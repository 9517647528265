import React, { useState } from 'react';
import _ from 'lodash';
import { _time, _user } from 'std';

import {
    Icon,
    Grid,
    Paper,
    Button,
    FormControl,
    Checkbox,
    Select,
    MenuItem,
    ListItemText,
    Divider,
    Typography,
    TextField,
    InputAdornment,
    DialogTitle,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    FormControlLabel,
    CircularProgress
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import useTrends from './hooks/useTrends';

import StatisticsChart from './StatisticsChart';

import useDateRangePicker from 'components/DateTimePickersTz/hooks/useDateRangePicker';
import DateRangePicker from 'components/DateTimePickersTz/DateRangePicker';
import { RESIDENTIAL, COMMERCIAL, CONDO } from '../../../constants';
import { DROPNGO, ADJUSTMENT, PICKUP, WALKIN } from '../../../constants';
import MDIcon from '@mdi/react';
import { mdiAccountOff, mdiWalk, mdiMapMarkerOff } from '@mdi/js';

import FilterSidePanel from 'components/FilterSidePanel/FilterSidePanel';
import useFilterSidePanel from 'components/FilterSidePanel/hooks/useFilterSidePanel';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import { useEffect } from 'react';
import moment from 'moment-timezone';

const allPickupTypes = [RESIDENTIAL, COMMERCIAL, CONDO];

const allBulkTypes = [
    { type: DROPNGO, label: 'Drop & Go' },
    { type: ADJUSTMENT, label: 'Adjustment' },
    { type: PICKUP, label: 'Pickup' },
    { type: WALKIN, label: 'Walk In' }
];

const Trends = ({ http, collectors, regions, theme, onSnackbar }) => {
    const [addressQueryDialogOpen, setAddressQueryDialogOpen] = useState(false);
    const [createNewChartDialogOpen, setCreateNewChartDialogOpen] = useState(false);
    const [selectedChartId, setSelectedChartId] = useState(null);
    const [selectedChart, setSelectedChart] = useState(null);
    const [chartKeyValue, setChartKeyValue] = useState(0);
    const [loadingIcons, setLoadingIcons] = useState([]);

    const {
        startDate,
        endDate,
        timezone,
        dateWindow,
        handleChangeStartDate,
        handleChangeEndDate,
        handleGoForwards,
        handleGoBackwards,
        handleWindowSelect
    } = useDateRangePicker({
        saveStateInURL: true,
        timezones: [process.env.REACT_APP_REGION_TIMEZONE],
        initStartVal: _time.getStartOfIsoWeek(process.env.REACT_APP_REGION_TIMEZONE, 1),
        initEndVal: _time.getEndOfDay(process.env.REACT_APP_REGION_TIMEZONE),
        initDateWindow: 'thisIsoWeek'
    });

    const {
        loading,
        addressKeys,
        visibleTrendKeys,
        trendLines,
        collectorsSelected,
        handleTrend,
        fetchTrends,
        chartStatsList,
        zonesWithRegion,
        provinces,
        cities,
        provincesSelected,
        citiesSelected,
        zonesSelected,
        regionsSelected,
        allDrivers,
        clerks,
        driversSelected,
        clerksSelected,
        allCollectorAdmins,
        collectorAdminsSelected,
        handleCollectorsSelected,
        handleAllFilterCollectors,
        setChartInterval,
        setPickupTypesSelected,
        setZonesSelected,
        setProvincesSelected,
        setCitiesSelected,
        handleRegionsSelected,
        setDriversSelected,
        setClerksSelected,
        setCollectorAdminsSelected,
        handleVisibleTrendKeys,
        handleDownload,
        handleRemoveChart,
        setAddressKeys,
        chartTitle,
        setChartTitle,
        handleFiltersForm,
        setSize,
        size,
        handleRefresh
    } = useTrends({ http, collectors, onSnackbar, startDate, endDate, regions });

    const handleSubmit = async () => {
        addLoadingIcon();
        await handleTrend(selectedChartId);
        setChartKeyValue((chartKeyValue + 1) % 2);
        removeLoadingIcon();
        setSelectedChart(null);
        setSelectedChartId(null);
    };

    const addLoadingIcon = () => {
        setLoadingIcons([
            ...loadingIcons,
            <CircularProgress
                size={30}
                thickness={4.8}
                style={{ marginTop: theme.spacing.unit, marginRight: theme.spacing.unit / 2 }}
            />
        ]);
    };
    const removeLoadingIcon = () => {
        let loadingIconsCopy = _.clone(loadingIcons);
        setLoadingIcons([loadingIconsCopy.splice(0, 1)]);
    };

    const handleDelete = index => {
        handleRemoveChart(index);
        setChartKeyValue((chartKeyValue + 1) % 2);
    };

    // change both date window and x-axis interval
    const handleDateWindowChange = e => {
        handleWindowSelect(e);
        switch (e.target.value) {
            case 'today':
                setChartInterval('DAY');
                break;
            case 'thisIsoWeek':
                setChartInterval('WEEK');
                break;
            case 'thisMonth':
                setChartInterval('MONTH');
                break;
            default:
                setChartInterval('MONTH');
        }
    };

    const { open: filterPanelOpen, overlay, toggleOverlay, handleFilter, handleFilterPanelOpen } = useFilterSidePanel({
        onFilter: handleSubmit
    });

    const regionsSorted = _.sortBy(regions, 'name');
    const zonesFiltered = _.filter(zonesWithRegion, zone =>
        _.some(zone.regions, region => regionsSelected.includes(region._id))
    );

    const filterTypes = new Set();
    trendLines.forEach(line => filterTypes.add(line.type));

    useEffect(() => {
        switch (dateWindow) {
            case 'today':
                setChartInterval('DAY');
                break;
            case 'thisIsoWeek':
                setChartInterval('WEEK');
                break;
            case 'thisMonth':
                setChartInterval('MONTH');
                break;
            default:
                setChartInterval('MONTH');
        }
        fetchTrends();
    }, []);

    const handleSetFormValues = _id => {
        setSelectedChartId(_id);
        let chart = chartStatsList.find(trend => trend._id.toString() === _id.toString());
        handleFiltersForm(chart);
        switch (chart.chartInterval) {
            case 'DAY':
                handleWindowSelect({ target: { value: 'today' } });
                break;
            case 'WEEK':
                handleWindowSelect({ target: { value: 'thisIsoWeek' } });
                break;
            case 'MONTH':
                handleWindowSelect({ target: { value: 'thisMonth' } });
                break;
            default:
                handleWindowSelect({ target: { value: 'thisMonth' } });
        }
        handleChangeStartDate(moment(chart.startDate));
        handleChangeEndDate(moment(chart.endDate));
        handleVisibleTrendKeys(chart.visibleTrendKeys);
        setCreateNewChartDialogOpen(true);
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    position: 'relative',
                    marginTop: theme.spacing.unit * 2
                }}
            >
                <Paper
                    style={{
                        minHeight: '80px',
                        margin: theme.spacing.unit * 2,
                        padding: theme.spacing.unit * 2,
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                        overflow: 'auto'
                    }}
                >
                    {loadingIcons}
                    <Button
                        style={{ margin: theme.spacing.unit }}
                        variant="outlined"
                        onClick={() => setCreateNewChartDialogOpen(true)}
                        color="primary"
                        data-cy="new-chart"
                    >
                        <Icon style={{ marginRight: theme.spacing.unit }}>add_circle_outline</Icon>
                        New Chart
                    </Button>
                </Paper>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid container spacing={theme.spacing.unit * 2} style={{ margin: theme.spacing.unit }}>
                        {!_.isEmpty(chartStatsList) &&
                            chartStatsList.map((chartStats, index) => {
                                return (
                                    <Grid item xs={chartStats.size} key={chartKeyValue + index}>
                                        <StatisticsChart
                                            key={index}
                                            title={chartStats.title}
                                            data={chartStats.data}
                                            visibleTrendKeys={chartStats.visibleTrendKeys}
                                            trendLines={trendLines}
                                            totalUsers={chartStats.totalUsers}
                                            totalPickups={chartStats.totalPickups}
                                            totalOrders={chartStats.totalOrders}
                                            dataInterval={chartStats.chartInterval}
                                            setPickupTypesSelected={setPickupTypesSelected}
                                            setZonesSelected={setZonesSelected}
                                            onRegionsSelected={handleRegionsSelected}
                                            handleDownload={() => handleDownload(chartStats._id)}
                                            handleRemoveChart={() => handleDelete(chartStats._id)}
                                            handleRefresh={() => handleRefresh(chartStats._id)}
                                            handleFilters={() => handleSetFormValues(chartStats._id)}
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                </div>
                <Dialog
                    open={createNewChartDialogOpen}
                    fullWidth
                    onClose={() => {
                        setSelectedChartId(null);
                        setSelectedChart(null);
                        setChartTitle('');
                        setCreateNewChartDialogOpen(false);
                    }}
                >
                    <DialogTitle>
                        {_.isNil(selectedChartId)
                            ? 'Create New Statistics Chart'
                            : `Edit ${_.get(selectedChart, 'title', 'Chart')}`}
                    </DialogTitle>
                    <DialogContent>
                        <CustomFormTitle titleText={'Trend Info'} iconName={'timeline'} />
                        <div style={{ display: 'flex', marginBottom: theme.spacing.unit * 2 }}>
                            <TextField
                                label="Chart Title"
                                value={chartTitle}
                                name="Chart Title"
                                onChange={e => setChartTitle(e.target.value)}
                                style={{ margin: theme.spacing.unit, marginTop: -theme.spacing.unit }}
                            />
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DateRangePicker
                                    timezone={timezone}
                                    startDate={startDate}
                                    endDate={endDate}
                                    window={dateWindow}
                                    handlePrevious={handleGoBackwards}
                                    handleNext={handleGoForwards}
                                    handleChangeStartDate={handleChangeStartDate}
                                    handleChangeEndDate={handleChangeEndDate}
                                />
                            </div>
                            <Select
                                value={dateWindow}
                                onChange={handleDateWindowChange}
                                style={{
                                    margin: theme.spacing.unit
                                }}
                                data-cy="date-range-select"
                            >
                                <MenuItem value="today" data-cy="date-range-select-today">
                                    Daily
                                </MenuItem>
                                <MenuItem value="thisIsoWeek" data-cy="date-range-select-thisIsoWeek">
                                    Weekly
                                </MenuItem>
                                <MenuItem value="thisMonth" data-cy="date-range-select-thisMonth">
                                    Monthly
                                </MenuItem>
                                <MenuItem value="thisQuarter" data-cy="date-range-select-thisQuarter">
                                    Quarterly
                                </MenuItem>
                                <MenuItem value="allTime" data-cy="date-range-select-allTime">
                                    All Time
                                </MenuItem>
                            </Select>

                            <Select
                                value={size}
                                onChange={e => setSize(e.target.value)}
                                style={{
                                    margin: theme.spacing.unit
                                }}
                                data-cy="date-range-select"
                            >
                                <MenuItem value={12} data-cy="size-select-full">
                                    Full
                                </MenuItem>
                                <MenuItem value={6} data-cy="size-select-half">
                                    1/2
                                </MenuItem>
                                <MenuItem value={4} data-cy="size-select-third">
                                    1/3
                                </MenuItem>
                                <MenuItem value={3} data-cy="size-select-quarter">
                                    1/4
                                </MenuItem>
                            </Select>
                        </div>
                        <CustomFormTitle titleText={'Location Info'} iconName={'location_on'} />
                        <div style={{ display: 'flex', marginBottom: theme.spacing.unit * 2 }}>
                            <Select
                                multiple
                                onChange={e => setProvincesSelected(e.target.value)}
                                style={{
                                    margin: theme.spacing.unit
                                }}
                                value={provincesSelected}
                                renderValue={s =>
                                    s.includes('')
                                        ? 'All Provinces'
                                        : `${s.length} ${
                                              process.env.REACT_APP_REGIONEXT === 'AUS' ? 'States' : 'Provinces'
                                          }`
                                }
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setProvincesSelected(provinces);
                                        }}
                                    >
                                        All
                                    </Button>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setProvincesSelected([]);
                                        }}
                                    >
                                        None
                                    </Button>
                                </div>
                                <MenuItem value={''}>
                                    All Provinces
                                    <span
                                        style={{
                                            marginLeft: theme.spacing.unit,
                                            display: 'grid',
                                            placeItems: 'center'
                                        }}
                                    >
                                        {/* <MDIcon
                                            path={mdiMapMarkerOff}
                                            size={0.85}
                                            color={theme.palette.text.secondary}
                                        /> */}
                                    </span>
                                </MenuItem>
                                {provinces.map(province => (
                                    <MenuItem key={province} value={province}>
                                        <Checkbox checked={provincesSelected.includes(province)} />
                                        <ListItemText>{province}</ListItemText>
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                multiple
                                onChange={e => setCitiesSelected(e.target.value)}
                                style={{
                                    margin: theme.spacing.unit
                                }}
                                value={citiesSelected}
                                renderValue={s => (s.includes('') ? 'All Cities' : `${s.length} Cities`)}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setCitiesSelected(
                                                _.filter(cities, c => provincesSelected.includes(c.province)).map(
                                                    c => c.name
                                                )
                                            );
                                        }}
                                    >
                                        All
                                    </Button>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setCitiesSelected([]);
                                        }}
                                    >
                                        None
                                    </Button>
                                </div>
                                <MenuItem key={''} value={''}>
                                    All Cities
                                    <span
                                        style={{
                                            marginLeft: theme.spacing.unit,
                                            paddingTop: theme.spacing.unit
                                        }}
                                    >
                                        {/* <MDIcon
                                            path={mdiMapMarkerOff}
                                            size={0.85}
                                            color={theme.palette.text.secondary}
                                        /> */}
                                    </span>
                                </MenuItem>
                                {_.filter(cities, c => provincesSelected.includes(c.province)).map(city => (
                                    <MenuItem key={city.name} value={city.name}>
                                        <Checkbox checked={citiesSelected.includes(city.name)} />
                                        <ListItemText>
                                            {city.name}, {city.province}
                                        </ListItemText>
                                    </MenuItem>
                                ))}
                            </Select>
                            <TextField
                                label="Address search (comma delimited)"
                                value={addressKeys}
                                name="Address search (comma delimited)"
                                multiline
                                fullWidth
                                onChange={e => setAddressKeys(e.target.value)}
                                style={{ margin: theme.spacing.unit, marginTop: -theme.spacing.unit }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {/* <IconButton onClick={() => setAddressQueryDialogOpen(true)}> */}
                                            <Icon
                                                onClick={() => setAddressQueryDialogOpen(true)}
                                                style={{ color: 'gray', cursor: 'pointer' }}
                                            >
                                                info
                                            </Icon>
                                            {/* </IconButton> */}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <CustomFormTitle titleText={'System Filter'} iconName={'tune'} />
                        <div style={{ display: 'flex', marginBottom: theme.spacing.unit * 2, flexWrap: 'wrap' }}>
                            <Select
                                multiple
                                onChange={handleCollectorsSelected}
                                style={{
                                    margin: theme.spacing.unit
                                }}
                                value={collectorsSelected}
                                renderValue={s => `${s.length} Collectors`}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button onClick={handleAllFilterCollectors(true)}>All</Button>
                                    <Button onClick={handleAllFilterCollectors(false)}>None</Button>
                                </div>
                                {collectors.map(({ _id, name }) => (
                                    <MenuItem key={_id} value={_id}>
                                        <Checkbox checked={collectorsSelected.includes(_id)} />
                                        <ListItemText>{name}</ListItemText>
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                multiple
                                renderValue={val => `${val.length} Collector Admin${val.length !== 1 ? 's' : ''}`}
                                value={collectorAdminsSelected}
                                onChange={e => setCollectorAdminsSelected(e.target.value)}
                                style={{
                                    margin: theme.spacing.unit
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setCollectorAdminsSelected(
                                                _(allCollectorAdmins)
                                                    .filter(user => collectorsSelected.includes(user.collector._id))
                                                    .map(user => user._id)
                                                    .value()
                                            );
                                        }}
                                    >
                                        All
                                    </Button>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setCollectorAdminsSelected([]);
                                        }}
                                    >
                                        None
                                    </Button>
                                </div>
                                <MenuItem value={''}>
                                    Empty Collector Admin
                                    <span
                                        style={{
                                            marginLeft: theme.spacing.unit,
                                            display: 'grid',
                                            placeItems: 'center'
                                        }}
                                    >
                                        <MDIcon path={mdiWalk} size={0.85} color={theme.palette.text.secondary} />
                                    </span>
                                </MenuItem>
                                {_.orderBy(
                                    allCollectorAdmins,
                                    [d => !d.banned, d => d.name.first.toLowerCase()],
                                    ['desc', 'asc']
                                ).map(user => {
                                    const { _id, collector, banned } = user;
                                    if (!collectorsSelected.includes(collector._id)) return null;
                                    return (
                                        <MenuItem
                                            key={_id}
                                            value={_id}
                                            style={{
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {_user.getNameFull(user).toLowerCase()}
                                            {banned && (
                                                <span
                                                    style={{
                                                        marginLeft: theme.spacing.unit,
                                                        display: 'grid',
                                                        placeItems: 'center'
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={mdiAccountOff}
                                                        size={0.85}
                                                        color={theme.palette.text.secondary}
                                                    />
                                                </span>
                                            )}
                                        </MenuItem>
                                    );
                                })}
                            </Select>

                            <Select
                                multiple
                                renderValue={val => `${val.length} Driver${val.length !== 1 ? 's' : ''}`}
                                value={driversSelected}
                                onChange={e => setDriversSelected(e.target.value)}
                                style={{
                                    margin: theme.spacing.unit
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setDriversSelected(
                                                _(allDrivers)
                                                    .filter(driver => collectorsSelected.includes(driver.collector._id))
                                                    .map(driver => driver._id)
                                                    .value()
                                            );
                                        }}
                                    >
                                        All
                                    </Button>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setDriversSelected([]);
                                        }}
                                    >
                                        None
                                    </Button>
                                </div>
                                <MenuItem value={''}>
                                    Empty Driver
                                    <span
                                        style={{
                                            marginLeft: theme.spacing.unit,
                                            display: 'grid',
                                            placeItems: 'center'
                                        }}
                                    >
                                        <MDIcon path={mdiWalk} size={0.85} color={theme.palette.text.secondary} />
                                    </span>
                                </MenuItem>
                                {_.orderBy(
                                    allDrivers,
                                    [d => !d.banned, d => d.name.first.toLowerCase()],
                                    ['desc', 'asc']
                                ).map(driver => {
                                    const { _id, collector, banned } = driver;
                                    if (!collectorsSelected.includes(collector._id)) return null;
                                    return (
                                        <MenuItem
                                            key={_id}
                                            value={_id}
                                            style={{
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {_user.getNameFull(driver).toLowerCase()}
                                            {banned && (
                                                <span
                                                    style={{
                                                        marginLeft: theme.spacing.unit,
                                                        display: 'grid',
                                                        placeItems: 'center'
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={mdiAccountOff}
                                                        size={0.85}
                                                        color={theme.palette.text.secondary}
                                                    />
                                                </span>
                                            )}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <Select
                                multiple
                                renderValue={val => `${val.length} Clerk${val.length !== 1 ? 's' : ''}`}
                                value={clerksSelected}
                                onChange={e => setClerksSelected(e.target.value)}
                                style={{
                                    margin: theme.spacing.unit
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setClerksSelected(
                                                _(clerks)
                                                    .filter(clerk => collectorsSelected.includes(clerk.collector._id))
                                                    .map(clerk => clerk._id)
                                                    .value()
                                            );
                                        }}
                                    >
                                        All
                                    </Button>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setClerksSelected([]);
                                        }}
                                    >
                                        None
                                    </Button>
                                </div>
                                {_.orderBy(
                                    clerks,
                                    [c => !c.banned, c => c.name.first.toLowerCase()],
                                    ['desc', 'asc']
                                ).map(clerk => {
                                    const { _id, collector, banned } = clerk;
                                    if (!collectorsSelected.includes(collector._id)) return null;
                                    return (
                                        <MenuItem
                                            key={_id}
                                            value={_id}
                                            style={{
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {_user.getNameFull(clerk).toLowerCase()}
                                            {banned && (
                                                <span
                                                    style={{
                                                        marginLeft: theme.spacing.unit,
                                                        display: 'grid',
                                                        placeItems: 'center'
                                                    }}
                                                >
                                                    <MDIcon
                                                        path={mdiAccountOff}
                                                        size={0.85}
                                                        color={theme.palette.text.secondary}
                                                    />
                                                </span>
                                            )}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <Select
                                multiple
                                onChange={e => setZonesSelected(e.target.value)}
                                style={{
                                    margin: theme.spacing.unit
                                }}
                                value={zonesSelected}
                                renderValue={s => `${s.length} Zones`}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setZonesSelected(zonesWithRegion.map(zone => zone._id));
                                        }}
                                    >
                                        All
                                    </Button>
                                    <Button
                                        onClick={e => {
                                            e.stopPropagation();
                                            setZonesSelected([]);
                                        }}
                                    >
                                        None
                                    </Button>
                                </div>
                                {zonesFiltered.map(zone => (
                                    <MenuItem key={zone._id} value={zone._id}>
                                        <Checkbox checked={zonesSelected.includes(zone._id)} />
                                        <ListItemText>{zone.name}</ListItemText>
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <CustomFormTitle titleText={'Display'} iconName={'preview'} />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Grid container spacing={theme.spacing.unit}>
                                {[...filterTypes].map(filterType => {
                                    const trendLinesByType = _.filter(trendLines, { type: filterType });
                                    const trendLinesShown = _.filter(trendLines, line =>
                                        visibleTrendKeys.includes(line.dataKey)
                                    );

                                    const filteredTrendLines = _.filter(trendLinesByType, line =>
                                        visibleTrendKeys.includes(line.dataKey)
                                    );

                                    const showingAll = trendLinesByType.length === filteredTrendLines.length;

                                    const handleShowAll = () => {
                                        let updatedTrendKeys = _.clone(visibleTrendKeys);

                                        for (let line of trendLinesByType) {
                                            if (!updatedTrendKeys.includes(line.dataKey)) {
                                                updatedTrendKeys.push(line.dataKey);
                                            }
                                        }

                                        handleVisibleTrendKeys(updatedTrendKeys);
                                    };

                                    const handleShowNone = () => {
                                        let updatedTrendKeys = _.clone(visibleTrendKeys);

                                        const shownTrendsWithoutType = _.filter(
                                            trendLinesShown,
                                            line => line.type !== filterType
                                        );
                                        updatedTrendKeys = shownTrendsWithoutType.map(line => line.dataKey);

                                        handleVisibleTrendKeys(updatedTrendKeys);
                                    };

                                    const handleIndividual = key => {
                                        let updatedTrendKeys = _.clone(visibleTrendKeys);
                                        if (updatedTrendKeys.includes(key)) {
                                            handleVisibleTrendKeys(_.filter(updatedTrendKeys, k => k !== key));
                                        } else {
                                            updatedTrendKeys.push(key);
                                            handleVisibleTrendKeys(updatedTrendKeys);
                                        }
                                    };

                                    const typeCapitalized = _.startCase(filterType);

                                    return (
                                        <Grid
                                            item
                                            xs={6}
                                            sm={4}
                                            style={{
                                                border: '1px solid',
                                                borderColor: theme.palette.grey[400],
                                                borderRadius: 4
                                            }}
                                        >
                                            <div />
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography
                                                    variant="body1"
                                                    style={{ paddingBottom: 0, marginBottom: 0 }}
                                                >
                                                    {typeCapitalized}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    style={{ float: 'right', paddingBottom: 0, marginBottom: 0 }}
                                                >
                                                    Shown: {filteredTrendLines.length}
                                                </Typography>
                                            </div>
                                            <div
                                                style={{
                                                    paddingTop: theme.spacing.unit / 2,
                                                    paddingBottom: theme.spacing.unit / 2,
                                                    paddingLeft: theme.spacing.unit / 2,
                                                    paddingRight: theme.spacing.unit / 2,
                                                    marginBottom: theme.spacing.unit * 2,
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-around'
                                                    }}
                                                >
                                                    <Button onClick={handleShowAll}>All</Button>
                                                    <Button onClick={handleShowNone}>None</Button>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    {trendLinesByType.map(line => {
                                                        const { dataKey, displayName } = line;
                                                        return (
                                                            <FormControlLabel
                                                                key={dataKey}
                                                                control={
                                                                    <Checkbox
                                                                        style={{ padding: 0, margin: 2 }}
                                                                        onChange={e => handleIndividual(e.target.value)}
                                                                        value={dataKey}
                                                                        checked={visibleTrendKeys.includes(dataKey)}
                                                                    />
                                                                }
                                                                style={{ padding: 0, margin: 2 }}
                                                                label={
                                                                    <Typography
                                                                        style={{
                                                                            fontSize: '90%',
                                                                            padding: 0,
                                                                            margin: 2
                                                                        }}
                                                                    >
                                                                        {displayName}
                                                                    </Typography>
                                                                }
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={() => {
                                setSelectedChart(null);
                                setSelectedChartId(null);
                                setChartTitle('');
                                setCreateNewChartDialogOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            color="primary"
                            disabled={_.isEmpty(collectorsSelected)}
                            variant="contained"
                            data-cy="dashboard-search-button"
                            type="submit"
                            onClick={() => {
                                setCreateNewChartDialogOpen(false);
                                setChartTitle('');
                                handleSubmit();
                            }}
                        >
                            <Icon>search</Icon>
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={addressQueryDialogOpen} fullWidth onClose={() => setAddressQueryDialogOpen(false)}>
                    <DialogTitle>Address Search</DialogTitle>
                    <DialogContent>
                        <Typography>
                            This field is comma delimited to allow for multiple search queries. Search queries are case
                            insensitive. This field will only affect trends for which there is relevant pickup location
                            data.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => setAddressQueryDialogOpen(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default withTheme()(Trends);
