import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';

import MdiIcon from '@mdi/react';
import * as allMdiIcons from '@mdi/js';
import { withTheme } from '@material-ui/core/styles';
import {
    withWidth,
    Icon,
    Paper,
    Grid,
    Button,
    Typography,
    IconButton,
    Collapse,
    CircularProgress,
    TextField,
    Divider,
    colors,
    InputAdornment,
    Switch,
    Select,
    OutlinedInput,
    MenuItem,
    Menu,
    ListItem,
    ListItemIcon,
    ListItemText,
    InputLabel,
    FormControl,
    Tooltip,
    Chip,
    Checkbox
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';

import { getTextInput } from 'components/CRUDTables/helperFunctions';
import StringListInput from 'components/CustomInputs/StringListInput';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { isAUSRegion, isCONRegion, isEXPRegion } from 'utils/misc';
import { isWidthDown } from '@material-ui/core/withWidth';
import { useRef } from 'react';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';
import { validate } from 'utils/validate';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import DOMPurify from 'dompurify';
import { Clipboard } from '@capacitor/clipboard';

const Emails = ({ theme, collectors, width, pickupsEnabled, charityEnabled }) => {
    const headerRef = useRef(null);
    const [headerWidth, setHeaderWidth] = useState(width);
    const isSmallScreen = isWidthDown('md', width);
    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);
    const [lang, setLang] = useState(process.env.REACT_APP_REGION_LANGUAGE);
    const [renderScreen, setRenderScreen] = useState(0);
    const [showMenu, setShowMenu] = useState(true);
    const [menuEl, setMenuEl] = useState(null);

    const [emailsConfigLoading, setEmailsConfigLoading] = useState();
    const [emails, setEmails] = useState({});
    const [emailConfig, setEmailConfig] = useState({});

    const [sendTestEmailExpanded, setSendTestEmailExpanded] = useState('');
    const [editor, setEditor] = useState('content');
    const [emailTemplateSelected, setEmailTemplateSelected] = useState('');
    const [emailTemplateSearch, setEmailTemplateSearch] = useState('');
    const [testEmailAddress, setTestEmailAddress] = useState('');
    const [customizeTestData, setCustomizeTestData] = useState(false);
    const [emailDataLocationType, setEmailDataLocationType] = useState('Residential');
    const [includeCharityFields, setIncludeCharityFields] = useState(true);
    const [includeRedemptionFee, setIncludeRedemptionFee] = useState(true);
    const [testData, setTestData] = useState('');
    const [currentEmailHtml, setCurrentEmailHtml] = useState(
        '<div style="padding: 10px 50px 50px;"><p>Please <b>refresh</b> to see updated email content</p></div>'
    );

    const iconPath = isAUSRegion()
        ? 'https://skipthedepot.com/img/app/aus'
        : isCONRegion()
        ? 'https://skipthedepot.com/img/app/con'
        : 'https://skipthedepot.com/img/app/std';

    const [isLoading, setIsLoading] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    const onSnackbar = useContext(SnackbarContext);

    const handleMenu = open => e => {
        if (open) {
            if (isSmallScreen) {
                setMenuEl(e.currentTarget);
            }
            setShowMenu(true);
        } else {
            setMenuEl(null);
            setShowMenu(false);
        }
    };
    const handleSelectSendgridTemplate = e => {
        let value = e.target.value;
        if (value === 'registrationEmail') {
            formik.setFieldValue(`emails.${emailTemplateSelected}.registrationEmail`, true);
            formik.setFieldValue(`emails.${emailTemplateSelected}.organizationApprovalEmail`, false);
            formik.setFieldValue(`emails.${emailTemplateSelected}.inactiveCustomerEmail`, false);
        } else if (value === 'organizationApprovalEmail') {
            formik.setFieldValue(`emails.${emailTemplateSelected}.registrationEmail`, false);
            formik.setFieldValue(`emails.${emailTemplateSelected}.organizationApprovalEmail`, true);
            formik.setFieldValue(`emails.${emailTemplateSelected}.inactiveCustomerEmail`, false);
        } else if (value === 'inactiveCustomerEmail') {
            formik.setFieldValue(`emails.${emailTemplateSelected}.registrationEmail`, false);
            formik.setFieldValue(`emails.${emailTemplateSelected}.organizationApprovalEmail`, false);
            formik.setFieldValue(`emails.${emailTemplateSelected}.inactiveCustomerEmail`, true);
        } else {
            formik.setFieldValue(`emails.${emailTemplateSelected}.registrationEmail`, false);
            formik.setFieldValue(`emails.${emailTemplateSelected}.organizationApprovalEmail`, false);
            formik.setFieldValue(`emails.${emailTemplateSelected}.inactiveCustomerEmail`, false);
        }
    };

    const updateCurrentEmailHtml = async () => {
        if (inProgress) return;
        if (_.isEmpty(emailTemplateSelected)) return;
        let testDataToSend;
        if (customizeTestData) {
            try {
                testDataToSend = JSON.parse(testData.replaceAll('\n', ''));
            } catch (err) {
                onSnackbar('Error parsing test data', 'error');
                return;
            }
        }
        setInProgress(true);
        const res = await http.postJSON(
            '/system/getUpdatedEmailHtml',
            {
                emailTemplateSelected,
                recipientEmail: testEmailAddress,
                testData: testDataToSend,
                lang,
                configuration: {
                    emails: _.get(formik.values, 'emails'),
                    emailConfig: _.get(formik.values, 'emailConfig')
                },
                locationType: emailDataLocationType,
                includeCharityFields,
                includeRedemptionFee
            },
            true
        );
        if (res.ok) {
            setTestData(
                JSON.stringify(res.data.testData)
                    .replaceAll(',', ',\n')
                    .replaceAll('[[', '[\n[')
                    .replaceAll(':{', ':\n{')
                    .replaceAll('}}', '}\n}')
                    .replaceAll(']]', ']\n]')
                    .trim()
            );
            setCurrentEmailHtml(res.data.emailHtml);
        } else {
            setCurrentEmailHtml('Unable to render email html');
        }
        setInProgress(false);
    };

    const loadInformationFormsConfig = async () => {
        setEmailsConfigLoading(true);
        const res = await http.getJSON(`/system/emails/`);
        if (res.ok) {
            setEmails(res.data.emails);
            setEmailConfig(res.data.emailConfig);
            let firstEmailTemplate = _.first(_.keys(_.get(res.data, 'emails', {})).sort());
            if (!_.isNil(firstEmailTemplate)) {
                setEmailTemplateSelected(firstEmailTemplate);
            }
        }
        setEmailsConfigLoading(false);
    };

    const handleSendTestEmail = async () => {
        let testDataToSend;
        if (customizeTestData) {
            try {
                testDataToSend = JSON.parse(testData.replaceAll('\n', ''));
            } catch (err) {
                onSnackbar('Error parsing test data', 'error');
                return;
            }
        }
        const res = await http.post('/system/sendTestEmail', {
            emailTemplateSelected,
            recipientEmail: testEmailAddress,
            testData: testDataToSend,
            lang,
            configuration: { emails: _.get(formik.values, 'emails'), emailConfig: _.get(formik.values, 'emailConfig') },
            locationType: emailDataLocationType,
            includeCharityFields,
            includeRedemptionFee
        });
        if (res.ok) {
            onSnackbar(`Test ${emailTemplateSelected} email sent to ${testEmailAddress}`);
        } else {
            onSnackbar(`Error sending test email`, 'error');
        }
    };

    const handleSendAllTestEmails = async () => {
        let testDataToSend;
        if (customizeTestData) {
            try {
                testDataToSend = JSON.parse(testData.replaceAll('\n', ''));
            } catch (err) {
                onSnackbar('Error parsing test data', 'error');
                return;
            }
        }
        const res = await http.post('/system/sendAllTestEmails', {
            recipientEmail: testEmailAddress,
            testData: testDataToSend,
            lang,
            configuration: { emails: _.get(formik.values, 'emails'), emailConfig: _.get(formik.values, 'emailConfig') },
            locationType: emailDataLocationType,
            includeCharityFields,
            includeRedemptionFee
        });
        if (res.ok) {
            onSnackbar(`All test emails sent to ${testEmailAddress}`);
        } else {
            onSnackbar(`Error sending test emails`, 'error');
        }
    };

    const handleRerender = () => {
        setRenderScreen(renderScreen === 0 ? 1 : 0);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            emails,
            emailConfig
        },
        onSubmit: async values => {
            setIsLoading(true);
            _.values(values.emails).forEach(email => {
                if (!email.registrationEmail) {
                    delete email.registrationEmail;
                }
                if (!email.organizationApprovalEmail) {
                    delete email.organizationApprovalEmail;
                }
                if (!email.inactiveCustomerEmail) {
                    delete email.inactiveCustomerEmail;
                }
                if (!email.customEmail) {
                    delete email.customEmail;
                }
            });

            const localizationRes = await http.postJSON(
                '/system/updateEmails',
                {
                    emails: values.emails,
                    emailConfig: values.emailConfig
                },
                true
            );
            if (localizationRes.ok) {
                onSnackbar('Values updated successfully');
                const res = await http.getJSON(`/system/emails/`);
                if (res.ok) {
                    setEmails(res.data.emails);
                }
            } else {
                onSnackbar('Failed to update localizations.', 'error');
            }

            setIsLoading(false);
        }
    });

    const handleFormikSave = () => {
        // since we're saving remove our general dirty field
        formik.setFieldValue('hasUnsavedChanges', undefined);
        formik.handleSubmit();
    };

    useEffect(() => {
        loadInformationFormsConfig();
        formik.handleReset();
    }, []);

    useEffect(() => {
        if (!headerRef.current) {
            return;
        }

        const newWidth = headerRef.current.offsetWidth;
        setHeaderWidth(newWidth);
    }, [width]);

    useEffect(() => {
        if (!_.isEmpty(emailTemplateSelected)) {
            updateCurrentEmailHtml();
        }
    }, [emailTemplateSelected, lang, includeCharityFields, includeRedemptionFee, emailDataLocationType]);

    return (
        <div style={{ height: '100%', display: 'flex' }}>
            {!isSmallScreen && (
                <div
                    style={{
                        width: 400
                    }}
                >
                    <Paper style={{ height: '100%', overflowY: 'auto' }}>
                        <ListItem data-cy={'system-information-editor-menu'}>
                            <Typography
                                variant="body1"
                                style={{
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    display: 'block',
                                    paddingRight: 0,
                                    color: `${theme.palette.text.primary}`
                                }}
                            >
                                Emails / SMS / Push
                            </Typography>
                        </ListItem>
                        <MenuItem
                            dense
                            onClick={() => setEmailTemplateSelected('MSG_FORMATTING_PREVIEW')}
                            selected={emailTemplateSelected === 'MSG_FORMATTING_PREVIEW'}
                            style={{}}
                            data-cy={`information-forms-menu-item-MSG_FORMATTING_PREVIEW`}
                            disabled={inProgress}
                        >
                            <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                Global Formatting
                            </Typography>
                        </MenuItem>
                        <Divider style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }} />
                        <Typography
                            variant="caption"
                            style={{ fontWeight: 'bold', marginLeft: theme.spacing.unit * 2 }}
                        >
                            Notification Templates
                        </Typography>
                        <SearchBar
                            searchString={emailTemplateSearch}
                            handleSearchChange={e => setEmailTemplateSearch(e.target.value)}
                        />
                        {_.keys(emails)
                            .filter(
                                key =>
                                    (pickupsEnabled ||
                                        _.every(
                                            ['PICKUP', 'DRIVER', '_ETA', 'INACTIVE', 'BOTTLE_DRIVE'],
                                            term => !key.includes(term)
                                        )) &&
                                    (charityEnabled ||
                                        _.every(
                                            ['CHARITY', 'ORGANIZATION', 'DONATION', 'TAX_RECEIPT'],
                                            term => !key.includes(term)
                                        )) &&
                                    (key.toLowerCase().includes(emailTemplateSearch.toLowerCase()) ||
                                        key
                                            .replace('MSG_', '')
                                            .replace('_', ' ')
                                            .toLowerCase()
                                            .includes(emailTemplateSearch.toLowerCase()) ||
                                        _.some(_.values(_.get(formik.values, `emails.${key}.${lang}`, {})), value => {
                                            if (_.isNil(value) || typeof value !== 'string') return false;
                                            return value.toLowerCase().includes(emailTemplateSearch.toLowerCase());
                                        })) &&
                                    !_.get(formik.values, `emails.${key}.hidden`, false)
                            )
                            .sort()
                            .map(emailTemplate => {
                                return (
                                    <MenuItem
                                        dense
                                        onClick={() => setEmailTemplateSelected(emailTemplate)}
                                        selected={emailTemplateSelected === emailTemplate}
                                        style={{ marginLeft: theme.spacing.unit }}
                                        data-cy={`information-forms-menu-item-${emailTemplate}`}
                                        disabled={inProgress}
                                    >
                                        <Typography variant="caption">
                                            {_.startCase(
                                                emailTemplate
                                                    .replace('MSG_', '')
                                                    .replace('_', ' ')
                                                    .toLowerCase()
                                            )}
                                        </Typography>
                                    </MenuItem>
                                );
                            })}
                    </Paper>
                </div>
            )}

            <div
                ref={headerRef}
                style={{ width: '100%', padding: theme.spacing.unit * 2, height: '100%', overflowY: 'scroll' }}
            >
                <Paper
                    style={{
                        padding: theme.spacing.unit * 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        position: 'absolute',
                        zIndex: 10,
                        boxShadow: theme.shadows[2],
                        width: _.isNil(headerRef.current) ? headerWidth - 50 : headerRef.current.offsetWidth - 50
                    }}
                >
                    <Typography variant="h5">
                        {isSmallScreen && (
                            <IconButton onClick={handleMenu(true)} data-cy="information-forms-menu-button">
                                <MdiIcon path={allMdiIcons['mdiMenu']} size={0.7} color={theme.palette.text.primary} />
                            </IconButton>
                        )}
                        {_.startCase(
                            emailTemplateSelected
                                .replace('MSG_', '')
                                .replace('_', ' ')
                                .toLowerCase()
                        )}
                    </Typography>
                    {isSmallScreen && (
                        <Menu
                            open={showMenu && isSmallScreen && !_.isNil(menuEl)}
                            anchorEl={menuEl}
                            onClose={handleMenu(false)}
                        >
                            <MenuItem
                                dense
                                onClick={() => setEmailTemplateSelected('MSG_FORMATTING_PREVIEW')}
                                selected={emailTemplateSelected === 'MSG_FORMATTING_PREVIEW'}
                                style={{}}
                                data-cy={`information-forms-menu-item-MSG_FORMATTING_PREVIEW`}
                                disabled={inProgress}
                            >
                                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                                    Global Formatting
                                </Typography>
                            </MenuItem>
                            <Divider style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }} />
                            <Typography
                                variant="caption"
                                style={{ fontWeight: 'bold', marginLeft: theme.spacing.unit * 2 }}
                            >
                                Notification Templates
                            </Typography>
                            <SearchBar
                                searchString={emailTemplateSearch}
                                handleSearchChange={e => setEmailTemplateSearch(e.target.value)}
                            />
                            {_.keys(emails)
                                .filter(
                                    key =>
                                        (pickupsEnabled ||
                                            _.every(
                                                ['PICKUP', 'DRIVER', '_ETA', 'INACTIVE', 'BOTTLE_DRIVE'],
                                                term => !key.includes(term)
                                            )) &&
                                        (charityEnabled ||
                                            _.every(
                                                ['CHARITY', 'ORGANIZATION', 'DONATION', 'TAX_RECEIPT'],
                                                term => !key.includes(term)
                                            )) &&
                                        (key.toLowerCase().includes(emailTemplateSearch.toLowerCase()) ||
                                            key
                                                .replace('MSG_', '')
                                                .replace('_', ' ')
                                                .toLowerCase()
                                                .includes(emailTemplateSearch.toLowerCase()) ||
                                            _.some(
                                                _.values(_.get(formik.values, `emails.${key}.${lang}`, {})),
                                                value => {
                                                    if (_.isNil(value) || typeof value !== 'string') return false;
                                                    return value
                                                        .toLowerCase()
                                                        .includes(emailTemplateSearch.toLowerCase());
                                                }
                                            )) &&
                                        !_.get(formik.values, `emails.${key}.hidden`, false)
                                )
                                .sort()
                                .map(emailTemplate => {
                                    return (
                                        <MenuItem
                                            dense
                                            onClick={() => setEmailTemplateSelected(emailTemplate)}
                                            selected={emailTemplateSelected === emailTemplate}
                                            style={{ marginLeft: theme.spacing.unit }}
                                            data-cy={`information-forms-menu-item-${emailTemplate}`}
                                        >
                                            <Typography variant="caption">
                                                {_.startCase(
                                                    emailTemplate
                                                        .replace('MSG_', '')
                                                        .replace('_', ' ')
                                                        .toLowerCase()
                                                )}
                                            </Typography>
                                        </MenuItem>
                                    );
                                })}
                        </Menu>
                    )}
                    <div>
                        <ToggleButtonGroup
                            value={lang}
                            exclusive
                            onChange={(e, lang) => {
                                if (!_.isEmpty(lang)) setLang(lang);
                            }}
                            style={{ display: 'flex', flexWrap: 'wrap', marginRight: theme.spacing.unit }}
                        >
                            <ToggleButton style={{ flexGrow: 1 }} value="en" data-cy={`language-toggle-en`}>
                                English
                            </ToggleButton>
                            <ToggleButton style={{ flexGrow: 1 }} value="fr" data-cy={`language-toggle-fr`}>
                                French
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {formik.dirty && (
                            <Typography variant="body2" style={{ color: 'red', marginTop: theme.spacing.unit }}>
                                There are unsaved changes
                            </Typography>
                        )}
                    </div>

                    <Button
                        data-cy={`information-editor-submit`}
                        color="primary"
                        onClick={handleFormikSave}
                        disabled={isLoading}
                        variant="contained"
                    >
                        Save
                        {isLoading && <CircularProgress size={16} />}
                    </Button>
                </Paper>
                <div
                    style={{
                        paddingTop: theme.spacing.unit,
                        marginTop: formik.dirty ? 75 : 50,
                        paddingRight: theme.spacing.unit * 2
                    }}
                >
                    {emailsConfigLoading ? (
                        <Paper style={{ margin: theme.spacing.unit * 2, padding: theme.spacing.unit * 2 }}>
                            <div
                                style={{
                                    margin: theme.spacing.unit * 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <CircularProgress />
                            </div>
                        </Paper>
                    ) : (
                        <>
                            {!_.isNil(emailTemplateSelected) &&
                                !_.isEmpty(emailTemplateSelected) &&
                                !_.isEmpty(
                                    _.get(formik, `values.emails.${emailTemplateSelected}.dynamicFields`, [])
                                ) && (
                                    <FormsWidget theme={theme} formik={formik}>
                                        <Grid item xs="11">
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <Typography variant="body1" style={{ marginTop: 10 }}>
                                                    Supported Dynamic Fields:{' '}
                                                </Typography>

                                                {_.uniq([
                                                    'appBrandName',
                                                    'appURL',
                                                    'systemSupportEmail',
                                                    'systemSupportPhoneNumber',
                                                    ..._.get(
                                                        formik,
                                                        `values.emails.${emailTemplateSelected}.dynamicFields`,
                                                        []
                                                    )
                                                ]).map((value, i) => {
                                                    return (
                                                        <Chip
                                                            key={value}
                                                            label={value}
                                                            style={{ margin: theme.spacing.unit / 2, padding: 0 }}
                                                            onClick={() => {
                                                                Clipboard.write({ string: `{{${value}}}` });
                                                                onSnackbar('Copied field to clipboard');
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </Grid>
                                        <Grid item xs="1">
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Tooltip
                                                    title={`Content with characters ', " or & may need to be escaped with three brackets`}
                                                >
                                                    <Icon
                                                        style={{
                                                            color: theme.palette.text.disabled
                                                        }}
                                                    >
                                                        info
                                                    </Icon>
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                    </FormsWidget>
                                )}
                            {(emailTemplateSelected === 'MSG_FORMATTING_PREVIEW' ||
                                (!_.isNil(emailTemplateSelected) &&
                                    !_.isEmpty(emailTemplateSelected) &&
                                    !_.isNil(_.get(formik, `values.emails.${emailTemplateSelected}.icon`)))) && (
                                <FormsWidget theme={theme} formik={formik} cyString={`${lang}-emails-input`}>
                                    <Grid container spacing={theme.spacing.unit}>
                                        <Grid item xs="12" sm="6">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Typography variant="h6">Edit Email</Typography>
                                            </div>
                                            <Divider />

                                            <div
                                                style={{
                                                    maxHeight: 850,
                                                    overflowY: 'scroll',
                                                    overflowX: 'hidden',
                                                    padding: 10,
                                                    '-webkit-scrollbar': 'none',
                                                    '-ms-overflow-style': 'none',
                                                    scrollbarWidth: 'none'
                                                }}
                                            >
                                                {emailTemplateSelected !== 'MSG_FORMATTING_PREVIEW' && (
                                                    <Grid container spacing={theme.spacing.unit}>
                                                        <Grid item xs="12">
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between'
                                                                }}
                                                            >
                                                                <FormControl
                                                                    fullWidth
                                                                    style={{
                                                                        marginTop: theme.spacing.unit
                                                                    }}
                                                                >
                                                                    <InputLabel variant="outlined">
                                                                        Sendgrid Template
                                                                    </InputLabel>
                                                                    <Select
                                                                        data-cy="bulk-type-selection-dropdown"
                                                                        value={
                                                                            _.get(
                                                                                formik.values,
                                                                                `emails.${emailTemplateSelected}.registrationEmail`,
                                                                                false
                                                                            )
                                                                                ? 'registrationEmail'
                                                                                : _.get(
                                                                                      formik.values,
                                                                                      `emails.${emailTemplateSelected}.organizationApprovalEmail`,
                                                                                      false
                                                                                  )
                                                                                ? 'organizationApprovalEmail'
                                                                                : _.get(
                                                                                      formik.values,
                                                                                      `emails.${emailTemplateSelected}.inactiveCustomerEmail`,
                                                                                      false
                                                                                  )
                                                                                ? 'inactiveCustomerEmail'
                                                                                : 'general'
                                                                        }
                                                                        onChange={handleSelectSendgridTemplate}
                                                                        disabled={isLoading}
                                                                        input={
                                                                            <OutlinedInput
                                                                                labelWidth={136}
                                                                                name="sendgridTemplate"
                                                                                id="sendgrid-template"
                                                                            />
                                                                        }
                                                                        style={{
                                                                            minWidth: 168
                                                                        }}
                                                                    >
                                                                        <MenuItem value="general">
                                                                            General Template
                                                                        </MenuItem>
                                                                        <MenuItem value="registrationEmail">
                                                                            Registration Template
                                                                        </MenuItem>
                                                                        <MenuItem value="organizationApprovalEmail">
                                                                            Organization Approval Template
                                                                        </MenuItem>
                                                                        <MenuItem value="inactiveCustomerEmail">
                                                                            Inactive Customer Template
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                {_.get(
                                                                    formik.values,
                                                                    `emails.${emailTemplateSelected}.registrationEmail`,
                                                                    false
                                                                ) && (
                                                                    <Tooltip
                                                                        title="There are multiple registration email templates available.
                                                                            The template corresponding with the
                                                                            user's location type will be used to
                                                                            send notifications."
                                                                    >
                                                                        <Icon
                                                                            style={{
                                                                                margin: theme.spacing.unit,
                                                                                marginTop: theme.spacing.unit * 3,
                                                                                color: theme.palette.text.disabled
                                                                            }}
                                                                        >
                                                                            info
                                                                        </Icon>
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                        </Grid>
                                                        {/* {_.get(
                                                                formik.values,
                                                                `emails.${emailTemplateSelected}.registrationEmail`,
                                                                false
                                                            ) && (
                                                                <Grid item xs={12}>
                                                                    <div
                                                                        style={{
                                                                            backgroundColor: 'lightGrey',
                                                                            borderRadius: '5px',
                                                                            display: 'flex',
                                                                            padding: theme.spacing.unit
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            style={{
                                                                                margin: theme.spacing.unit,
                                                                                color: colors.grey[700]
                                                                            }}
                                                                        >
                                                                            info
                                                                        </Icon>
                                                                        <Typography
                                                                            variant="caption"
                                                                            style={{
                                                                                color: colors.grey[700]
                                                                            }}
                                                                        >
                                                                            There are three registration email templates
                                                                            available. The email template corresponding
                                                                            with the users specific location type will
                                                                            be used to send notifications.
                                                                        </Typography>
                                                                    </div>
                                                                </Grid>
                                                            )} */}
                                                        <Grid item xs={12}>
                                                            <div style={{ display: 'flex' }}>
                                                                {_.get(
                                                                    formik.values,
                                                                    `emails.${emailTemplateSelected}.icon`
                                                                ) === 'None' ? (
                                                                    <Icon
                                                                        style={{
                                                                            color: theme.palette.text.disabled,
                                                                            fontSize: 55,
                                                                            marginRight: theme.spacing.unit
                                                                        }}
                                                                    >
                                                                        hide_image
                                                                    </Icon>
                                                                ) : (
                                                                    <img
                                                                        alt={_.get(
                                                                            formik.values,
                                                                            `emails.${emailTemplateSelected}.icon`
                                                                        )}
                                                                        style={{
                                                                            height: 55,
                                                                            marginRight: theme.spacing.unit
                                                                        }}
                                                                        src={`${iconPath}/${_.get(
                                                                            formik.values,
                                                                            `emails.${emailTemplateSelected}.icon`
                                                                        )}.png`}
                                                                    />
                                                                )}

                                                                <FormControl fullWidth>
                                                                    <InputLabel variant="outlined">Icon</InputLabel>
                                                                    <Select
                                                                        {...formik.getFieldProps(
                                                                            `emails.${emailTemplateSelected}.icon`
                                                                        )}
                                                                        // value={emailTemplateSelected}
                                                                        // onChange={e => setEmailTemplateSelected(e.target.value)}
                                                                        input={
                                                                            <OutlinedInput
                                                                                labelWidth={
                                                                                    'Icon'.length * theme.spacing.unit
                                                                                }
                                                                                name="iconSelect"
                                                                            />
                                                                        }
                                                                        style={{
                                                                            minWidth: 168
                                                                        }}
                                                                        data-cy={`information-forms-email-icon-select`}
                                                                    >
                                                                        {[
                                                                            'None',
                                                                            'appcircle',
                                                                            'calendar',
                                                                            'truck',
                                                                            'cardcircle',
                                                                            'check',
                                                                            'heart',
                                                                            'bin'
                                                                        ].map(value => (
                                                                            <MenuItem
                                                                                data-cy={`information-forms-email-icon-select-${value}`}
                                                                                value={value}
                                                                            >
                                                                                {value}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </Grid>

                                                        {Object.keys(
                                                            _.get(
                                                                formik,
                                                                `values.emails.${emailTemplateSelected}.${lang}`
                                                            )
                                                        ).map((objKey, idx) => {
                                                            let path = `emails.${emailTemplateSelected}.${lang}`;
                                                            let obj = _.get(
                                                                formik,
                                                                `values.emails.${emailTemplateSelected}.${lang}`
                                                            );
                                                            // if contain array of strings, create textfield for each element
                                                            if (
                                                                objKey.toLowerCase().includes('sms') ||
                                                                objKey.toLowerCase().includes('push')
                                                            ) {
                                                                return <></>;
                                                            }
                                                            if (
                                                                !_.get(
                                                                    formik.values,
                                                                    `emails.${emailTemplateSelected}.inactiveCustomerEmail`,
                                                                    false
                                                                ) &&
                                                                ['subject', 'preheader'].includes(objKey)
                                                            ) {
                                                                return <></>;
                                                            } else if (Array.isArray(obj[objKey])) {
                                                                return obj[objKey].map((item, childIdx) => (
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            {...formik.getFieldProps(
                                                                                `${path}.${objKey}[${childIdx}]`
                                                                            )}
                                                                            error={
                                                                                _.get(
                                                                                    formik.touched,
                                                                                    `${path}.${objKey}[${childIdx}]`,
                                                                                    false
                                                                                ) &&
                                                                                _.get(
                                                                                    formik.errors,
                                                                                    `${path}.${objKey}[${childIdx}]`,
                                                                                    false
                                                                                )
                                                                                    ? true
                                                                                    : null
                                                                            }
                                                                            InputLabelProps={{
                                                                                shrink: !_.isEmpty(
                                                                                    formik.getFieldProps(
                                                                                        `${path}.${objKey}[${childIdx}]`
                                                                                    ).value
                                                                                )
                                                                            }}
                                                                            label={`${_.startCase(objKey)} ${childIdx +
                                                                                1}`}
                                                                            style={{
                                                                                marginTop: theme.spacing.unit
                                                                            }}
                                                                            multiline
                                                                            variant="outlined"
                                                                            helperText={
                                                                                _.get(
                                                                                    formik.touched,
                                                                                    `${path}.${objKey}[${childIdx}]`,
                                                                                    false
                                                                                ) &&
                                                                                _.get(
                                                                                    formik.errors,
                                                                                    `${path}.${objKey}[${childIdx}]`,
                                                                                    false
                                                                                )
                                                                                    ? _.get(
                                                                                          formik.errors,
                                                                                          `${path}.${objKey}[${childIdx}]`,
                                                                                          false
                                                                                      )
                                                                                    : null
                                                                            }
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                ));
                                                            } else {
                                                                return (
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            {...formik.getFieldProps(
                                                                                `${path}.${objKey}`
                                                                            )}
                                                                            error={
                                                                                _.get(
                                                                                    formik.touched,
                                                                                    `${path}.${objKey}`,
                                                                                    false
                                                                                ) &&
                                                                                _.get(
                                                                                    formik.errors,
                                                                                    `${path}.${objKey}`,
                                                                                    false
                                                                                )
                                                                                    ? true
                                                                                    : null
                                                                            }
                                                                            InputLabelProps={{
                                                                                shrink: !_.isEmpty(
                                                                                    formik.getFieldProps(
                                                                                        `${path}.${objKey}`
                                                                                    ).value
                                                                                )
                                                                            }}
                                                                            label={_.startCase(objKey)}
                                                                            style={{
                                                                                marginTop: theme.spacing.unit
                                                                            }}
                                                                            multiline
                                                                            variant="outlined"
                                                                            helperText={
                                                                                _.get(
                                                                                    formik.touched,
                                                                                    `${path}.${objKey}`,
                                                                                    false
                                                                                ) &&
                                                                                _.get(
                                                                                    formik.errors,
                                                                                    `${path}.${objKey}`,
                                                                                    false
                                                                                )
                                                                                    ? _.get(
                                                                                          formik.errors,
                                                                                          `${path}.${objKey}`,
                                                                                          false
                                                                                      )
                                                                                    : null
                                                                            }
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                );
                                                            }
                                                        })}
                                                    </Grid>
                                                )}
                                                {emailTemplateSelected === 'MSG_FORMATTING_PREVIEW' && (
                                                    <Grid container spacing={theme.spacing.unit}>
                                                        <Grid item xs="12">
                                                            <CustomFormTitle titleText="Images" iconName="image" />
                                                            <Typography variant="caption">
                                                                <Checkbox
                                                                    {...formik.getFieldProps(
                                                                        `emailConfig.globalFormatting.showIcon`
                                                                    )}
                                                                    checked={_.get(
                                                                        formik.values,
                                                                        'emailConfig.globalFormatting.showIcon'
                                                                    )}
                                                                    style={{
                                                                        padding: theme.spacing.unit / 2
                                                                    }}
                                                                />
                                                                Show Header Icon
                                                            </Typography>
                                                            <TextAlignmentToggle
                                                                formik={formik}
                                                                theme={theme}
                                                                path="emailConfig.globalFormatting.iconAlignment"
                                                                name="icon"
                                                            />
                                                            <Typography variant="caption">
                                                                <Checkbox
                                                                    {...formik.getFieldProps(
                                                                        `emailConfig.globalFormatting.useDefaultIssueImage`
                                                                    )}
                                                                    checked={_.get(
                                                                        formik.values,
                                                                        'emailConfig.globalFormatting.useDefaultIssueImage'
                                                                    )}
                                                                    style={{
                                                                        padding: theme.spacing.unit / 2
                                                                    }}
                                                                />
                                                                Use Default Issue Image
                                                            </Typography>
                                                            <TextField
                                                                {...formik.getFieldProps(
                                                                    `emailConfig.globalFormatting.defaultIssueImageUrl`
                                                                )}
                                                                disabled={
                                                                    !_.get(
                                                                        formik.values,
                                                                        'emailConfig.globalFormatting.useDefaultIssueImage',
                                                                        false
                                                                    )
                                                                }
                                                                fullWidth
                                                                type="url"
                                                                label="Image URL"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs="12">
                                                            <CustomFormTitle titleText="Title" iconName="title" />
                                                        </Grid>
                                                        <Grid item xs="12">
                                                            <TextAlignmentToggle
                                                                formik={formik}
                                                                theme={theme}
                                                                path="emailConfig.globalFormatting.titleAlignment"
                                                                name="title"
                                                            />
                                                        </Grid>
                                                        <Grid item sm="6" xs="12">
                                                            <TextStyleSelect
                                                                formik={formik}
                                                                theme={theme}
                                                                path="emailConfig.globalFormatting.titleTextVariant"
                                                                label="Style"
                                                                name="title"
                                                            />
                                                        </Grid>
                                                        <Grid item sm="6" xs="12">
                                                            <FontWeightSelect
                                                                formik={formik}
                                                                theme={theme}
                                                                path="emailConfig.globalFormatting.titleFontWeight"
                                                                name="title"
                                                                label="Font Weight"
                                                            />
                                                        </Grid>
                                                        <Grid item xs="12">
                                                            <CustomFormTitle titleText="Text" iconName="text_format" />
                                                            <TextAlignmentToggle
                                                                formik={formik}
                                                                theme={theme}
                                                                path="emailConfig.globalFormatting.bodyAlignment"
                                                                name="body"
                                                            />
                                                            <TextStyleSelect
                                                                formik={formik}
                                                                theme={theme}
                                                                path="emailConfig.globalFormatting.emphasisTextVariant"
                                                                label="Emphasis Text Style"
                                                                name="title"
                                                            />
                                                        </Grid>
                                                        <Grid item sm="6" xs="12">
                                                            <TextField
                                                                {...formik.getFieldProps(
                                                                    `emailConfig.globalFormatting.emphasisTextColor`
                                                                )}
                                                                type="color"
                                                                label="Emphasis Text Color"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item sm="6" xs="12">
                                                            <TextField
                                                                {...formik.getFieldProps(
                                                                    `emailConfig.globalFormatting.emphasisTextBackgroundColor`
                                                                )}
                                                                type="color"
                                                                label="Emphasis Text Background Color"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs="12">
                                                            <CustomFormTitle titleText="Button" iconName="ads_click" />
                                                            <TextAlignmentToggle
                                                                formik={formik}
                                                                theme={theme}
                                                                path="emailConfig.globalFormatting.buttonAlignment"
                                                                name="button"
                                                            />
                                                        </Grid>
                                                        <Grid item sm="6" xs="12">
                                                            <TextField
                                                                {...formik.getFieldProps(
                                                                    `emailConfig.globalFormatting.buttonColor`
                                                                )}
                                                                type="color"
                                                                label="Background Color"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item sm="6" xs="12">
                                                            <TextField
                                                                {...formik.getFieldProps(
                                                                    `emailConfig.globalFormatting.buttonBorderColor`
                                                                )}
                                                                type="color"
                                                                label="Border Color"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item sm="6" xs="12">
                                                            <TextField
                                                                {...formik.getFieldProps(
                                                                    `emailConfig.globalFormatting.buttonBorderWidth`
                                                                )}
                                                                type="number"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            px
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                fullWidth
                                                                label="Border Width"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item sm="6" xs="12">
                                                            <TextField
                                                                {...formik.getFieldProps(
                                                                    `emailConfig.globalFormatting.buttonBorderRadius`
                                                                )}
                                                                type="number"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            px
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                fullWidth
                                                                label="Border Radius"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item sm="6" xs="12">
                                                            <TextField
                                                                {...formik.getFieldProps(
                                                                    `emailConfig.globalFormatting.buttonTextColor`
                                                                )}
                                                                type="color"
                                                                label="Text Color"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item sm="6" xs="12">
                                                            <TextField
                                                                {...formik.getFieldProps(
                                                                    `emailConfig.globalFormatting.buttonFontSize`
                                                                )}
                                                                type="number"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            px
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                fullWidth
                                                                margin="normal"
                                                                label="Font Size"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item sm="6" xs="12">
                                                            <FontWeightSelect
                                                                formik={formik}
                                                                theme={theme}
                                                                path="emailConfig.globalFormatting.buttonFontWeight"
                                                                name="button"
                                                                label="Font Weight"
                                                            />
                                                        </Grid>
                                                        <Grid item sm="6" xs="12">
                                                            <FormControl
                                                                fullWidth
                                                                style={{
                                                                    marginTop: theme.spacing.unit
                                                                }}
                                                            >
                                                                <InputLabel variant="outlined">
                                                                    Text Transform
                                                                </InputLabel>
                                                                <Select
                                                                    {...formik.getFieldProps(
                                                                        `emailConfig.globalFormatting.buttonTextTransform`
                                                                    )}
                                                                    input={
                                                                        <OutlinedInput
                                                                            labelWidth={
                                                                                'Text Transform'.length *
                                                                                theme.spacing.unit
                                                                            }
                                                                            name="buttonTextTransformSelect"
                                                                        />
                                                                    }
                                                                    style={{
                                                                        minWidth: 168
                                                                    }}
                                                                    data-cy={`email-button-text-transform-select`}
                                                                >
                                                                    <MenuItem
                                                                        data-cy={`email-button-text-transform-select-none`}
                                                                        value={'none'}
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                margin: 0,
                                                                                textTransform: 'none'
                                                                            }}
                                                                        >
                                                                            None
                                                                        </p>
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        data-cy={`email-button-text-transform-select-uppercase`}
                                                                        value={'uppercase'}
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                margin: 0,
                                                                                textTransform: 'uppercase'
                                                                            }}
                                                                        >
                                                                            uppercase
                                                                        </p>
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        data-cy={`email-button-text-transform-select-lowercase`}
                                                                        value={'lowercase'}
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                margin: 0,
                                                                                textTransform: 'lowercase'
                                                                            }}
                                                                        >
                                                                            lowercase
                                                                        </p>
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        data-cy={`email-button-text-transform-select-capitalize`}
                                                                        value={'capitalize'}
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                margin: 0,
                                                                                textTransform: 'capitalize'
                                                                            }}
                                                                        >
                                                                            capitalize
                                                                        </p>
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </div>
                                        </Grid>

                                        <Grid item xs="12" sm="6">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Typography variant="h6">Preview</Typography>
                                                <IconButton
                                                    style={{ padding: 0 }}
                                                    disabled={inProgress}
                                                    onClick={() => updateCurrentEmailHtml()}
                                                >
                                                    <Icon>refresh</Icon>
                                                </IconButton>
                                            </div>
                                            <Divider />
                                            <div
                                                style={{
                                                    minHeight: 300,
                                                    maxHeight: 600,
                                                    overflowY: 'scroll',
                                                    border: '1px solid grey',
                                                    borderRadius: 5,
                                                    margin: theme.spacing.unit,
                                                    padding: theme.spacing.unit * 2,
                                                    fontFamily: 'sans-serif',
                                                    color: colors.grey[700],
                                                    position: 'relative',
                                                    '-webkit-scrollbar': 'none',
                                                    '-ms-overflow-style': 'none',
                                                    scrollbarWidth: 'none'
                                                }}
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(currentEmailHtml)
                                                    }}
                                                />
                                            </div>
                                            <Grid container spacing={theme.spacing.unit}>
                                                <Grid item xs={12}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <Typography variant="h6">Send Test Email</Typography>
                                                        {/* <IconButton
                                                                    style={{ padding: 2 }}
                                                                    onClick={() =>
                                                                        setSendTestEmailExpanded(!sendTestEmailExpanded)
                                                                    }
                                                                >
                                                                    <Icon>
                                                                        {sendTestEmailExpanded
                                                                            ? 'keyboard_arrow_up'
                                                                            : 'keyboard_arrow_down'}
                                                                    </Icon>
                                                                </IconButton> */}
                                                    </div>
                                                </Grid>
                                                {/* <Collapse in={sendTestEmailExpanded}> */}
                                                <Grid item xs="12">
                                                    <Divider style={{ marginBottom: theme.spacing.unit * 2 }} />

                                                    <Grid container spacing={theme.spacing.unit}>
                                                        <Grid item xs="12">
                                                            <div style={{ display: 'flex' }}>
                                                                <TextField
                                                                    type="email"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    name="lat"
                                                                    label={'Recipient Email'}
                                                                    value={testEmailAddress}
                                                                    onChange={e =>
                                                                        setTestEmailAddress(
                                                                            e.target.value.toLowerCase()
                                                                        )
                                                                    }
                                                                    style={{
                                                                        paddingRight: theme.spacing.unit * 0.75
                                                                    }}
                                                                    error={
                                                                        !_.isEmpty(testEmailAddress) &&
                                                                        validate(['email'], testEmailAddress, lang).fail
                                                                    }
                                                                    helperText={
                                                                        !_.isEmpty(testEmailAddress) &&
                                                                        validate(['email'], testEmailAddress, lang)
                                                                            .reason
                                                                    }
                                                                />
                                                                <Button
                                                                    data-cy="send-test-email-button"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleSendTestEmail}
                                                                    style={{ height: 55, width: 150 }}
                                                                    disabled={
                                                                        _.isEmpty(testEmailAddress) ||
                                                                        validate(['email'], testEmailAddress, lang).fail
                                                                    }
                                                                >
                                                                    Send
                                                                    <Icon
                                                                        style={{
                                                                            marginLeft: theme.spacing.unit
                                                                        }}
                                                                    >
                                                                        reply
                                                                    </Icon>
                                                                </Button>
                                                                <Button
                                                                    data-cy="send-test-all-emails-button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={() =>
                                                                        warnAction(() => {
                                                                            handleSendAllTestEmails();
                                                                        }, `Are you sure you want to send ALL test emails to ${testEmailAddress}?`)
                                                                    }
                                                                    style={{
                                                                        height: 55,
                                                                        marginLeft: theme.spacing.unit,
                                                                        width: 255
                                                                    }}
                                                                    disabled={
                                                                        _.isEmpty(testEmailAddress) ||
                                                                        validate(['email'], testEmailAddress, lang).fail
                                                                    }
                                                                >
                                                                    Send All
                                                                    <Icon
                                                                        style={{
                                                                            marginLeft: theme.spacing.unit
                                                                        }}
                                                                    >
                                                                        reply_all
                                                                    </Icon>
                                                                </Button>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs="12">
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    textAlign: 'right'
                                                                }}
                                                            >
                                                                <ExternalLink
                                                                    text="Edit SendGrid Template"
                                                                    url={'https://mc.sendgrid.com/dynamic-templates'}
                                                                />
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs="12">
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between'
                                                                }}
                                                            >
                                                                <Typography variant="caption">
                                                                    <Switch
                                                                        name="customizeTestData"
                                                                        checked={customizeTestData}
                                                                        onChange={e =>
                                                                            setCustomizeTestData(e.target.checked)
                                                                        }
                                                                        color="primary"
                                                                        style={{
                                                                            padding: 0,
                                                                            margin: 0,
                                                                            height: '25px'
                                                                        }}
                                                                    />
                                                                    Customize Test Data
                                                                </Typography>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column'
                                                                    }}
                                                                >
                                                                    <Typography variant="caption">
                                                                        <Checkbox
                                                                            checked={
                                                                                emailDataLocationType === 'Residential'
                                                                            }
                                                                            onChange={e =>
                                                                                setEmailDataLocationType('Residential')
                                                                            }
                                                                            value={'Residential'}
                                                                            style={{
                                                                                padding: theme.spacing.unit / 2
                                                                            }}
                                                                        />
                                                                        {isEXPRegion() ? 'Personal' : 'Residential'}
                                                                    </Typography>
                                                                    {!isEXPRegion() && (
                                                                        <Typography variant="caption">
                                                                            <Checkbox
                                                                                checked={
                                                                                    emailDataLocationType === 'Condo'
                                                                                }
                                                                                onChange={e =>
                                                                                    setEmailDataLocationType('Condo')
                                                                                }
                                                                                value={'Condo'}
                                                                                style={{
                                                                                    padding: theme.spacing.unit / 2
                                                                                }}
                                                                            />
                                                                            Condo
                                                                        </Typography>
                                                                    )}
                                                                    <Typography variant="caption">
                                                                        <Checkbox
                                                                            checked={
                                                                                emailDataLocationType === 'Commercial'
                                                                            }
                                                                            onChange={e =>
                                                                                setEmailDataLocationType('Commercial')
                                                                            }
                                                                            value={'Commercial'}
                                                                            style={{
                                                                                padding: theme.spacing.unit / 2
                                                                            }}
                                                                        />
                                                                        {isEXPRegion() ? 'Business' : 'Commercial'}
                                                                    </Typography>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column'
                                                                    }}
                                                                >
                                                                    <Typography variant="caption">
                                                                        <Checkbox
                                                                            checked={includeCharityFields}
                                                                            onChange={e =>
                                                                                setIncludeCharityFields(
                                                                                    e.target.checked
                                                                                )
                                                                            }
                                                                            value={includeCharityFields}
                                                                            style={{
                                                                                padding: theme.spacing.unit / 2
                                                                            }}
                                                                        />
                                                                        Donating / Is Charity
                                                                    </Typography>
                                                                    <Typography variant="caption">
                                                                        <Checkbox
                                                                            checked={includeRedemptionFee}
                                                                            onChange={e =>
                                                                                setIncludeRedemptionFee(
                                                                                    e.target.checked
                                                                                )
                                                                            }
                                                                            value={includeRedemptionFee}
                                                                            style={{
                                                                                padding: theme.spacing.unit / 2
                                                                            }}
                                                                        />
                                                                        Redemption Fee
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs="12">
                                                            <Collapse in={customizeTestData}>
                                                                <TextField
                                                                    type="text"
                                                                    fullWidth
                                                                    multiline
                                                                    variant="outlined"
                                                                    name="lat"
                                                                    label={'TestData'}
                                                                    value={testData}
                                                                    onChange={e => setTestData(e.target.value)}
                                                                    style={{
                                                                        paddingRight: theme.spacing.unit * 0.75
                                                                    }}
                                                                />
                                                            </Collapse>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* </Collapse> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </FormsWidget>
                            )}
                            {!_.isNil(emailTemplateSelected) &&
                                !_.isEmpty(emailTemplateSelected) &&
                                emailTemplateSelected !== 'MSG_FORMATTING_PREVIEW' &&
                                _.some(['smsText', 'smsTextBasic', 'smsTextDetailed'], key =>
                                    Object.keys(
                                        _.get(formik, `values.emails.${emailTemplateSelected}.${lang}`)
                                    ).includes(key)
                                ) && (
                                    <FormsWidget
                                        theme={theme}
                                        formik={formik}
                                        cyString={`${lang}-sms-input`}
                                        title="Edit SMS Notification"
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <>
                                                {['smsText', 'smsTextBasic', 'smsTextDetailed'].map(field => {
                                                    if (
                                                        _.isNil(
                                                            _.get(
                                                                formik,
                                                                `values.emails.${emailTemplateSelected}.${lang}.${field}`
                                                            )
                                                        )
                                                    ) {
                                                        return <></>;
                                                    }
                                                    return (
                                                        <TextField
                                                            {...formik.getFieldProps(
                                                                `emails.${emailTemplateSelected}.${lang}.${field}`
                                                            )}
                                                            error={
                                                                _.get(
                                                                    formik.touched,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                ) &&
                                                                _.get(
                                                                    formik.errors,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                )
                                                                    ? true
                                                                    : null
                                                            }
                                                            InputLabelProps={{
                                                                shrink: !_.isEmpty(
                                                                    formik.getFieldProps(
                                                                        `emails.${emailTemplateSelected}.${lang}.${field}`
                                                                    ).value
                                                                )
                                                            }}
                                                            label={_.startCase(field)}
                                                            style={{
                                                                marginTop: theme.spacing.unit
                                                            }}
                                                            multiline
                                                            variant="outlined"
                                                            helperText={
                                                                _.get(
                                                                    formik.touched,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                ) &&
                                                                _.get(
                                                                    formik.errors,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                )
                                                                    ? _.get(
                                                                          formik.errors,
                                                                          `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                          false
                                                                      )
                                                                    : null
                                                            }
                                                            fullWidth
                                                        />
                                                    );
                                                })}
                                            </>
                                        </Grid>
                                    </FormsWidget>
                                )}
                            {!_.isNil(emailTemplateSelected) &&
                                !_.isEmpty(emailTemplateSelected) &&
                                emailTemplateSelected !== 'MSG_FORMATTING_PREVIEW' &&
                                _.some(['pushText', 'pushTitle', 'pushTextCharity'], key =>
                                    Object.keys(
                                        _.get(formik, `values.emails.${emailTemplateSelected}.${lang}`)
                                    ).includes(key)
                                ) && (
                                    <FormsWidget
                                        theme={theme}
                                        formik={formik}
                                        cyString={`${lang}-push-notification-input`}
                                        title="Edit Push Notification"
                                    >
                                        <Grid container spacing={theme.spacing.unit}>
                                            <>
                                                {['pushTitle', 'pushText', 'pushTextCharity'].map(field => {
                                                    if (
                                                        _.isNil(
                                                            _.get(
                                                                formik,
                                                                `values.emails.${emailTemplateSelected}.${lang}.${field}`
                                                            )
                                                        )
                                                    ) {
                                                        return <></>;
                                                    }
                                                    return (
                                                        <TextField
                                                            {...formik.getFieldProps(
                                                                `emails.${emailTemplateSelected}.${lang}.${field}`
                                                            )}
                                                            error={
                                                                _.get(
                                                                    formik.touched,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                ) &&
                                                                _.get(
                                                                    formik.errors,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                )
                                                                    ? true
                                                                    : null
                                                            }
                                                            InputLabelProps={{
                                                                shrink: !_.isEmpty(
                                                                    formik.getFieldProps(
                                                                        `emails.${emailTemplateSelected}.${lang}.${field}`
                                                                    ).value
                                                                )
                                                            }}
                                                            label={_.startCase(field)}
                                                            style={{
                                                                marginTop: theme.spacing.unit
                                                            }}
                                                            multiline
                                                            variant="outlined"
                                                            helperText={
                                                                _.get(
                                                                    formik.touched,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                ) &&
                                                                _.get(
                                                                    formik.errors,
                                                                    `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                    false
                                                                )
                                                                    ? _.get(
                                                                          formik.errors,
                                                                          `emails.${emailTemplateSelected}.${lang}.${field}`,
                                                                          false
                                                                      )
                                                                    : null
                                                            }
                                                            fullWidth
                                                        />
                                                    );
                                                })}
                                            </>
                                        </Grid>
                                    </FormsWidget>
                                )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withWidth()(withTheme()(Emails));

function FormsWidget(props) {
    const { children, theme, cyString, title, collapse } = props;
    const [expanded, setExpanded] = useState(false);

    return (
        <Paper
            style={{
                padding: theme.spacing.unit * 2,
                margin: theme.spacing.unit / 2,
                marginTop: theme.spacing.unit * 2
            }}
        >
            {!_.isNil(title) && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography variant="h6">{title}</Typography>
                        {collapse && (
                            <IconButton style={{ padding: 0, margin: 2 }} onClick={() => setExpanded(!expanded)}>
                                <Icon>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                            </IconButton>
                        )}
                    </div>
                    <Divider style={{ marginBottom: theme.spacing.unit * 2 }} />
                </>
            )}
            {collapse ? (
                <Collapse in={expanded}>
                    <Grid container spacing={theme.spacing.unit} data-cy={cyString ? cyString : null}>
                        {children}
                    </Grid>
                </Collapse>
            ) : (
                <Grid container spacing={theme.spacing.unit} data-cy={cyString ? cyString : null}>
                    {children}
                </Grid>
            )}
        </Paper>
    );
}

const SearchBar = ({ searchString, handleSearchChange }) => {
    return (
        <div style={{ marginLeft: 16, marginRight: 16 }}>
            <TextField
                name="search"
                label="Search"
                fullWidth
                variant="outlined"
                value={searchString || ''}
                onChange={e => handleSearchChange(e)}
                margin="dense"
                autoComplete="false"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
        </div>
    );
};

const TextAlignmentToggle = ({ formik, theme, path, name }) => {
    return (
        <ToggleButtonGroup
            value={_.get(formik.values, path)}
            exclusive
            onChange={(e, value) => formik.setFieldValue(path, value)}
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginRight: theme.spacing.unit
            }}
        >
            <ToggleButton style={{ flexGrow: 1 }} value="left" data-cy={`${name}-align-left`}>
                <Icon>format_align_left</Icon>
            </ToggleButton>
            <ToggleButton style={{ flexGrow: 1 }} value="center" data-cy={`${name}-align-center`}>
                <Icon>format_align_center</Icon>
            </ToggleButton>
            <ToggleButton style={{ flexGrow: 1 }} value="right" data-cy={`${name}-align-right`}>
                <Icon>format_align_right</Icon>
            </ToggleButton>
            <ToggleButton style={{ flexGrow: 1 }} value="justify" data-cy={`${name}-align-justify`}>
                <Icon>format_align_justify</Icon>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

const FontWeightSelect = ({ formik, theme, path, name, label }) => {
    return (
        <FormControl
            fullWidth
            style={{
                marginTop: theme.spacing.unit
            }}
        >
            <InputLabel variant="outlined">{label}</InputLabel>
            <Select
                {...formik.getFieldProps(path)}
                input={
                    <OutlinedInput labelWidth={label.length * theme.spacing.unit} name={`${name}FontWeightSelect`} />
                }
                style={{
                    minWidth: 168
                }}
                data-cy={`email-${name}-font-weight-select`}
            >
                <MenuItem data-cy={`email-${name}-font-weight-select-300`} value={'300'}>
                    <p style={{ margin: 0, fontWeight: '300' }}>300</p>
                </MenuItem>
                <MenuItem data-cy={`email-${name}-font-weight-select-400`} value={'400'}>
                    <p style={{ margin: 0, fontWeight: '400' }}>400</p>
                </MenuItem>
                <MenuItem data-cy={`email-${name}-font-weight-select-500`} value={'500'}>
                    <p style={{ margin: 0, fontWeight: '500' }}>500</p>
                </MenuItem>
                <MenuItem data-cy={`email-${name}-font-weight-select-600`} value={'600'}>
                    <p style={{ margin: 0, fontWeight: '600' }}>600</p>
                </MenuItem>
                <MenuItem data-cy={`email-${name}-font-weight-select-700`} value={'700'}>
                    <p style={{ margin: 0, fontWeight: '700' }}>700</p>
                </MenuItem>
                <MenuItem data-cy={`email-${name}-font-weight-select-normal`} value={'normal'}>
                    <p
                        style={{
                            margin: 0,
                            fontWeight: 'normal'
                        }}
                    >
                        normal
                    </p>
                </MenuItem>
                <MenuItem data-cy={`email-${name}-font-weight-select-bold`} value={'bold'}>
                    <p
                        style={{
                            margin: 0,
                            fontWeight: 'bold'
                        }}
                    >
                        bold
                    </p>
                </MenuItem>
            </Select>
        </FormControl>
    );
};

const TextStyleSelect = ({ formik, theme, path, name, label }) => {
    return (
        <FormControl
            fullWidth
            style={{
                marginTop: theme.spacing.unit
            }}
        >
            <InputLabel variant="outlined">{label}</InputLabel>
            <Select
                {...formik.getFieldProps(path)}
                input={<OutlinedInput labelWidth={label.length * theme.spacing.unit} name={`${name}StyleSelect`} />}
                style={{
                    minWidth: 168
                }}
                data-cy={`email-${name}-style-select`}
            >
                <MenuItem data-cy={`email-${name}-style-select-p`} value={'p'}>
                    <p style={{ margin: 0 }}>Normal</p>
                </MenuItem>
                <MenuItem data-cy={`email-${name}-style-select-h1`} value={'h1'}>
                    <h1 style={{ margin: 0 }}>Heading 1</h1>
                </MenuItem>
                <MenuItem data-cy={`email-${name}-style-select-h2`} value={'h2'}>
                    <h2 style={{ margin: 0 }}>Heading 2</h2>
                </MenuItem>
                <MenuItem data-cy={`email-${name}-style-select-h3`} value={'h3'}>
                    <h3 style={{ margin: 0 }}>Heading 3</h3>
                </MenuItem>
                <MenuItem data-cy={`email-${name}-style-select-h4`} value={'h4'}>
                    <h4 style={{ margin: 0 }}>Heading 4</h4>
                </MenuItem>
                <MenuItem data-cy={`email-${name}-style-select-h5`} value={'h5'}>
                    <h5 style={{ margin: 0 }}>Heading 5</h5>
                </MenuItem>
                <MenuItem data-cy={`email-${name}-style-select-h6`} value={'h6'}>
                    <h6 style={{ margin: 0 }}>Heading 6</h6>
                </MenuItem>
            </Select>
        </FormControl>
    );
};
