import React, { useContext } from 'react';

import _ from 'lodash';

import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import CharitySelect from '../../CharitySelect/CharitySelect';

import { canRedeemWithoutDonation } from 'helpers/redemptionHelper';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import { Typography } from '@material-ui/core';
import { isEXPRegion } from 'utils/misc';

const Donations = ({
    donate,
    charities,
    charityPreferred,
    subdivisionPreferred,
    taxReceiptRequested,
    customerLocation,
    customer,
    theme,
    cashOutAvailable,
    handleCharityPreferred,
    handleChange,
    toggleDonate,
    handleSetDonation,
    // toggleTaxReceiptRequested,
    onCustomerLocation,
    onClose,
    handleBack,
    handleNext,
    handleConfirmationDialog,
    balance,
    minRedemption,
    activeStep,
    http,
    google,
    hasLastPickup,
    redemptionFee,
    lockDonationPreference,
    disablingAccount = false,
    deletingAccount = false
}) => {
    const { lang } = useContext(LocalizationContext);
    const charitySelected = _.find(charities, charity => charity._id === charityPreferred && charity.approved);
    const donateDisabled =
        _.isEmpty(_.get(customerLocation, 'description', null)) &&
        taxReceiptRequested &&
        donate &&
        charitySelected.taxReceiptsIssued;

    const redeemWithoutDonation = canRedeemWithoutDonation(customer, balance, minRedemption, redemptionFee);
    return (
        <>
            <DialogContent>
                <CustomFormTitle titleText={loc('pickupDialog49', lang)} iconName="loyalty" />
                {isEXPRegion() &&
                    (donate ? (
                        <Typography>
                            If you <b>do not</b> want to to donate, remove the selected organization below. Otherwise,
                            proceed with donation. <b>Note: all redemptions are for the full account balance.</b>
                        </Typography>
                    ) : (
                        <Typography>
                            If you would like to donate, select an organization below. Otherwise, proceed without
                            donation. <b>Note: all redemptions are for the full account balance.</b>
                        </Typography>
                    ))}
                <CharitySelect
                    donate={donate}
                    charities={charities}
                    charityPreferred={charityPreferred}
                    subdivisionPreferred={subdivisionPreferred}
                    taxReceiptRequested={taxReceiptRequested}
                    style={{ marginTop: isEXPRegion() ? theme.spacing.unit * 3 : theme.spacing.unit }}
                    onCharityPreferred={handleCharityPreferred}
                    onChange={handleChange}
                    onSwitch={toggleDonate}
                    onSetDonation={handleSetDonation}
                    // onToggleTaxReceiptRequested={toggleTaxReceiptRequested}
                    showAddressInputOnTaxRecipts={!hasLastPickup}
                    location={customerLocation}
                    onLocation={onCustomerLocation}
                    http={http}
                    google={google}
                    lockDonationPreference={lockDonationPreference}
                />
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Hidden smUp>
                    <Button color="primary" onClick={onClose}>
                        {loc('close', lang)}
                    </Button>
                </Hidden>
                {activeStep > 0 && (
                    <Button color="primary" onClick={handleBack}>
                        {loc('back', lang)}
                    </Button>
                )}

                {donate ? (
                    <Button
                        data-cy="redemption-dialog-donate"
                        color="primary"
                        disabled={(donate && _.isEmpty(charityPreferred)) || donateDisabled}
                        onClick={() => handleConfirmationDialog(true)}
                    >
                        {disablingAccount
                            ? loc('donateAndDisable', lang)
                            : deletingAccount
                            ? loc('donateAndDelete', lang)
                            : isEXPRegion()
                            ? loc('donateExpressApp', lang)
                            : loc('donate', lang)}
                    </Button>
                ) : (
                    (cashOutAvailable || redeemWithoutDonation) && (
                        <Button
                            data-cy="redemption-dialog-next"
                            color="primary"
                            onClick={handleNext}
                            disabled={!redeemWithoutDonation}
                        >
                            {redeemWithoutDonation
                                ? isEXPRegion()
                                    ? loc('pickupDialog70', lang)
                                    : loc('next', lang)
                                : loc('next', lang)}
                        </Button>
                    )
                )}
            </DialogActions>
        </>
    );
};

export default Donations;
