import React, { useContext, useState, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Typography,
    Tooltip,
    Button,
    IconButton,
    Dialog,
    DialogContent,
    colors,
    Icon,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    withTheme,
    Divider,
    Chip,
    Paper,
    withStyles
} from '@material-ui/core';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import ImageViewer from 'components/ImageUploads/ImageViewer';

import { loc, locDate } from 'localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';

import { _pickup, _bulk, _commodity, _user } from 'std';

import {
    isEXPRegion,
    getHomeMarkerPayloadString,
    getCustomerName,
    getPlural,
    truncateBagtag,
    uppercaseFirstLetter
} from '../../../utils/misc';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { openWindow } from 'helpers/windowHelper';

function PickupSummary(props) {
    const {
        pickup,
        customer,
        adminView,
        commoditiesAvailable,
        handleViewCustomerReport,
        driverIssuesTxt,
        history,
        auth,
        theme,
        rolePermissions
    } = props;

    const { lang } = useContext(LocalizationContext);

    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [imagesToView, setImagesToView] = useState([]);

    const driverIssues = _.get(pickup, 'driverIssues', []);
    const payloadIssues = _.get(pickup, 'bulks[0].payloadIssues.issues', []);
    const payloadIssuesImages = _.get(pickup, 'bulks[0].payloadIssues.images', []);
    let imageUrls = _.get(pickup, 'images.pickupImages', []);

    // iterate through the bulks and determine how many of the multiple bulks is counted
    const bulksCounted = _pickup.numberOfCountedBulksOnPickup(pickup);
    const bulksCompleted = _pickup.numberOfCompletedBulksOnPickup(pickup);
    const counted = bulksCounted === pickup.bulks.length;
    const completed = bulksCompleted === pickup.bulks.length;
    const timezone = _.get(pickup, 'collector.timzone', process.env.REACT_APP_REGION_TIMEZONE);

    //used for messaging the collector as an admin
    const firstBulk = _.first(pickup.bulks);

    const allBagTags = pickup.bulks.map(b => ({ bagtag: b.label, dateCounted: b.dateCounted }));

    const hasDriverIssues = !_.isNil(driverIssues) && !_.isEmpty(driverIssues);
    //const hasReportingIssues = !_.isNil(payloadIssues) && !_.isEmpty(payloadIssues) && !_.isNil(payloadIssuesImages);

    let promosApplied = [];
    if (!_.isEmpty(pickup.bulks)) {
        pickup.bulks.forEach(b => {
            if (!_.isEmpty(b.promosApplied)) {
                promosApplied = promosApplied.concat(b.promosApplied);
            }
        });
    } else {
        promosApplied = _.get(pickup, 'promos', []);
    }
    const payload = _.get(pickup, 'payload', {});
    /*const payloadCommodities = Object.keys(payload)
        //.filter(skuType => _.get(payload, skuType, 0) > 0)
        .map(skuType => _.find(commoditiesAvailable, { skuType }))
        .filter(commodity => !_.isNil(commodity));*/

    const commoditiesForStats = useMemo(() => _.filter(commoditiesAvailable, { includeContainersInStats: true }), [
        commoditiesAvailable
    ]);
    const commoditiesNotForStats = useMemo(
        () => _.filter(commoditiesAvailable, c => !_.get(c, 'includeContainersInStats')),
        [commoditiesAvailable]
    );

    const commoditiesNotForStatsHasPayload = useMemo(() => {
        return _.some(commoditiesNotForStats, c => _.get(payload, _commodity.getSkuType(c)) > 0);
    }, [commoditiesNotForStats, payload]);

    const tableCellStyles = { padding: 0, color: theme.palette.text.secondary, borderBottom: 'none' };

    const handleViewImages = images => {
        setImageDialogOpen(true);
        setImagesToView(images);
    };

    const handleViewTrip = (externalWindow, collector, reciever, date) => {
        const URL = `/operators/${auth._id}/collector-completed?collector=${collector}&date=${moment(
            date,
            'YYYYMMDD'
        ).toISOString()}&receiver=${reciever}`;
        openWindow(history, externalWindow, URL);
    };

    const payloadStr = useMemo(() => {
        return commoditiesAvailable
            .map(commodity => {
                const payloadForCommodity = {};
                payloadForCommodity[_commodity.getSkuType(commodity)] = _.get(
                    payload,
                    _commodity.getSkuType(commodity),
                    0
                );
                const subPayloadsForCommodity = _.filter(_.get(pickup, 'subPayloads', []), {
                    skuType: _commodity.getSkuType(commodity)
                });

                const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups(
                    [{ payload: payloadForCommodity, subPayloads: subPayloadsForCommodity }],
                    commoditiesAvailable
                );
                const groupedPayloadTotals = _commodity.groupPayloadItemCountsByUnit(combinedPayloadItems);
                if (_.isEmpty(groupedPayloadTotals)) {
                    return '';
                } else {
                    let payloadStr = '';
                    Object.keys(groupedPayloadTotals).forEach((unit, i) => {
                        payloadStr += `${groupedPayloadTotals[unit]} ${
                            groupedPayloadTotals[unit] > 1
                                ? getPlural(_commodity.getUnit(commodity, lang))
                                : _commodity.getUnit(commodity, lang)
                        }`;
                    });
                    return (
                        payloadStr + ` ${loc('of', lang)} ${_commodity.getPayloadInputName(commodity).toLowerCase()}`
                    );
                }
            })
            .filter(val => !_.isEmpty(val))
            .join(', ');
    }, [commoditiesAvailable, payload, pickup, lang]);

    return (
        <>
            <Paper
                style={{
                    padding: theme.spacing.unit * 2,
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: theme.palette.background.default
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
                    <Typography variant="h6" style={{ marginRight: theme.spacing.unit * 2, fontSize: '1rem' }}>
                        {loc('dashboardHistory58', lang)}
                    </Typography>
                </div>
                {pickup.aborted && (
                    <Typography variant="body2" style={{ marginBottom: theme.spacing.unit }} color="textSecondary">
                        {loc('dashboardHistory66', lang, { reason: pickup.reason })}
                    </Typography>
                )}
                {pickup.complete && !pickup.aborted && (
                    <Typography color="textSecondary">
                        {loc('on', lang)}{' '}
                        <b>
                            {loc('dateAtTime', lang, {
                                date: locDate(_.get(pickup, 'completionDate'), 'MMM Do, YYYY', lang, timezone),
                                time: locDate(_.get(pickup, 'completionDate'), 'h:mm a', lang, timezone)
                            })}
                        </b>{' '}
                        {loc('weCollected', lang)} {payloadStr}{' '}
                        <b>
                            {_.get(pickup, 'location.description')
                                ? `${loc('at', lang)} ${formatAddress(_.get(pickup, 'location.description'))}.`
                                : '.'}
                        </b>{' '}
                        {!_.isEmpty(imageUrls) && (
                            <>
                                <span>
                                    <span
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        onClick={() => handleViewImages(imageUrls)}
                                    >
                                        {loc('tooltip2', lang)}
                                    </span>
                                </span>
                            </>
                        )}
                    </Typography>
                )}

                {hasDriverIssues && (
                    <>
                        <Typography style={{ color: colors.red[500], marginTop: 5 }}>
                            {loc('dashboardHistory30', lang)}: {driverIssuesTxt.map(issue => issue.name).join(', ')}
                            {', '}
                            <span
                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                onClick={handleViewCustomerReport(pickup.bulks[0], pickup)}
                            >
                                {loc('dashboardHistory22', lang)}
                            </span>
                        </Typography>
                    </>
                )}

                {!pickup.complete && (
                    <Typography color="textSecondary">{`${loc('dashboardHistory71', lang)}`}</Typography>
                )}

                {/*commoditiesForStats.map(commodity => {
                    const commodityIcon = _.get(commodity, 'iconName');

                    const payloadForCommodity = {};
                    payloadForCommodity[_commodity.getSkuType(commodity)] = _.get(
                        payload,
                        _commodity.getSkuType(commodity),
                        0
                    );
                    const subPayloadsForCommodity = _.filter(_.get(pickup, 'subPayloads', []), {
                        skuType: _commodity.getSkuType(commodity)
                    });

                    const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups(
                        [{ payload: payloadForCommodity, subPayloads: subPayloadsForCommodity }],
                        commoditiesAvailable
                    );
                    const groupedPayloadTotals = _commodity.groupPayloadItemCountsByUnit(combinedPayloadItems);

                    return (
                        !_.isEmpty(groupedPayloadTotals) && (
                            <div key={_.get(commodity, '_id')}>
                                <Typography color="textSecondary">
                                    {uppercaseFirstLetter(_commodity.getPayloadInputName(commodity))}
                                </Typography>
                                <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                                    <TableBody>
                                        {Object.keys(groupedPayloadTotals).map((unit, i) => (
                                            <TableRow style={{ height: 20 }} key={i}>
                                                <TableCell style={tableCellStyles}>
                                                    <span style={{ marginRight: theme.spacing.unit }}>
                                                        <MDIcon
                                                            path={_.get(allIcons, commodityIcon)}
                                                            size={0.6}
                                                            color={theme.palette.text.secondary}
                                                        />
                                                    </span>
                                                    {uppercaseFirstLetter(
                                                        groupedPayloadTotals[unit] > 1 ? getPlural(unit) : unit
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        ...tableCellStyles,
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    {groupedPayloadTotals[unit]}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        )
                    );
                })}

                {commoditiesNotForStatsHasPayload && (
                    <div>
                        <Typography color="textSecondary">{loc('other', lang)}</Typography>
                        <Table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                            <TableBody>
                                {commoditiesNotForStats.map(commodity => {
                                    const commodityIcon = _.get(commodity, 'iconName');

                                    const payloadForCommodity = {};
                                    payloadForCommodity[_commodity.getSkuType(commodity)] = _.get(
                                        payload,
                                        _commodity.getSkuType(commodity),
                                        0
                                    );
                                    const subPayloadsForCommodity = _.filter(_.get(pickup, 'subPayloads', []), {
                                        skuType: _commodity.getSkuType(commodity)
                                    });

                                    const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups(
                                        [{ payload: payloadForCommodity, subPayloads: subPayloadsForCommodity }],
                                        commoditiesAvailable
                                    );
                                    const groupedPayloadTotals = _commodity.groupPayloadItemCountsByUnit(
                                        combinedPayloadItems
                                    );
                                    return Object.keys(groupedPayloadTotals).map((unit, i) => (
                                        <TableRow style={{ height: 20 }} key={i}>
                                            <TableCell style={tableCellStyles}>
                                                <span style={{ marginRight: theme.spacing.unit }}>
                                                    <MDIcon
                                                        path={_.get(allIcons, commodityIcon)}
                                                        size={0.6}
                                                        color={theme.palette.text.secondary}
                                                    />
                                                </span>
                                                {_commodity.getPayloadInputName(commodity)}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    ...tableCellStyles,
                                                    textAlign: 'right'
                                                }}
                                            >
                                                {groupedPayloadTotals[unit]}
                                            </TableCell>
                                        </TableRow>
                                    ));
                                })}
                            </TableBody>
                        </Table>
                    </div>
                )*/}

                {/*beverage > 0 && (
                            <TableRow style={{ height: 36 }}>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {loc('dashboardHistory47', lang)}
                                </TableCell>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}>
                                    {beverage}
                                </TableCell>
                            </TableRow>
                        )}
                        {clothing > 0 && (
                            <TableRow style={{ height: 36 }}>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {loc('dashboardHistory48', lang)}
                                </TableCell>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}>
                                    {clothing}
                                </TableCell>
                            </TableRow>
                        )}
                        {electronics > 0 && (
                            <TableRow style={{ height: 36 }}>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {loc('dashboardHistory49', lang)}
                                </TableCell>
                                <TableCell style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}>
                                    {electronics}
                                </TableCell>
                            </TableRow>
                        )*/}
                {/* 
                {(adminView || !_.isEmpty(imageUrls)) && <Divider style={{ marginTop: 5, marginBottom: 5 }} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div> */}
                {adminView && (
                    <div style={{ width: '100%' }}>
                        <Tooltip title="View Trip">
                            <div
                                style={{
                                    height: 'auto',
                                    width: '100%',
                                    margin: theme.spacing.unit * 0.5,
                                    padding: theme.spacing.unit / 2,
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '16px',
                                    overflow: 'auto',
                                    cursor: 'pointer'
                                }}
                                onClick={e =>
                                    (_user.isSystemAdmin(auth) ||
                                        (_user.isInternalRole(auth) &&
                                            (rolePermissions &&
                                                rolePermissions.pickups &&
                                                rolePermissions.pickups.completed))) &&
                                    !_.isNil(_.get(pickup, 'collector')) &&
                                    handleViewTrip(
                                        e.ctrlKey,
                                        _.get(pickup, 'collector._id'),
                                        _.get(pickup, 'receiver._id'),
                                        displayDate(pickup)
                                    )
                                }
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <MDIcon
                                        path={_.get(allIcons, 'mdiTruck')}
                                        size={0.9}
                                        color={theme.palette.text.secondary}
                                        style={{ margin: theme.spacing.unit / 2 }}
                                    />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                            {!_.isNil(pickup.receiver) ? getCustomerName(pickup.receiver) : 'TBD'}
                                        </Typography>
                                        <Typography style={{ fontSize: '11px', lineHeight: 1.2 }}>
                                            Collector Code: {_.get(firstBulk, 'collector.code', 'N/A')} (
                                            {_pickup.getReceiverDistance(pickup)} m)
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </Tooltip>
                    </div>
                )}
                {/* </div>
                </div> */}
            </Paper>

            <Dialog open={imageDialogOpen} maxWidth="xs" fullWidth onClose={() => setImageDialogOpen(false)}>
                <DialogTitlePrimary closeButtonShown onClose={() => setImageDialogOpen(false)}>
                    {loc('history10', lang)}
                </DialogTitlePrimary>
                <DialogContent style={{ margin: theme.spacing.unit, padding: 0 }}>
                    <ImageViewer
                        displayImages={imagesToView}
                        disableDelete={true}
                        scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default withTheme()(PickupSummary);

function displayDate(pickup) {
    if (!_.isNil(pickup.completionDate)) {
        return pickup.completionDate;
    } else if (moment(pickup.date).isSame('2018-01-01T00:00:00Z', 'day')) {
        return 'Not assigned yet';
    } else {
        return pickup.date;
    }
}

function formatAddress(description) {
    let addressParts = description.split(', ');
    addressParts.splice(addressParts.length - 1, 1); //remove country
    addressParts[addressParts.length - 1] = addressParts[addressParts.length - 1].replace('Alberta', 'AB'); //shorten alberta

    return addressParts.join(', ');
}
