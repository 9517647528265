import React, { useState, useRef, useEffect, useContext } from 'react';

import _ from 'lodash';
import { _zone, _user, _pickup } from 'std';
import { MODES } from 'containers/Operators/Pickups/constants';
// import { getMapCenter, MODES } from 'helpers/mapHelper';
import { getQueryStringValue, setQueryStringValue } from 'utils/query';

import { isEXPRegion, wait } from 'utils/misc';
import * as mapThemes from './themes';
import * as colors from '@material-ui/core/colors';

import { LinearProgress } from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';

import {
    createMarker,
    requiresRender,
    centerMapOnPickups,
    setZonePolygons,
    setZoneLabels,
    setMarkerCurrentLocation,
    setMarkersStartLocations,
    setMarkersDestinations,
    setMarkerDestination,
    setMarkersPickups,
    setMarkerSelectedPickupAddress,
    setMarkersDrivers,
    setMarkersHomePickups,
    //setMarkerLiveInfo,
    setMarkerHomeOrganization,
    setMarkersCharities,
    setMarkersDropLocations,
    setMarkersReturnSites,
    setMarkersChildAccounts,
    setMarkersPastLocations,
    setMarkerStartLocation,
    drawSingleRoute,
    clearDirectionDisplay
} from './functions';
import Marker_Cluster_Indigo from 'icons/Marker_Cluster_Indigo.png';
import Marker_Cluster_Amber from 'icons/Marker_Cluster_Amber.png';
import Marker_Cluster_DeepOrange from 'icons/Marker_Cluster_DeepOrange.png';

import Pin_Cluster_STD from 'icons/Pin_Cluster_STD.png';
import Pin_Cluster_AUS from 'icons/Pin_Cluster_AUS.png';
import Pin_Cluster_CON from 'icons/Pin_Cluster_CON.png';
import Pin_Cluster_EXP from 'icons/Pin_Cluster_EXP.png';
import Pin_Cluster_SAR from 'icons/Pin_Cluster_REC.png';

import GoogleContext from 'utils/contexts/GoogleContext';

import usePrevious from 'utils/hooks/usePrevious';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { isValidGps } from 'utils/latlngFunctions';
import * as markerclusterer from '@googlemaps/markerclusterer';

const mapThemeLight = mapThemes.defaultLight;
const mapThemeDark = mapThemes.dark;

let zonePolygons = [];
let zoneLabels = [];

let markerCurrentLocation = [];
let markerStartLocation = {};
let markerDestination = {};
let markersDestinations = [];
let markersStartLocations = [];
let markersPickups = {};
let markersDrivers = {};
let markersCharities = {};
let markersDropLocations = {};
let markersChildAccounts = {};
let markersPastLocations = {};

let markerClusterer;
let charityMarkerClusterer;
// marker with no image thats used to display the address under the selected pickup, needed since markers cannot have more then one label.
let markerPickupSelectedAddress = {};

let polylines = [];
let directionDisplay = []; // direction for driver from current location to trip start location

//wraping this component in a memo makes it only rerender if its props have changed, similar to
//using extends PureComponent in class component but it only works for props, not state
//https://reactjs.org/docs/hooks-faq.html#:~:text=like%20Hooks%20do.-,React.,the%20old%20and%20new%20props.
const LargeMap = React.memo(
    props => {
        const {
            closeViewAllowed,
            mapCenter,
            defaultCoordinates,
            showDirections,
            zones,
            collectors,
            pickups,
            homePickups,
            homeOrganization,
            drivers,
            routes,
            charities,
            dropLocations,
            childAccounts = [],
            pastLocations,
            startLocations,
            destinations,
            currentLocation,
            pickupIdsChecked,
            startLocation,
            destination,
            accountType,
            seeAllPendingPickups,
            pickupRectangleSelect,
            offsetLegalInfo,
            hideLegalInfo,
            addRightOffsetLegalInfo,
            tripToCenter,
            mode,
            pickupSelected,
            zoneDataSelected,
            liveInfo,
            filters,
            filterDate,
            commoditiesAvailable,
            centerOnMarkersTrigger, //doesn't matter what value it is, if it changes the map will center on all markers
            style,
            onTripToCenter,
            onPickupDialog,
            onOpenPickupDialogWithMapMarker,
            onMapClick,
            onPickupClick,
            onZoneClick,
            onZoneBoundsChanged,
            onPinClick,
            onCharityClick,
            onDropLocationClick,
            onReturnSiteClick,
            onChildAccountClick,
            onPastLocationClick,
            preventDefaultCenterOnTrip,
            theme,
            dropLocationMarkerOverride = {},
            charityMarkerOverride = {},
            charityEnabled,
            pickupsEnabled
        } = props;

        const [map, setMap] = useState();
        //const [geoCoder, setGeoCode] = useState();
        //const [mapCenterCoordsListener, setMapCenterCoordsListener] = useState(null);
        const [routeIsLoading, setRouteIsLoading] = useState(_user.isDriver(accountType) ? true : false);

        const [preventCenterOnTrip, setPreventCenterOnTrip] = useState(preventDefaultCenterOnTrip);

        const prevProps = usePrevious(props);

        const { google } = useContext(GoogleContext);
        const { lang } = useContext(LocalizationContext);

        const MarkerWithLabel = require('markerwithlabel')(google.maps);
        const mapElemRef = useRef();
        const firstRenderRef = useRef(true);
        const shiftPressedRef = useRef(false);
        const throttledDrawDirectionRef = useRef(
            _.throttle(
                (directionDisplay, google, map, currentLocation, startLocation) => {
                    if (!_.isNil(currentLocation)) {
                        drawSingleRoute(directionDisplay, google, map, currentLocation, startLocation);
                    }
                },
                1000,
                { leading: true, trailing: false }
            )
        );
        const handleKeyDown = event => {
            if (event.code === 'ShiftLeft' || event.code === 'ShiftRight') {
                shiftPressedRef.current = true;
            }
        };

        const handleKeyUp = event => {
            if (event.code === 'ShiftLeft' || event.code === 'ShiftRight') {
                shiftPressedRef.current = false;
            }
        };

        const centerOnMarkers = () => {
            if (_.isEmpty(map)) {
                return;
            }

            let bounds = new google.maps.LatLngBounds();

            // use system default
            let defaultLat = defaultCoordinates.latitude;
            let defaultLng = defaultCoordinates.longitude;
            // const isEXP = process.env.REACT_APP_REGION_EXT === 'EXP';
            // if (isEXP) {
            //     // downtown Vancouver
            //     defaultLat = '49.281860';
            //     defaultLng = '-123.117148';
            // }
            if (_.isEmpty(pickups) && _.isNil(homePickups) && _.isNil(homeOrganization)) {
                let latLng = new google.maps.LatLng(defaultLat, defaultLng);
                bounds.extend(latLng);
            }

            (pickups || []).forEach(pickup => {
                let latLng = new google.maps.LatLng(pickup.location.lat, pickup.location.lng);
                bounds.extend(latLng);
            });

            (drivers || []).forEach(driver => {
                let latLng = new google.maps.LatLng(driver.location.lat, driver.location.lng);
                bounds.extend(latLng);
            });

            if (currentLocation && currentLocation.lat && currentLocation.lng) {
                let latLng = new google.maps.LatLng(currentLocation.lat, currentLocation.lng);
                bounds.extend(latLng);
            }

            if (startLocation && startLocation.lat && startLocation.lng) {
                let latLng = new google.maps.LatLng(startLocation.lat, startLocation.lng);
                bounds.extend(latLng);
            }

            if (destination && destination.lat && destination.lng) {
                let latLng = new google.maps.LatLng(destination.lat, destination.lng);
                bounds.extend(latLng);
            }

            (homePickups || []).forEach(homePickup => {
                let latLng = new google.maps.LatLng(homePickup.location.lat, homePickup.location.lng);
                bounds.extend(latLng);
            });

            if (!_.isNil(homeOrganization)) {
                bounds.extend(new google.maps.LatLng(homeOrganization.location.lat, homeOrganization.location.lng));
            }

            map.fitBounds(bounds, { bottom: 200, left: 50, right: 50, top: 100 }); // higher bottom value compensates for trips widget
        };

        const centerOnTrip = trip => {
            if (_.isEmpty(map)) {
                return;
            }

            let bounds = new google.maps.LatLngBounds();

            let collectorLatLng = new google.maps.LatLng(trip.collector.location.lat, trip.collector.location.lng);
            bounds.extend(collectorLatLng);

            let startLatLng = new google.maps.LatLng(trip.startLocation.lat, trip.startLocation.lng);
            bounds.extend(startLatLng);

            trip.pickups.forEach(pickup => {
                let pickupLatLng = new google.maps.LatLng(pickup.location.lat, pickup.location.lng);
                bounds.extend(pickupLatLng);
            });

            map.fitBounds(bounds, { bottom: 100, left: 100, right: 100, top: 300 }); // higher top value compensates for trips widget

            if (map.getZoom() === 20) {
                //Default zoom when no pickups are present
                map.setZoom(13);
            }
        };

        /* const recenterMap = () => {
            // Centers map on centerMap coordinates
            console.log("RECENTERING MAP")
            if (_.isEmpty(map)) {
                return;
            }

            let bounds = new google.maps.LatLngBounds();
            let latLng = new google.maps.LatLng(mapCenter.lat, mapCenter.lng);

            bounds.extend(latLng);

            map.fitBounds(bounds, { bottom: 100, left: 100, right: 100, top: 300 });
            map.setZoom(13);
        }; */

        const handlePickupClick = pickup => props => {
            if (_.isNil(onPickupClick)) {
                return;
            }

            onPickupClick(pickup, shiftPressedRef.current)(props);
        };

        const handlePinClick = pin => () => {
            if (_.isNil(onPinClick)) {
                return;
            }

            onPinClick(pin);
        };

        const updateOperatorMap = () => {
            const clusterPickupMarkers = _.get(filters, 'clusterPickupMarkers', false);
            if (!_.isNil(markerClusterer)) {
                markerClusterer.clearMarkers();
                if (
                    filterDate === _.get(prevProps, 'filterDate', filterDate) &&
                    clusterPickupMarkers !== _.get(prevProps, 'filters.clusterPickupMarkers', clusterPickupMarkers) &&
                    !clusterPickupMarkers
                ) {
                    markersPickups = {};
                }
            }
            if (!_.isNil(charityMarkerClusterer)) {
                charityMarkerClusterer.clearMarkers();
            }
            let modifiedRoutes = routes;
            if (mode === MODES['START_ROUTE']) {
                modifiedRoutes = [];
            }

            if (requiresRender({ prevData: prevProps.routes, data: modifiedRoutes, mapEntities: polylines })) {
                console.log('%croutes are being rendered.', 'color: seagreen');
                if (routeIsLoading) {
                    centerOnMarkers();
                    setRouteIsLoading(false); // initial route load is over after the first response is received
                }
                polylines.forEach(polyline => polyline.setMap(null));
                polylines = [];
                _.filter(modifiedRoutes, route => !_.isNil(route)).forEach(route => {
                    if (route.success) {
                        route.legs.forEach(leg => {
                            leg.steps.forEach(step => {
                                let polyline = new google.maps.Polyline({
                                    map,
                                    path: step.path,
                                    strokeColor: leg.color || route.color,
                                    strokeOpacity: 0.7,
                                    strokeWeight: 5,
                                    clickable: false
                                });
                                polylines.push(polyline);
                            });
                        });
                    } else {
                        // NB: this can happen now due to undefined routes when going back to the depot
                        // FIXME: set empty/return routes to have empty array of legs?
                        // console.error('ERROR: a route was not loaded properly (route.error)', route);
                    }
                });
            }

            if (prevProps.mode === MODES['SELECT'] && mode === MODES['PICKUP'] && !_.isNil(currentLocation)) {
                map.setCenter({
                    lat: parseFloat(pickupSelected.location.lat),
                    lng: parseFloat(pickupSelected.location.lng)
                });
                let bounds = new google.maps.LatLngBounds();
                if (!_.isNil(currentLocation)) {
                    bounds.extend({ lat: parseFloat(currentLocation.lat), lng: parseFloat(currentLocation.lng) });
                }
                bounds.extend({
                    lat: parseFloat(pickupSelected.location.lat),
                    lng: parseFloat(pickupSelected.location.lng)
                });
                map.fitBounds(bounds, { bottom: 100, left: 100, right: 100, top: 100 });
            } else if (prevProps.mode === MODES['SELECT'] && mode === MODES['DROPOFF'] && !_.isNil(currentLocation)) {
                map.setCenter({ lat: parseFloat(destination.lat), lng: parseFloat(destination.lng) });
                let bounds = new google.maps.LatLngBounds();
                if (!_.isNil(currentLocation)) {
                    bounds.extend({ lat: parseFloat(currentLocation.lat), lng: parseFloat(currentLocation.lng) });
                }
                bounds.extend({ lat: parseFloat(destination.lat), lng: parseFloat(destination.lng) });
                map.fitBounds(bounds, { bottom: 100, left: 100, right: 100, top: 100 });
            } else if (prevProps.mode !== MODES['SELECT'] && mode === MODES['SELECT']) {
                centerOnMarkers();
            } else if (mode === MODES['START_ROUTE'] && !_.isNil(currentLocation)) {
                map.setCenter({ lat: parseFloat(startLocation.lat), lng: parseFloat(startLocation.lng) });
                let bounds = new google.maps.LatLngBounds();
                if (!_.isNil(currentLocation)) {
                    bounds.extend({ lat: parseFloat(currentLocation.lat), lng: parseFloat(currentLocation.lng) });
                }
                bounds.extend({ lat: parseFloat(startLocation.lat), lng: parseFloat(startLocation.lng) });
                map.fitBounds(bounds, { bottom: 100, left: 100, right: 100, top: 100 });
            }

            setAllMarkersToUnused(markerCurrentLocation);
            setMarkerCurrentLocation(markerCurrentLocation, currentLocation, handlePinClick, map, google);
            removeUnusedMarkers(markerCurrentLocation);

            setAllMarkersToUnused(markersStartLocations);
            setMarkersStartLocations(markersStartLocations, startLocations, handlePinClick, map, google);
            removeUnusedMarkers(markersStartLocations);

            // One-time render:
            setMarkersDestinations(markersDestinations, destinations, handlePinClick, map, google, theme);
            setMarkerStartLocation(markerStartLocation, startLocation, handlePinClick, map, google);
            setMarkerDestination(markerDestination, destination, handlePinClick, map, google);

            const showAll =
                accountType !== 'Collector Employee' || (accountType === 'Collector Employee' && seeAllPendingPickups);
            if (mode !== MODES['START_ROUTE']) {
                setAllMarkersToUnused(markersPickups);
                setMarkersPickups(
                    markersPickups,
                    pickups,
                    map,
                    google,
                    theme,
                    handlePickupClick,
                    pickupSelected,
                    pickupIdsChecked,
                    prevProps,
                    showAll,
                    _.get(filters, 'showClusterIds', 'None'),
                    commoditiesAvailable
                );
                removeUnusedMarkers(markersPickups);
                setMarkerSelectedPickupAddress(markerPickupSelectedAddress, pickupSelected, map, google, theme);
                let markers = [];
                for (let key of Object.keys(markersPickups)) {
                    let marker = markersPickups[key].marker;
                    marker.bagCount = _pickup.numberOfPayloadBags(markersPickups[key].pickup, commoditiesAvailable);
                    markers.push(marker);
                }
                if (clusterPickupMarkers) {
                    markerClusterer = new markerclusterer.MarkerClusterer({
                        map,
                        markers,
                        algorithm: new markerclusterer.SuperClusterAlgorithm({ radius: 80 }),
                        renderer: {
                            render: ({ markers, _position: position }) => {
                                //here is where you return a Marker
                                //and style it w/custom label/icon props
                                let totalBagCount = markers.reduce((total, marker) => {
                                    return total + marker.bagCount;
                                }, 0);
                                let labelOffsetX = totalBagCount.toString().length * 4;
                                let iconUrl = Marker_Cluster_Indigo;
                                let color = colors['indigo'][500];
                                if (totalBagCount <= 250 && totalBagCount > 100) {
                                    iconUrl = Marker_Cluster_Amber;
                                    color = colors['amber'][500];
                                } else if (totalBagCount > 250) {
                                    iconUrl = Marker_Cluster_DeepOrange;
                                    color = colors['deepOrange'][500];
                                }
                                return new MarkerWithLabel({
                                    icon: {
                                        url: iconUrl,
                                        scaledSize: new google.maps.Size(64, 64)
                                    },
                                    position: {
                                        lat: position.lat(),
                                        lng: position.lng()
                                    },
                                    labelContent: totalBagCount, //String(markers.length),
                                    labelAnchor: new google.maps.Point(4 + labelOffsetX, 45),
                                    labelStyle: {
                                        color: color,
                                        fontFamily: theme.typography.subheading.fontFamily
                                    },
                                    labelClass: 'marker-label'
                                });
                            }
                        }
                    });
                }
            } else {
                // remove all pickup markers and address labels when it's start screen
                setAllMarkersToUnused(markersPickups);
                removeUnusedMarkers(markersPickups);
                setMarkerSelectedPickupAddress(markerPickupSelectedAddress, {}, map, google, theme);
                // show route to starting location
                if (!_.isNil(currentLocation)) {
                    throttledDrawDirectionRef.current(directionDisplay, google, map, currentLocation, startLocation);
                }
            }

            if (mode !== MODES['START_ROUTE']) {
                clearDirectionDisplay(directionDisplay);
            }

            setAllMarkersToUnused(markersDrivers);
            setMarkersDrivers(markersDrivers, drivers, handlePinClick, map, google, theme);
            removeUnusedMarkers(markersDrivers);
        };

        const updateCustomerMap = () => {
            if (!_.isNil(homePickups)) {
                setAllMarkersToUnused(markersPickups);
                setMarkersHomePickups(
                    markersPickups,
                    homePickups,
                    liveInfo,
                    onOpenPickupDialogWithMapMarker,
                    map,
                    google,
                    theme,
                    commoditiesAvailable,
                    lang
                );

                //only recenter map if pickup locations changed
                if (pickupLocationsChanged(prevProps.homePickups, homePickups)) {
                    centerOnMarkers();
                }

                removeUnusedMarkers(markersPickups);
            } else if (_.isNil(homePickups) && !_.isNil(prevProps.homePickups)) {
                markersPickups[prevProps.homePickups[0]._id].marker.setMap(null);
                delete markersPickups[prevProps.homePickups[0]._id];

                for (let key in markersPickups) {
                    if (key.includes('liveInfo')) {
                        markersPickups[key].marker.setMap(null);
                        delete markersPickups[key];
                    }
                }
            }

            /*setMarkerLiveInfo(
                markersPickups,
                homePickups,
                commoditiesAvailable,
                onOpenPickupDialogWithMapMarker,
                map,
                google,
                theme,
                liveInfo,
                prevProps,
                lang
            );*/
            setAllMarkersToUnused(markersPastLocations);
            if (pickupsEnabled) {
                setMarkersPastLocations(
                    markersPastLocations,
                    _.filter(pastLocations, pl => _.isNil(pl.nextPickupDate)),
                    onPastLocationClick,
                    map,
                    google,
                    theme,
                    lang
                );
            }
            removeUnusedMarkers(markersPastLocations);

            if (!_.isNil(homeOrganization)) {
                setAllMarkersToUnused(markersPickups);
                if (
                    _.isNil(markersPickups[homeOrganization._id]) ||
                    !_.isEqual(homeOrganization, markersPickups[homeOrganization._id].pickup)
                ) {
                    setMarkerHomeOrganization(markersPickups, homeOrganization, onPickupDialog, map, google, theme);
                    centerOnMarkers();
                } else {
                    markersPickups[homeOrganization._id].used = true;
                }
                removeUnusedMarkers(markersPickups);
            }

            if (charityEnabled) {
                setMarkersCharities(
                    markersCharities,
                    charities,
                    onCharityClick,
                    map,
                    google,
                    charityMarkerOverride,
                    theme
                );
            }

            // setMarkersDropLocations(
            //     markersDropLocations,
            //     dropLocations,
            //     onDropLocationClick,
            //     map,
            //     google,
            //     dropLocationMarkerOverride,
            //     theme
            // );

            setMarkersReturnSites(
                markersDropLocations,
                dropLocations,
                collectors,
                onReturnSiteClick,
                map,
                google,
                dropLocationMarkerOverride,
                theme
            );

            let regularMarkers = [];
            let charityMarkers = [];

            for (let key of Object.keys(markersDropLocations)) {
                let marker = markersDropLocations[key].marker;
                regularMarkers.push(marker);
            }

            for (let key of Object.keys(markersCharities)) {
                let marker = markersCharities[key].marker;
                charityMarkers.push(marker);
            }

            const createMarkerClusterer = (markers, color) => {
                return new markerclusterer.MarkerClusterer({
                    map,
                    markers,
                    algorithm: new markerclusterer.SuperClusterAlgorithm({ radius: 100 }),
                    renderer: {
                        render: ({ markers, _position: position }) => {
                            let totalLocations = markers.length;
                            let labelOffsetX = totalLocations.toString().length * 7;

                            let canvas = document.createElement('canvas');
                            let size = 64;
                            let scaledSize = 32;
                            canvas.width = size;
                            canvas.height = size;
                            let context = canvas.getContext('2d');

                            context.clearRect(0, 0, canvas.width, canvas.height);

                            // Draw the shadow
                            context.beginPath();
                            context.arc(
                                canvas.width / 2 + 3,
                                canvas.height / 2 + 3,
                                size / 2 - 4,
                                0,
                                2 * Math.PI,
                                false
                            );
                            context.fillStyle = 'rgba(0, 0, 0, 0.2)';
                            context.fill();
                            context.beginPath();
                            context.arc(size / 2, size / 2, size / 2 - 2, 0, 2 * Math.PI, false);
                            context.fillStyle = color;
                            context.fill();
                            context.shadowColor = 'transparent';
                            context.lineWidth = 1.5;
                            context.strokeStyle = '#ffffff';
                            context.stroke();

                            return new MarkerWithLabel({
                                icon: {
                                    url: canvas.toDataURL(),
                                    scaledSize: new google.maps.Size(50, 50)
                                },
                                position: {
                                    lat: position.lat(),
                                    lng: position.lng()
                                },
                                labelContent: totalLocations, //String(markers.length),
                                labelAnchor: new google.maps.Point(labelOffsetX, 40),
                                labelStyle: {
                                    color: 'white',
                                    fontFamily: theme.typography.subheading.fontFamily,
                                    fontSize: '24px',
                                    fontWeight: 900
                                },
                                labelClass: 'marker-label'
                            });
                        }
                    }
                });
            };

            markerClusterer = createMarkerClusterer(
                regularMarkers,
                isEXPRegion() ? theme.palette.secondary[500] : theme.palette.primary[500]
            );

            charityMarkerClusterer = createMarkerClusterer(charityMarkers, theme.palette.charityPin);

            setMarkersChildAccounts(
                markersChildAccounts,
                childAccounts,
                onChildAccountClick,
                map,
                google,
                theme,
                lang,
                pickupsEnabled
            );
            setMarkerCurrentLocation(markerCurrentLocation, currentLocation, () => {}, map, google, 'customer');
        };

        useEffect(() => {
            const asyncFunc = async () => {
                document.addEventListener('keydown', handleKeyDown);
                document.addEventListener('keyup', handleKeyUp);

                let center;
                let lat;
                let lng;

                if (currentLocation) {
                    lat = _.get(currentLocation, 'lat');
                    lng = _.get(currentLocation, 'lng');
                } else if (mapCenter) {
                    lat = _.get(mapCenter, 'lat');
                    lng = _.get(mapCenter, 'lng');
                } else {
                    lat = parseFloat(getQueryStringValue('lat'));
                    lng = parseFloat(getQueryStringValue('lng'));
                }

                if (!isValidGps(lat, lng)) {
                    center = {
                        lat: _.get(defaultCoordinates, 'latitude'),
                        lng: _.get(defaultCoordinates, 'longitude')
                    };
                } else {
                    center = { lat, lng };
                }

                const map = new google.maps.Map(mapElemRef.current, {
                    styles: theme.palette.type === 'light' ? mapThemeLight : mapThemeDark,
                    center,
                    visualRefresh: true,
                    mapTypeControl: false,
                    streetViewControl: false,
                    mapTypeId: 'roadmap',
                    minZoom: 2,
                    maxZoom: closeViewAllowed ? 20 : 17,
                    disableDefaultUI: true,
                    clickableIcons: false // stops POI info windows
                });

                /*  mapEventListeners.forEach(mapEventListener => {
                    map.addListener(_.get(mapEventListener, 'name'), e => {
                        const eventHandler = _.get(mapEventListener, 'handler', () => {});
                        eventHandler(e, map);
                    });
                }); */

                const zoomFromURL = parseInt(getQueryStringValue('zoom'));
                const zoom = isNaN(zoomFromURL) ? 12 : zoomFromURL;
                map.setZoom(zoom);
                map.addListener(
                    'zoom_changed',
                    _.throttle(() => {
                        const zoom = map.getZoom();
                        setQueryStringValue('zoom', zoom);
                    }, 100)
                );

                if (showDirections && !_.isNil(onMapClick)) {
                    map.addListener('click', onMapClick);
                }

                setMap(map);
                // Zones (admins only):
                await wait(500); // HACK TODO:
                if (!_.isNil(zones) && !_.isEmpty(zones)) {
                    setZonePolygons(
                        zonePolygons,
                        zones,
                        zoneDataSelected,
                        onZoneClick,
                        onZoneBoundsChanged,
                        map,
                        google,
                        filters,
                        filterDate
                    );

                    setZoneLabels(zoneLabels, zones, map, google, filters, filterDate, collectors);
                }

                // Only for customer site map
                if (!showDirections && !_.isNil(homePickups)) {
                    centerMapOnPickups(homePickups, map, google);
                }
            };

            asyncFunc();

            return () => {
                document.removeEventListener('keydown', handleKeyDown);
                document.removeEventListener('keyup', handleKeyUp);

                // Reset all map elements:
                polylines = [];
                markerCurrentLocation = [];
                markerDestination = {};
                markersDestinations = [];
                markersPickups = {};
                markersDrivers = {};
                markersCharities = {};
                markersDropLocations = {};
            };
        }, []);

        useEffect(() => {
            if (!_.isNil(map)) {
                console.log('%cMap theme has changed.', 'color: seagreen');
                map.setOptions({ styles: theme.palette.type === 'light' ? mapThemeLight : mapThemeDark });
                setAllMarkersToUnused(markersPickups);
                removeUnusedMarkers(markersPickups);
            }
        }, [theme.palette.type]);

        useEffect(() => {
            if (tripToCenter && preventCenterOnTrip) {
                // Prevents default centering on trip
                setPreventCenterOnTrip(false);
                return;
            }
            if (!_.isNil(tripToCenter)) {
                console.log('%ctripToCenter has changed.', 'color: seagreen');
                centerOnTrip(tripToCenter);
            }
        }, [tripToCenter]);

        useEffect(() => {
            if (filters) {
                zonePolygons.forEach(polygon => {
                    const show = filters.onlyTodayZones
                        ? filters.showZones && _zone.isDateServiced(polygon.zone, filterDate)
                        : filters.showZones;

                    polygon.setVisible(show);
                });

                zoneLabels.forEach(label => {
                    const show = filters.onlyTodayZones
                        ? filters.showNextServiceDate &&
                          (filters.onlyTodayZones && _zone.isDateServiced(label.zone, filterDate))
                        : filters.showNextServiceDate;

                    label.setVisible(show);
                });
            }
        }, [filterDate, filters]);

        useEffect(() => {
            if (!_.isNil(zones) && !_.isEmpty(zones) && !_.isNil(map)) {
                setZonePolygons(
                    zonePolygons,
                    zones,
                    zoneDataSelected,
                    onZoneClick,
                    onZoneBoundsChanged,
                    map,
                    google,
                    filters,
                    filterDate
                );
                setZoneLabels(zoneLabels, zones, map, google, filters, filterDate);
            }
        }, [zoneDataSelected]);

        useEffect(() => {
            //fixes issue where onMapClick closure pointing to old state values
            if (showDirections && !_.isNil(onMapClick) && !_.isNil(map)) {
                google.maps.event.clearListeners(map, 'click');
                map.addListener('click', onMapClick);
            }
        }, [onMapClick]);

        useEffect(() => {
            //Rectangle selection, need to re-add these each time pickupRectangleSelect is changed so
            //they call the current value of the function which references the current value of pickupIdsChecked and
            //not an old value of the function which references an old value of pickupIdsChecked

            let mouseDownListener, mouseMoveListener, mouseUpListener;
            if (!_.isNil(pickupRectangleSelect) && !_.isNil(map)) {
                let rectangle = null;
                let originalClickPos = null;

                mouseDownListener = google.maps.event.addListener(map, 'mousedown', e => {
                    if (shiftPressedRef.current) {
                        map.setOptions({ draggable: false });
                        originalClickPos = e.latLng;

                        rectangle = new google.maps.Rectangle({
                            map,
                            bound: new google.maps.LatLngBounds(originalClickPos, originalClickPos),
                            fillColor: '#90a4ae',
                            fillOpacity: 0.05,
                            strokeColor: '#90a4ae',
                            strokeWeight: 0.5,
                            clickable: false
                        });
                    }
                });

                mouseMoveListener = google.maps.event.addListener(map, 'mousemove', e => {
                    if (rectangle) {
                        let newBounds = new google.maps.LatLngBounds(originalClickPos, originalClickPos);
                        newBounds.extend(e.latLng);
                        rectangle.setBounds(newBounds);
                    }
                });

                mouseUpListener = google.maps.event.addListener(map, 'mouseup', e => {
                    if (rectangle) {
                        let bounds = rectangle.getBounds();
                        const pickup_ids = [];
                        for (let pickup_id in markersPickups) {
                            const markerPos = markersPickups[pickup_id].marker.getPosition();
                            if (bounds && bounds.contains(markerPos)) {
                                pickup_ids.push(pickup_id);
                            }
                        }
                        pickupRectangleSelect(pickup_ids);
                        map.setOptions({ draggable: true });
                        rectangle.setMap(null);
                        rectangle = null;
                        bounds = null;
                    }
                });
            }

            return () => {
                google.maps.event.removeListener(mouseDownListener);
                google.maps.event.removeListener(mouseMoveListener);
                google.maps.event.removeListener(mouseUpListener);
            };
        }, [pickupRectangleSelect]);

        useEffect(() => {
            //NOTE: map will be null on the first call to this useEffect since state is not set yet
            if (!_.isNil(map)) {
                if (accountType === 'Collector Administrator' && firstRenderRef.current) {
                    firstRenderRef.current = false;
                    centerOnMarkers();
                }
                if (showDirections) {
                    updateOperatorMap();
                } else {
                    updateCustomerMap();
                }

                if (prevProps.centerOnMarkersTrigger !== centerOnMarkersTrigger) {
                    centerOnMarkers();
                }
            }
        });

        console.log('LargeMap.js render'); // DEBUG

        return (
            <React.Fragment>
                <div
                    ref={elem => (mapElemRef.current = elem)}
                    id="google-large-map"
                    data-cy="google-large-map"
                    className={
                        (offsetLegalInfo ? 'offset-legal-info' : '') +
                        ' ' +
                        (hideLegalInfo ? 'hide-legal-info' : '') +
                        ' ' +
                        (addRightOffsetLegalInfo ? 'add-right-offset-legal-info' : '')
                    }
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        ...style
                    }}
                />
                {showDirections && routeIsLoading && (
                    <LinearProgress style={{ position: 'absolute', left: 0, bottom: 0, zIndex: 1, width: '100%' }} />
                )}
            </React.Fragment>
        );
    },
    (prevProps, nextProps) => {
        return _.isEqual(prevProps, nextProps); //deep comparision on props
    }
);

export default withTheme()(LargeMap);

function setAllMarkersToUnused(markers) {
    for (let pickup_id in markers) {
        markers[pickup_id].used = false;
    }
}

function removeUnusedMarkers(markers) {
    for (let pickup_id in markers) {
        if (!markers[pickup_id].used) {
            markers[pickup_id].marker.setMap(null);
            if (!_.isNil(markers[pickup_id].markerSecondary)) {
                markers[pickup_id].markerSecondary.setMap(null);
            }
            delete markers[pickup_id];
        }
    }
}

/*
async function onPolygonChange(zonePolygon, zone) {
    console.log(`///////////// ${zone.name} /////////////`);
    console.log('Zone ID:', zone._id);
    try {
        let bounds = zonePolygon
            .getPath()
            .getArray()
            .map(coords => ({ lat: coords.lat(), lng: coords.lng() }));

        console.log(JSON.stringify(bounds.concat(_.first(bounds))));
    } catch (err) {
        console.log('Google Maps API Error. Please replace googleApiKey ', { err, data: zonePolygon.getPath() });
    }
}*/

function pickupLocationsChanged(previousPickups, currentPickups) {
    previousPickups = previousPickups || [];
    currentPickups = currentPickups || [];

    if (previousPickups.length !== currentPickups.length) {
        return true;
    }

    let pickupLocationDifferent = false;
    for (let currentPickup of currentPickups) {
        let previousPickup = _.find(previousPickups, previousPickup => previousPickup._id === currentPickup._id);

        if (previousPickup === null) {
            pickupLocationDifferent = true;
            break;
        } else if (
            _.get(currentPickup, 'location.lat', null) !== _.get(previousPickup, 'location.lat', null) ||
            _.get(currentPickup, 'location.lng', null) !== _.get(previousPickup, 'location.lng', null)
        ) {
            pickupLocationDifferent = true;
            break;
        }
    }

    return pickupLocationDifferent;
}
