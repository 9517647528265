import VERSION_FRONT from 'VERSION';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { _user } from 'std';

import { isProductionEnv } from 'utils/misc';

import _ from 'lodash';

import * as colors from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { Collapse, IconButton, List, Popover } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';

import { withTheme } from '@material-ui/core/styles';
import { isSTDRegion } from 'utils/misc';
import { ROLES } from 'constants.js';

const styles = {
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: colors.red[500],
        color: 'white'
    },
    subMenuIcon: { marginLeft: 16, marginRight: 0 },
    badge: { marginRight: 32 }
};

const phoneNumberGroupings = process.env.REACT_APP_PHONE_NUMBER_GROUPING.split(' ');

function OperatorDrawerContents({
    theme,
    homePath,
    location,
    pages,
    operator,
    collector,
    collapseOpen,
    drawerWidth,
    index,
    onHandleDrawer,
    onExitView,
    onNightModeToggle,
    onCollapse,
    onLogOut,
    history,
    resetTimeoutCount,
    rolePermissions,
    handleDepotLogout,
    onAccountsMenu,
    onAccountsChange,
    accountSelectedIndex,
    accountsAnchorEl,
    pendingCount,
    overdueComplaintCount,
    unresolvedInfractionCount,
    pendingBottleDriveCount,
    menuOptions,
    pickupsEnabled,
    promosEnabled,
    tippingEnabled
}) {
    const handleClick = () => {
        resetTimeoutCount();
        if (index === 0) {
            onHandleDrawer(false)();
        }
    };
    const handleNightModeClick = e => {
        onNightModeToggle(e);
        if (index === 1) {
            onHandleDrawer(true)();
        }
    };

    const collapsablePickups = !pickupsEnabled ? (
        <div />
    ) : (
        <Collapse key={'collapsablePickups'} in={collapseOpen === 'pickups'} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {(!rolePermissions || _.get(rolePermissions, 'pickups.calendar', false)) && (
                    <MenuItem
                        data-cy="operatorsite-pickups-calendar-button"
                        component={Link}
                        button
                        dense
                        to={homePath + '/pickups-calendar'}
                        selected={location.pathname === homePath + '/pickups-calendar'}
                        onClick={handleClick}
                    >
                        <ListItemIcon style={styles.subMenuIcon}>
                            <Icon>date_range</Icon>
                        </ListItemIcon>
                        <ListItemText inset primary={pages[homePath + '/pickups-calendar']} />
                    </MenuItem>
                )}
                {(!rolePermissions || _.get(rolePermissions, 'pickups.commodity', false)) && (
                    <MenuItem
                        data-cy="operatorsite-commodity-pickups"
                        component={Link}
                        button
                        dense
                        to={homePath + '/commodity-pickups'}
                        selected={location.pathname === homePath + '/commodity-pickups'}
                        onClick={handleClick}
                    >
                        <ListItemIcon style={styles.subMenuIcon}>
                            <Icon>category</Icon>
                        </ListItemIcon>
                        <ListItemText inset primary={pages[homePath + '/commodity-pickups']} />
                    </MenuItem>
                )}
                {(!rolePermissions || _.get(rolePermissions, 'pickups.completed', false)) && (
                    <MenuItem
                        component={Link}
                        button
                        dense
                        to={homePath + '/collector-completed'}
                        selected={location.pathname === homePath + '/collector-completed'}
                        onClick={handleClick}
                    >
                        <ListItemIcon style={styles.subMenuIcon}>
                            <Icon>task_alt</Icon>
                        </ListItemIcon>
                        <ListItemText inset primary={pages[homePath + '/collector-completed']} />
                    </MenuItem>
                )}
                {(!rolePermissions || _.get(rolePermissions, 'pickups.today', false)) &&
                    (!_user.isCollectorAdmin(operator) || _.get(collector, 'configuration.enablePickups', false)) && (
                        <MenuItem
                            data-cy="operatorsite-pickups"
                            component={Link}
                            button
                            dense
                            to={homePath + '/pickups-manager'}
                            selected={location.pathname === homePath + '/pickups-manager'}
                            onClick={handleClick}
                        >
                            <ListItemIcon style={styles.subMenuIcon}>
                                <Icon>today</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary={pages[homePath + '/pickups-manager']} />
                        </MenuItem>
                    )}

                {(!rolePermissions || _.get(rolePermissions, 'pickups.tripStatistics', false)) &&
                    (!_user.isCollectorAdmin(operator) || _.get(collector, 'configuration.enablePickups', false)) && (
                        <MenuItem
                            data-cy="operator-site-collector-history"
                            component={Link}
                            button
                            dense
                            to={homePath + '/collector-history'}
                            selected={location.pathname === homePath + '/collector-history'}
                            onClick={handleClick}
                        >
                            <ListItemIcon style={styles.subMenuIcon}>
                                <Icon>query_stats</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary={pages[homePath + '/collector-history']} />
                        </MenuItem>
                    )}
            </List>
        </Collapse>
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
            <MenuList style={{ width: drawerWidth, paddingTop: 'max(8px, env(safe-area-inset-top, 8px))' }}>
                <ListItem style={{ marginBottom: theme.spacing.unit }}>
                    <ListItemIcon>
                        <Avatar style={styles.avatar}>{operator.name.first[0]}</Avatar>
                    </ListItemIcon>
                    <ListItemText
                        inset
                        primary={operator.name.first + ' ' + operator.name.last}
                        secondary={operator.phone.replace(
                            new RegExp(
                                `(\\d{${phoneNumberGroupings[0]}})(\\d{${phoneNumberGroupings[1]}})(\\d{${phoneNumberGroupings[2]
                                }})`
                            ),
                            process.env.REACT_APP_PHONE_NUMBER_FORMAT
                        )}
                    />
                    <ListItemSecondaryAction>
                        <MenuItem
                            component={Link}
                            button
                            to={homePath + '/profile'}
                            selected={location.pathname === homePath + '/profile'}
                            onClick={handleClick}
                            data-cy="operatorsite-profile"
                        >
                            <ListItemIcon style={{ marginRight: 0 }}>
                                <Icon>manage_accounts</Icon>
                            </ListItemIcon>
                        </MenuItem>
                    </ListItemSecondaryAction>
                </ListItem>
                {_user.isCollectorAdmin(operator) && _.get(operator, 'collectors', []).length > 1 && (
                    <MenuItem
                        data-cy="operator-site-switch-accounts"
                        button
                        style={{ marginTop: theme.spacing.unit * 2 }}
                        onClick={onAccountsMenu(true)}
                    >
                        <ListItemIcon>
                            <Icon>switch_account</Icon>
                        </ListItemIcon>
                        <ListItemText
                            inset
                            primary={
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        display: 'block',
                                        paddingRight: 0,
                                        fontSize: 'smaller'
                                    }}
                                >
                                    {operator.collectors[accountSelectedIndex].name}
                                </span>
                            }
                            secondary={
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        display: 'block',
                                        paddingRight: 0,
                                        fontSize: 'smaller'
                                    }}
                                >
                                    {operator.collectors[accountSelectedIndex].code}
                                </span>
                            }
                        />
                        <IconButton data-cy="operator-site-expand-button" disableRipple>
                            <Icon style={{ color: 'inherit' }}>expand_more</Icon>
                        </IconButton>
                    </MenuItem>
                )}
                {_user.isCollectorEmployee(operator) && (
                    <MenuItem
                        data-cy="operator-site-dashboard"
                        button
                        component={Link}
                        to={homePath + '/driver'}
                        selected={location.pathname === homePath + '/driver'}
                        style={{ marginTop: theme.spacing.unit * 2 }}
                        onClick={handleClick}
                    >
                        <ListItemIcon>
                            <Icon>dashboard</Icon>
                        </ListItemIcon>
                        <ListItemText inset primary={pages[homePath + '/driver']} />
                    </MenuItem>
                )}

                {_user.isDriver(operator) && (
                    <MenuItem
                        data-cy="operator-site-history"
                        button
                        component={Link}
                        to={homePath + '/driver-completed'}
                        selected={location.pathname === homePath + '/driver-completed'}
                        onClick={handleClick}
                    >
                        <ListItemIcon>
                            <Icon>history</Icon>
                        </ListItemIcon>
                        <ListItemText inset primary={pages[homePath + '/driver-completed']} />
                    </MenuItem>
                )}
                {pickupsEnabled && _user.isDriver(operator) && (
                    <MenuItem
                        data-cy="operatorsite-import-pickups"
                        component={Link}
                        button
                        to={homePath + '/import-pickups'}
                        selected={location.pathname === homePath + '/import-pickups'}
                        onClick={handleClick}
                    >
                        <ListItemIcon>
                            <Icon>upload_file</Icon>
                        </ListItemIcon>
                        <ListItemText inset primary={pages[homePath + '/import-pickups']} />
                    </MenuItem>
                )}

                {_user.isClerk(operator) && (
                    <MenuItem
                        data-cy="operator-site-bulk-counter"
                        button
                        component={Link}
                        to={homePath + '/bulks'}
                        selected={location.pathname === homePath + '/bulks'}
                        onClick={handleClick}
                    >
                        <ListItemIcon>
                            <Icon>ballot</Icon>
                        </ListItemIcon>
                        {overdueComplaintCount > 0 ? (
                            <Badge badgeContent={overdueComplaintCount} color="primary">
                                <ListItemText
                                    inset
                                    primary={pages[homePath + '/bulks']}
                                    style={{ paddingLeft: '16px' }}
                                />
                            </Badge>
                        ) : (
                            <ListItemText inset primary={pages[homePath + '/bulks']} />
                        )}
                    </MenuItem>
                )}

                {_user.isCollectorAdmin(operator) && (
                    <div>
                        <MenuItem
                            data-cy="operator-site-dashboard"
                            button
                            component={Link}
                            to={homePath + '/collector'}
                            selected={location.pathname === homePath + '/collector'}
                            style={{ marginTop: theme.spacing.unit * 2 }}
                            onClick={handleClick}
                        >
                            <ListItemIcon>
                                <Icon>dashboard</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary={pages[homePath + '/collector']} />
                        </MenuItem>
                        {pickupsEnabled && (
                            <MenuItem
                                data-cy={`${index}-operatorsite-pickups-menu-button`}
                                button
                                onClick={() => onCollapse('pickups')}
                            >
                                <ListItemIcon>
                                    <Icon>local_shipping</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary="Pickups" />
                            </MenuItem>
                        )}
                        {collapsablePickups}
                        {pickupsEnabled && (
                            <MenuItem
                                data-cy="operatorsite-import-pickups"
                                component={Link}
                                button
                                to={homePath + '/import-pickups'}
                                selected={location.pathname === homePath + '/import-pickups'}
                                onClick={handleClick}
                            >
                                <ListItemIcon>
                                    <Icon>upload_file</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary={pages[homePath + '/import-pickups']} />
                            </MenuItem>
                        )}
                        {_.get(collector, 'configuration.enableCounting', false) && (
                            <MenuItem
                                data-cy="operator-site-bulk-counter"
                                button
                                component={Link}
                                to={homePath + '/bulks'}
                                selected={location.pathname === homePath + '/bulks'}
                                style={{ marginTop: theme.spacing.unit * 2 }}
                                onClick={handleClick}
                            >
                                <ListItemIcon>
                                    <Icon>ballot</Icon>
                                </ListItemIcon>
                                {overdueComplaintCount > 0 ? (
                                    <Badge badgeContent={overdueComplaintCount} color="primary">
                                        <ListItemText
                                            inset
                                            primary={pages[homePath + '/bulks']}
                                            style={{ paddingLeft: '16px' }}
                                        />
                                    </Badge>
                                ) : (
                                    <ListItemText inset primary={pages[homePath + '/bulks']} />
                                )}
                            </MenuItem>
                        )}
                        {_.get(collector, 'configuration.enableCounting', false) && (
                            <MenuItem
                                data-cy="operator-site-clerk-report"
                                button
                                component={Link}
                                to={homePath + '/bulks-stats'}
                                selected={location.pathname === homePath + '/bulks-stats'}
                                onClick={handleClick}
                            >
                                <ListItemIcon>
                                    <Icon>analytics</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary={pages[homePath + '/bulks-stats']} />
                            </MenuItem>
                        )}
                        <MenuItem
                            data-cy="operator-site-employees"
                            button
                            component={Link}
                            to={homePath + '/employees'}
                            selected={location.pathname === homePath + '/employees'}
                            onClick={handleClick}
                        >
                            <ListItemIcon>
                                <Icon>table_chart</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary={pages[homePath + '/employees']} />
                        </MenuItem>

                        <MenuItem
                            data-cy="operator-site-options"
                            button
                            component={Link}
                            to={homePath + '/options'}
                            selected={location.pathname === homePath + '/options'}
                            style={{ marginTop: theme.spacing.unit * 2 }}
                            onClick={handleClick}
                        >
                            <ListItemIcon>
                                <Icon>settings</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary={pages[homePath + '/options']} />
                        </MenuItem>
                    </div>
                )}

                {(_user.isSystemAdmin(operator) || ROLES.includes(operator.accountType)) && [
                    <MenuItem
                        data-cy="operator-site-dashboard"
                        key={'operator-site-dashboard'}
                        button
                        component={Link}
                        to={homePath}
                        selected={location.pathname === homePath}
                        onClick={handleClick}
                    >
                        <ListItemIcon>
                            <Icon>home</Icon>
                        </ListItemIcon>
                        <ListItemText inset primary={pages[homePath]} />
                    </MenuItem>,
                    (!rolePermissions ||
                        (rolePermissions.pickups &&
                            (rolePermissions.pickups.today ||
                                rolePermissions.pickups.completed ||
                                rolePermissions.pickups.calendar ||
                                rolePermissions.pickups.tripStatistics ||
                                rolePermissions.pickups.commodity))) &&
                    pickupsEnabled && (
                        <MenuItem
                            data-cy={`${index}-operatorsite-pickups-menu-button`}
                            key={`${index}-operatorsite-pickups-menu-button`}
                            button
                            onClick={() => onCollapse('pickups')}
                        >
                            <ListItemIcon>
                                <Icon>local_shipping</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary="Pickups" />
                        </MenuItem>
                    ),

                    collapsablePickups,
                    (!rolePermissions ||
                        (rolePermissions.orders &&
                            (rolePermissions.orders.counter ||
                                rolePermissions.orders.history ||
                                rolePermissions.orders.clerkReport))) && (
                        <MenuItem
                            data-cy={`${index}-operatorsite-bulks-menu-button`}
                            key={`${index}-operatorsite-bulks-menu-button`}
                            button
                            onClick={() => onCollapse('bulks')}
                        >
                            <ListItemIcon>
                                {overdueComplaintCount > 0 ? (
                                    <Badge badgeContent={overdueComplaintCount} color="primary">
                                        <Icon>ballot</Icon>
                                    </Badge>
                                ) : (
                                    <Icon>ballot</Icon>
                                )}
                            </ListItemIcon>
                            <ListItemText inset primary="Orders" />
                        </MenuItem>
                    ),
                    <Collapse key={'operator-site-bulk'} in={collapseOpen === 'bulks'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {(!rolePermissions || _.get(rolePermissions, 'orders.clerkReport', false)) && (
                                <MenuItem
                                    component={Link}
                                    button
                                    dense
                                    to={homePath + '/bulks-stats'}
                                    selected={location.pathname === homePath + '/bulks-stats'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>summarize</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/bulks-stats']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'orders.counter', false)) && (
                                <MenuItem
                                    data-cy="operator-site-bulk-counter"
                                    component={Link}
                                    button
                                    dense
                                    to={homePath + '/bulks'}
                                    selected={location.pathname === homePath + '/bulks'}
                                    onClick={handleClick}
                                >
                                    {overdueComplaintCount > 0 ? (
                                        <>
                                            <ListItemIcon style={styles.subMenuIcon}>
                                                <Icon>calculate</Icon>
                                            </ListItemIcon>
                                            <ListItemText inset primary={pages[homePath + '/bulks']} />
                                            <Badge
                                                style={styles.badge}
                                                badgeContent={overdueComplaintCount}
                                                color="primary"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <ListItemIcon style={styles.subMenuIcon}>
                                                <Icon>calculate</Icon>
                                            </ListItemIcon>
                                            <ListItemText inset primary={pages[homePath + '/bulks']} />
                                        </>
                                    )}
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'orders.history', false)) && (
                                <MenuItem
                                    component={Link}
                                    button
                                    dense
                                    to={homePath + '/bulks-history'}
                                    selected={location.pathname === homePath + '/bulks-history'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>history</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/bulks-history']} />
                                </MenuItem>
                            )}
                        </List>
                    </Collapse>,
                    (!rolePermissions ||
                        (rolePermissions.accounting &&
                            (rolePermissions.accounting.redemptions ||
                                rolePermissions.accounting.invoicing ||
                                rolePermissions.accounting.quickbooks ||
                                rolePermissions.accounting.reconciliation ||
                                rolePermissions.accounting.agingBalancesReport))) && (
                        <MenuItem
                            data-cy={`${index}-operatorsite-accounting-menu-button`}
                            key={`${index}-operatorsite-accounting-menu-button`}
                            button
                            onClick={() => onCollapse('accounting')}
                        >
                            <ListItemIcon>
                                <Icon>monetization_on</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary="Accounting" />
                        </MenuItem>
                    ),
                    <Collapse
                        key={'operator-site-redemptions'}
                        in={collapseOpen === 'accounting'}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            {_.get(menuOptions, 'showAgingBalances') &&
                                (!rolePermissions ||
                                    _.get(rolePermissions, 'accounting.agingBalancesReport', false)) && (
                                    <MenuItem
                                        component={Link}
                                        button
                                        dense
                                        to={homePath + '/aging-balances-report'}
                                        selected={location.pathname === homePath + '/aging-balances-report'}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon style={styles.subMenuIcon}>
                                            <Icon>summarize</Icon>
                                        </ListItemIcon>
                                        <ListItemText inset primary={pages[homePath + '/aging-balances-report']} />
                                    </MenuItem>
                                )}
                            {_.get(menuOptions, 'showAccounting') &&
                                (!rolePermissions || _.get(rolePermissions, 'accounting.invoicing', false)) && (
                                    <MenuItem
                                        data-cy="operator-site-invoicing"
                                        component={Link}
                                        button
                                        dense
                                        to={homePath + '/accounting'}
                                        selected={location.pathname === homePath + '/accounting'}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon style={styles.subMenuIcon}>
                                            <Icon>checklist</Icon>
                                        </ListItemIcon>
                                        <ListItemText inset primary={pages[homePath + '/accounting']} />
                                    </MenuItem>
                                )}
                            {_.get(menuOptions, 'showReconciliation') &&
                                (!rolePermissions || _.get(rolePermissions, 'accounting.reconciliation', false)) && (
                                    <MenuItem
                                        component={Link}
                                        button
                                        dense
                                        to={homePath + '/reconciliation'}
                                        selected={location.pathname === homePath + '/reconciliation'}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon style={styles.subMenuIcon}>
                                            <Icon>request_quote</Icon>
                                        </ListItemIcon>
                                        <ListItemText inset primary={pages[homePath + '/reconciliation']} />
                                    </MenuItem>
                                )}
                            {(!rolePermissions || _.get(rolePermissions, 'accounting.redemptions', false)) && (
                                <MenuItem
                                    data-cy="operator-site-redemptions"
                                    component={Link}
                                    button
                                    dense
                                    to={homePath + '/redemptions'}
                                    selected={location.pathname === homePath + '/redemptions'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>redeem</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/redemptions']} />
                                </MenuItem>
                            )}
                            {_.get(menuOptions, 'showQuickbooks') &&
                                (!rolePermissions || _.get(rolePermissions, 'accounting.quickbooks', false)) && (
                                    <MenuItem
                                        component={Link}
                                        button
                                        dense
                                        to={homePath + '/quickbooks'}
                                        selected={location.pathname === homePath + '/quickbooks'}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon style={styles.subMenuIcon}>
                                            <Icon>auto_stories</Icon>
                                        </ListItemIcon>
                                        <ListItemText inset primary={pages[homePath + '/quickbooks']} />
                                    </MenuItem>
                                )}
                        </List>
                    </Collapse>,
                    (!rolePermissions ||
                        (rolePermissions.users &&
                            (rolePermissions.users.customers ||
                                rolePermissions.users.organizations ||
                                rolePermissions.users.collectors ||
                                rolePermissions.users.employees))) && (
                        <MenuItem
                            data-cy={`${index}-operatorsite-users-menu-button`}
                            key={`${index}-operatorsite-users-menu-button`}
                            button
                            onClick={() => onCollapse('users')}
                        >
                            <ListItemIcon>
                                {pendingCount > 0 ? (
                                    <Badge badgeContent={pendingCount} color="primary">
                                        <Icon>people</Icon>
                                    </Badge>
                                ) : (
                                    <Icon>people</Icon>
                                )}
                            </ListItemIcon>
                            <ListItemText inset primary="Users" />
                        </MenuItem>
                    ),
                    <Collapse key={'operator-site-users'} in={collapseOpen === 'users'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {(!rolePermissions || _.get(rolePermissions, 'users.admins', false)) && (
                                <MenuItem
                                    data-cy="operator-site-admins"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/admins'}
                                    selected={location.pathname === homePath + '/admins'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>shield_person</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/admins']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'users.collectors', false)) && (
                                <MenuItem
                                    data-cy="operator-site-collectors"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/collectors'}
                                    selected={location.pathname === homePath + '/collectors'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>engineering</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/collectors']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'users.customers', false)) && (
                                <MenuItem
                                    data-cy="operator-site-users"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/users'}
                                    selected={location.pathname === homePath + '/users'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>groups</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/users']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'users.employees', false)) && (
                                <MenuItem
                                    data-cy="operator-site-employees"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/employees'}
                                    selected={location.pathname === homePath + '/employees'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>badge</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/employees']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'users.organizations', false)) && (
                                <MenuItem
                                    data-cy="operator-site-charities"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/charities'}
                                    selected={location.pathname === homePath + '/charities'}
                                    onClick={handleClick}
                                >
                                    {pendingCount > 0 ? (
                                        <>
                                            <ListItemIcon style={styles.subMenuIcon}>
                                                <Icon>apartment</Icon>
                                            </ListItemIcon>
                                            <ListItemText inset primary={pages[homePath + '/charities']} />
                                            <Badge style={styles.badge} badgeContent={pendingCount} color="primary" />
                                        </>
                                    ) : (
                                        <>
                                            <ListItemIcon style={styles.subMenuIcon}>
                                                <Icon>apartment</Icon>
                                            </ListItemIcon>
                                            <ListItemText inset primary={pages[homePath + '/charities']} />
                                        </>
                                    )}
                                </MenuItem>
                            )}
                            {_.get(menuOptions, 'showDefaultCollectorOptions') &&
                                (!rolePermissions || _.get(rolePermissions, 'users.customers', false)) && (
                                    <MenuItem
                                        button
                                        dense
                                        component={Link}
                                        to={homePath + '/options'}
                                        selected={location.pathname === homePath + '/options'}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon style={styles.subMenuIcon}>
                                            <Icon>tune</Icon>
                                        </ListItemIcon>
                                        <ListItemText inset primary={pages[homePath + '/options']} />
                                    </MenuItem>
                                )}
                        </List>
                    </Collapse>,
                    !rolePermissions || _.get(rolePermissions, 'audit.reviewInfractions', false) ? (
                        <MenuItem
                            data-cy={`${index}-operatorsite-audit-menu-button`}
                            key={`${index}-operatorsite-audit-menu-button`}
                            button
                            onClick={() => onCollapse('audit')}
                        >
                            <ListItemIcon>
                                {unresolvedInfractionCount > 0 ? (
                                    <Badge badgeContent={unresolvedInfractionCount} color="primary">
                                        <Icon>policy</Icon>
                                    </Badge>
                                ) : (
                                    <Icon>policy</Icon>
                                )}
                            </ListItemIcon>
                            <ListItemText inset primary="Audit" />
                        </MenuItem>
                    ) : (
                        <></>
                    ),
                    <Collapse
                        key={'operator-site-review-users'}
                        in={collapseOpen === 'audit'}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <MenuItem
                                data-cy="operator-site-infractions"
                                component={Link}
                                button
                                dense
                                to={homePath + '/infractions'}
                                selected={location.pathname === homePath + '/infractions'}
                                onClick={handleClick}
                            >
                                {unresolvedInfractionCount > 0 ? (
                                    <>
                                        <ListItemIcon style={styles.subMenuIcon}>
                                            <Icon>assignment_late</Icon>
                                        </ListItemIcon>
                                        <ListItemText inset primary={pages[homePath + '/infractions']} />
                                        <Badge
                                            style={styles.badge}
                                            badgeContent={unresolvedInfractionCount}
                                            color="primary"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <ListItemIcon style={styles.subMenuIcon}>
                                            <Icon>assignment_late</Icon>
                                        </ListItemIcon>
                                        <ListItemText inset primary={pages[homePath + '/infractions']} />
                                    </>
                                )}
                            </MenuItem>
                        </List>
                    </Collapse>,
                    (!rolePermissions ||
                        _.get(rolePermissions, 'configuration.announcements', false) ||
                        (pickupsEnabled && _.get(rolePermissions, 'pickups.assetTracking', false)) ||
                        (pickupsEnabled && _.get(rolePermissions, 'pickups.bottleDrives', false)) ||
                        _.get(rolePermissions, 'system.imgCaptureStatus', false) ||
                        (pickupsEnabled && _.get(rolePermissions, 'pickups.import', false)) ||
                        _.get(rolePermissions, 'system.sendOuts', false) ||
                        _.get(rolePermissions, 'usage.questionnaires', false)) && (
                        <MenuItem
                            data-cy={`${index}-operatorsite-tools-menu-button`}
                            key={`${index}-operatorsite-tools-menu-button`}
                            button
                            onClick={() => onCollapse('tools')}
                        >
                            <ListItemIcon>
                                {pendingBottleDriveCount > 0 ? (
                                    <Badge badgeContent={pendingBottleDriveCount} color="primary">
                                        <Icon>build</Icon>
                                    </Badge>
                                ) : (
                                    <Icon>build</Icon>
                                )}
                            </ListItemIcon>
                            <ListItemText inset primary="Tools" />
                        </MenuItem>
                    ),
                    <Collapse key={'operator-site-users'} in={collapseOpen === 'tools'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {(!rolePermissions || _.get(rolePermissions, 'configuration.announcements', false)) && (
                                <MenuItem
                                    data-cy="operator-site-announcements"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/announcements'}
                                    selected={location.pathname === homePath + '/announcements'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>campaign</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/announcements']} />
                                </MenuItem>
                            )}
                            {pickupsEnabled &&
                                (!rolePermissions || _.get(rolePermissions, 'pickups.assetTracking', false)) && (
                                    <MenuItem
                                        data-cy="operatorsite-asset-tracking"
                                        component={Link}
                                        button
                                        dense
                                        to={homePath + '/asset-tracking'}
                                        selected={location.pathname === homePath + '/asset-tracking'}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon style={styles.subMenuIcon}>
                                            <Icon>not_listed_location</Icon>
                                        </ListItemIcon>
                                        <ListItemText inset primary={pages[homePath + '/asset-tracking']} />
                                    </MenuItem>
                                )}
                            {pickupsEnabled &&
                                (!rolePermissions || _.get(rolePermissions, 'pickups.bottleDrives', false)) && (
                                    <MenuItem
                                        data-cy="operatorsite-bottle-drives"
                                        component={Link}
                                        button
                                        dense
                                        to={homePath + '/bottle-drives'}
                                        selected={location.pathname === homePath + '/bottle-drives'}
                                        onClick={handleClick}
                                    >
                                        {pendingBottleDriveCount > 0 ? (
                                            <>
                                                <ListItemIcon style={styles.subMenuIcon}>
                                                    <Icon>recycling</Icon>
                                                </ListItemIcon>
                                                <ListItemText inset primary={pages[homePath + '/bottle-drives']} />
                                                <Badge
                                                    style={styles.badge}
                                                    badgeContent={pendingBottleDriveCount}
                                                    color="primary"
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <ListItemIcon style={styles.subMenuIcon}>
                                                    <Icon>recycling</Icon>
                                                </ListItemIcon>
                                                <ListItemText inset primary={pages[homePath + '/bottle-drives']} />
                                            </>
                                        )}
                                    </MenuItem>
                                )}
                            {(!rolePermissions || _.get(rolePermissions, 'system.imgCaptureStatus', false)) &&
                                isSTDRegion() && (
                                    <MenuItem
                                        button
                                        dense
                                        component={Link}
                                        to={homePath + '/img-capture-status'}
                                        selected={location.pathname === homePath + '/img-capture-status'}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon style={styles.subMenuIcon}>
                                            <Icon>wallpaper</Icon>
                                        </ListItemIcon>
                                        <ListItemText inset primary={pages[homePath + '/img-capture-status']} />
                                    </MenuItem>
                                )}
                            {pickupsEnabled && (!rolePermissions || _.get(rolePermissions, 'pickups.import', false)) && (
                                <MenuItem
                                    data-cy="operatorsite-import-pickups"
                                    component={Link}
                                    button
                                    dense
                                    to={homePath + '/import-pickups'}
                                    selected={location.pathname === homePath + '/import-pickups'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>input</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/import-pickups']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'system.sendOuts', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/send-outs'}
                                    selected={location.pathname === homePath + '/send-outs'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>send</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/send-outs']} />
                                </MenuItem>
                            )}

                            {(!rolePermissions || _.get(rolePermissions, 'configuration.questionnaires', false)) && (
                                <MenuItem
                                    data-cy="operator-site-questionnaires"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/questionnaires'}
                                    selected={location.pathname === homePath + '/questionnaires'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>quiz</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/questionnaires']} />
                                </MenuItem>
                            )}
                        </List>
                    </Collapse>,
                    (!rolePermissions ||
                        (rolePermissions.usage &&
                            (rolePermissions.usage.trends ||
                                (promosEnabled && rolePermissions.usage.promoUsage) ||
                                rolePermissions.usage.donations ||
                                rolePermissions.usage.fees ||
                                rolePermissions.usage.usage ||
                                (pickupsEnabled && rolePermissions.usage.stops)))) && (
                        <MenuItem
                            data-cy={`${index}-operatorsite-usage-menu-button`}
                            key={`${index}-operatorsite-usage-menu-button`}
                            button
                            onClick={() => onCollapse('usage')}
                        >
                            <ListItemIcon>
                                <Icon>data_usage</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary="Usage" />
                        </MenuItem>
                    ),
                    <Collapse key={'operator-site-trends'} in={collapseOpen === 'usage'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {(!rolePermissions || _.get(rolePermissions, 'usage.donations', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/donations'}
                                    selected={location.pathname === homePath + '/donations'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>volunteer_activism</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/donations']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'usage.complaints', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/complaints'}
                                    selected={location.pathname === homePath + '/complaints'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>mood_bad</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/complaints']} />
                                </MenuItem>
                            )}
                            {promosEnabled && (!rolePermissions || _.get(rolePermissions, 'usage.promoUsage', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/promo-usage'}
                                    selected={location.pathname === homePath + '/promo-usage'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>analytics</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/promo-usage']} />
                                </MenuItem>
                            )}
                            {pickupsEnabled && (!rolePermissions || _.get(rolePermissions, 'usage.stops', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/stops'}
                                    selected={location.pathname === homePath + '/stops'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>dangerous</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/stops']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'usage.fees', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/fees-usage'}
                                    selected={location.pathname === homePath + '/fees-usage'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>subscriptions</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/fees-usage']} />
                                </MenuItem>
                            )}

                            {pickupsEnabled &&
                                tippingEnabled &&
                                (!rolePermissions || _.get(rolePermissions, 'usage.tips', false)) && (
                                    <MenuItem
                                        button
                                        dense
                                        component={Link}
                                        to={homePath + '/tips'}
                                        selected={location.pathname === homePath + '/tips'}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon style={styles.subMenuIcon}>
                                            <Icon>payments</Icon>
                                        </ListItemIcon>
                                        <ListItemText inset primary={pages[homePath + '/tips']} />
                                    </MenuItem>
                                )}
                            {(!rolePermissions || _.get(rolePermissions, 'usage.questionnaires', false)) && (
                                <MenuItem
                                    data-cy="operator-site-audience-capture"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/audience-capture'}
                                    selected={location.pathname === homePath + '/audience-capture'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>quiz</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/audience-capture']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'usage.trends', false)) && (
                                <MenuItem
                                    data-cy="operator-site-trends"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/trends'}
                                    selected={location.pathname === homePath + '/trends'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>trending_up</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/trends']} />
                                </MenuItem>
                            )}
                        </List>
                    </Collapse>,
                    (!rolePermissions ||
                        (rolePermissions.configuration &&
                            ((promosEnabled && rolePermissions.configuration.promos) ||
                                rolePermissions.configuration.commodities ||
                                rolePermissions.configuration.rates ||
                                rolePermissions.configuration.regions ||
                                rolePermissions.configuration.services ||
                                rolePermissions.configuration.fees ||
                                rolePermissions.configuration.ratingPlatforms ||
                                rolePermissions.configuration.ipBlocking))) && (
                        <MenuItem
                            data-cy={`${index}-operatorsite-configuration-menu-button`}
                            key={`${index}-operatorsite-configuration-menu-button`}
                            button
                            onClick={() => onCollapse('config')}
                        >
                            <ListItemIcon>
                                <Icon>settings</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary="Configuration" />
                        </MenuItem>
                    ),
                    <Collapse
                        key={'operator-site-announcements'}
                        in={collapseOpen === 'config'}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            {(!rolePermissions || _.get(rolePermissions, 'configuration.commodities', false)) && (
                                <MenuItem
                                    data-cy="operator-site-commodities"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/commodities'}
                                    selected={location.pathname === homePath + '/commodities'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>category</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/commodities']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'configuration.fees', false)) && (
                                <MenuItem
                                    data-cy="operator-site-fees"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/fees'}
                                    selected={location.pathname === homePath + '/fees'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>request_quote</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/fees']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'configuration.promos', false)) &&
                                promosEnabled && (
                                    <MenuItem
                                        data-cy="operator-site-promos"
                                        button
                                        dense
                                        component={Link}
                                        to={homePath + '/promos'}
                                        selected={location.pathname === homePath + '/promos'}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon style={styles.subMenuIcon}>
                                            <Icon>speaker_notes</Icon>
                                        </ListItemIcon>
                                        <ListItemText inset primary={pages[homePath + '/promos']} />
                                    </MenuItem>
                                )}
                            {(!rolePermissions || _.get(rolePermissions, 'configuration.rates', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/rates'}
                                    selected={location.pathname === homePath + '/rates'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>list_alt</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/rates']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'configuration.ratingPlatforms', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/rating-platforms'}
                                    selected={location.pathname === homePath + '/rating-platforms'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>thumbs_up_down</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/rating-platforms']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'configuration.regions', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/regions'}
                                    selected={location.pathname === homePath + '/regions'}
                                    onClick={handleClick}
                                    data-cy="operator-site-regions"
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>public</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/regions']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'configuration.services', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/services'}
                                    selected={location.pathname === homePath + '/services'}
                                    onClick={handleClick}
                                    data-cy="operator-site-services"
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>list</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/services']} />
                                </MenuItem>
                            )}
                            {/* <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/droplocations'}
                                    selected={location.pathname === homePath + '/droplocations'}
                                    onClick={handleClick}
                                >
                                    <ListItemText inset primary={pages[homePath + '/droplocations']} />
                                </MenuItem> */}
                            {(!rolePermissions || _.get(rolePermissions, 'configuration.taxes', false)) && (
                                <MenuItem
                                    data-cy="operator-site-taxes"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/taxes'}
                                    selected={location.pathname === homePath + '/taxes'}
                                    onClick={handleClick}
                                >
                                    <ListItemIcon style={styles.subMenuIcon}>
                                        <Icon>price_change</Icon>
                                    </ListItemIcon>
                                    <ListItemText inset primary={pages[homePath + '/taxes']} />
                                </MenuItem>
                            )}
                            {pickupsEnabled &&
                                (!rolePermissions || _.get(rolePermissions, 'configuration.trucks', false)) && (
                                    <MenuItem
                                        data-cy="operator-site-trucks"
                                        button
                                        dense
                                        component={Link}
                                        to={homePath + '/trucks'}
                                        selected={location.pathname === homePath + '/trucks'}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon style={styles.subMenuIcon}>
                                            <Icon>local_shipping</Icon>
                                        </ListItemIcon>
                                        <ListItemText inset primary={pages[homePath + '/trucks']} />
                                    </MenuItem>
                                )}
                        </List>
                    </Collapse>,
                    (!rolePermissions ||
                        (rolePermissions.logs &&
                            (rolePermissions.logs.googleCalls ||
                                rolePermissions.logs.databaseLogs ||
                                rolePermissions.logs.requestLogs ||
                                rolePermissions.logs.messageLogs))) && (
                        <MenuItem
                            data-cy={`${index}-operatorsite-logs-menu-button`}
                            key={`${index}-operatorsite-logs-menu-button`}
                            button
                            onClick={() => onCollapse('logs')}
                        >
                            <ListItemIcon>
                                <Icon>dvr</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary="Logs" />
                        </MenuItem>
                    ),
                    <Collapse key={'operator-site-logs'} in={collapseOpen === 'logs'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {(!rolePermissions || _.get(rolePermissions, 'logs.databaseLogs', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/db-logs'}
                                    selected={location.pathname === homePath + '/db-logs'}
                                    onClick={handleClick}
                                >
                                    <ListItemText inset primary={pages[homePath + '/db-logs']} />
                                </MenuItem>
                            )}
                            {/* {(!rolePermissions || _.get(rolePermissions, 'logs.databaseLogs', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/logs'}
                                    selected={location.pathname === homePath + '/logs'}
                                    onClick={handleClick}
                                >
                                    <ListItemText inset primary={pages[homePath + '/logs']} />
                                </MenuItem>
                            )} */}
                            {(!rolePermissions || _.get(rolePermissions, 'logs.googleCalls', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/google-calls'}
                                    selected={location.pathname === homePath + '/google-calls'}
                                    onClick={handleClick}
                                >
                                    <ListItemText inset primary={pages[homePath + '/google-calls']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'logs.requestLogs', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/req-logs'}
                                    selected={location.pathname === homePath + '/req-logs'}
                                    onClick={handleClick}
                                >
                                    <ListItemText inset primary={pages[homePath + '/req-logs']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'logs.messageLogs', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/msg-logs'}
                                    selected={location.pathname === homePath + '/msg-logs'}
                                    onClick={handleClick}
                                >
                                    <ListItemText inset primary={pages[homePath + '/msg-logs']} />
                                </MenuItem>
                            )}
                        </List>
                    </Collapse>,
                    (!rolePermissions || (rolePermissions.security && rolePermissions.security.ipBlocking)) && (
                        <MenuItem
                            data-cy={`${index}-operatorsite-security-menu-button`}
                            key={`${index}-operatorsite-security-menu-button`}
                            button
                            onClick={() => onCollapse('security')}
                        >
                            <ListItemIcon>
                                <Icon>security</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary="Security" />
                        </MenuItem>
                    ),
                    <Collapse
                        key={'operator-site-security'}
                        in={collapseOpen === 'security'}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            {(!rolePermissions || _.get(rolePermissions, 'security.ipBlocking', false)) && (
                                <MenuItem
                                    data-cy="operator-ip-blocking"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/ip-blocking'}
                                    selected={location.pathname === homePath + '/ip-blocking'}
                                    onClick={handleClick}
                                >
                                    <ListItemText inset primary={pages[homePath + '/ip-blocking']} />
                                </MenuItem>
                            )}
                        </List>
                    </Collapse>,
                    (!rolePermissions ||
                        (rolePermissions.system &&
                            (rolePermissions.system.systemTasks ||
                                rolePermissions.system.savedNotifications ||
                                rolePermissions.system.settings ||
                                rolePermissions.system.informationForms ||
                                rolePermissions.system.integrations))) && (
                        <MenuItem
                            data-cy={`${index}-operatorsite-system-menu-button`}
                            key={`${index}-operatorsite-system-menu-button`}
                            button
                            onClick={() => onCollapse('system')}
                        >
                            <ListItemIcon>
                                <Icon>developer_mode</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary="System" />
                        </MenuItem>
                    ),
                    <Collapse key={'operator-site-system'} in={collapseOpen === 'system'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {(operator.isAllowedToDebug || _.get(rolePermissions, 'system.systemTasks', false)) && (
                                <MenuItem
                                    data-cy="operator-site-debug"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/debug'}
                                    selected={location.pathname === homePath + '/debug'}
                                    onClick={handleClick}
                                >
                                    <ListItemText inset primary={pages[homePath + '/debug']} />
                                </MenuItem>
                            )}

                            {(!rolePermissions || _.get(rolePermissions, 'system.emails', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/email-editor'}
                                    selected={location.pathname === homePath + '/email-editor'}
                                    onClick={handleClick}
                                    data-cy="operator-site-notification-editor"
                                >
                                    <ListItemText inset primary={pages[homePath + '/email-editor']} />
                                </MenuItem>
                            )}

                            {(!rolePermissions || _.get(rolePermissions, 'system.informationForms', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/informationForms'}
                                    selected={location.pathname === homePath + '/informationForms'}
                                    onClick={handleClick}
                                    data-cy="operator-site-information-forms"
                                >
                                    <ListItemText inset primary={pages[homePath + '/informationForms']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'system.integrations', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/integrations'}
                                    selected={location.pathname === homePath + '/integrations'}
                                    onClick={handleClick}
                                >
                                    <ListItemText inset primary={pages[homePath + '/integrations']} />
                                </MenuItem>
                            )}
                            {(!rolePermissions || _.get(rolePermissions, 'system.rolePermissions', false)) && (
                                <MenuItem
                                    data-cy="operator-site-role-permissions"
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/role-permissions'}
                                    selected={location.pathname === homePath + '/role-permissions'}
                                    onClick={handleClick}
                                >
                                    <ListItemText inset primary={pages[homePath + '/role-permissions']} />
                                </MenuItem>
                            )}
                            {!isProductionEnv &&
                                (!rolePermissions || _.get(rolePermissions, 'system.savedNotifications', false)) && (
                                    <MenuItem
                                        data-cy="operator-site-saved-notifications"
                                        button
                                        dense
                                        component={Link}
                                        to={homePath + '/saved-notifications'}
                                        selected={location.pathname === homePath + '/saved-notifications'}
                                        onClick={handleClick}
                                    >
                                        <ListItemText inset primary={pages[homePath + '/saved-notifications']} />
                                    </MenuItem>
                                )}
                            {(!rolePermissions || _.get(rolePermissions, 'system.settings', false)) && (
                                <MenuItem
                                    button
                                    dense
                                    component={Link}
                                    to={homePath + '/settings'}
                                    selected={location.pathname === homePath + '/settings'}
                                    onClick={handleClick}
                                    data-cy="operator-site-settings"
                                >
                                    <ListItemText inset primary={pages[homePath + '/settings']} />
                                </MenuItem>
                            )}
                        </List>
                    </Collapse>
                ]}
            </MenuList>

            <div style={{ marginTop: 'auto', marginBottom: 8 }}>
                {operator.adminView && (
                    <MenuList style={{ width: drawerWidth }}>
                        <MenuItem
                            style={{ marginTop: theme.spacing.unit * 2 }}
                            onClick={onExitView}
                            data-cy="exit-admin-view"
                        >
                            <ListItemIcon style={{ color: theme.palette.secondary.main }}>
                                <Icon>home</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary="Exit view" />
                        </MenuItem>
                    </MenuList>
                )}
                {!operator.adminView && (
                    <MenuList style={{ width: drawerWidth }}>
                        <MenuItem button style={{ marginTop: theme.spacing.unit * 2 }} onClick={handleNightModeClick}>
                            <ListItemIcon>
                                <Icon>brightness_2</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary="Night mode" />
                        </MenuItem>
                        {(_user.isClerk(operator) || _user.isCollectorAdmin(operator)) && (
                            <MenuItem
                                data-cy="depot-login"
                                button
                                onClick={() => {
                                    handleDepotLogout();
                                }}
                            >
                                <ListItemIcon>
                                    <Icon>lock</Icon>
                                </ListItemIcon>
                                <ListItemText inset primary="Lock Screen" />
                            </MenuItem>
                        )}
                        <MenuItem data-cy="operatorsite-logout-button" button onClick={onLogOut}>
                            <ListItemIcon>
                                <Icon>exit_to_app</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary="Sign out" />
                        </MenuItem>
                    </MenuList>
                )}

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography color="textSecondary" style={{ fontSize: 12 }}>
                        <span style={{ color: theme.palette.envColor[500] }}>
                            {process.env.REACT_APP_ENV !== 'PROD' ? process.env.REACT_APP_ENV : null}
                        </span>
                        <span style={{ margin: `0 ${theme.spacing.unit}px` }}>{VERSION_FRONT}</span>
                        <span style={{ color: theme.palette.text.disabled }}>
                            {process.env.REACT_APP_BUILD_VERSION}
                        </span>
                    </Typography>
                </div>
            </div>
            {_user.isCollectorAdmin(operator) && _.get(operator, 'collectors', []).length > 1 && (
                <Popover
                    open={!_.isNil(accountsAnchorEl)}
                    anchorEl={accountsAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    onClose={onAccountsMenu(false)}
                >
                    <List style={{ width: drawerWidth }}>
                        {operator.collectors.map((collector, index) => {
                            return (
                                <MenuItem
                                    data-cy={`${index}-operator-site-account-button`}
                                    key={collector._id}
                                    selected={accountSelectedIndex === index}
                                    disableGutters={false}
                                    style={{ height: 'auto' }}
                                    onClick={onAccountsChange(index)}
                                >
                                    <ListItemText
                                        inset
                                        primary={
                                            <span
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    display: 'block',
                                                    paddingRight: 0,
                                                    fontSize: 'smaller'
                                                }}
                                            >
                                                {collector.name}
                                            </span>
                                        }
                                        secondary={
                                            <span
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    display: 'block',
                                                    paddingRight: 0,
                                                    fontSize: 'smaller'
                                                }}
                                            >
                                                {collector.code}
                                            </span>
                                        }
                                        style={{
                                            paddingLeft: 8
                                        }}
                                    />
                                </MenuItem>
                            );
                        })}
                    </List>
                </Popover>
            )}
        </div>
    );
}

export default withTheme()(OperatorDrawerContents);
