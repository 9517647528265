import React, { useState, useRef } from 'react';
import useSpeedMode from 'containers/Operators/PORScreen/hooks/useSpeedMode';

import _ from 'lodash';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import BulkSkuTypeSelectionDropdown from 'components/BulkComponents/BulkSkuTypeSelectionDropdown';

import { Icon as MDIcon } from '@mdi/react';

import * as colors from '@material-ui/core/colors';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import { withTheme } from '@material-ui/core/styles';

import { mdiTag } from '@mdi/js';
import { FormControlLabel, Switch, Paper, Typography } from '@material-ui/core';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import useWindowSize from 'utils/hooks/useWindowSize';

function BulkSearchDialog(props) {
    const {
        theme,
        open,
        skus,
        error,
        inProgress,
        searchQuery,
        continuousCountingEnabled,
        skuType,
        //commoditiesProcessed,
        commodityColors,
        onQueryChange,
        onSearchForBulkToCount,
        onClose,
        onToggleContinuousCounting,
        onSkuTypeChangeSearch,
        resetTimeoutCount,
        handleBulkLookUp,
        children,
        bulkLookUpLoading,
        printedLabelSearchLoading
        /*onUpdateCommoditiesProcessed,
        onIncrementCommoditiedProcessed,
        onDecrementCommoditiedProcessed*/
    } = props;
    const [width, height] = useWindowSize();
    let mobileMode = width <= 600;
    let showKeyboard = !mobileMode && JSON.parse(localStorage.getItem('hideKeyboard')) !== true;

    const { speedMode, handleToggleSpeedMode } = useSpeedMode();
    const [layout, setLayout] = useState('default');
    const keyboard = useRef();

    const handleKeyPress = e => {
        if (e.key === 'Enter' && !_.isEmpty(searchQuery)) {
            handleBulkLookUp();
        }
    };

    const handleShift = () => {
        const newLayoutName = layout === 'default' ? 'shift' : 'default';
        setLayout(newLayoutName);
    };
    const handleEnter = () => {
        if (!_.isEmpty(searchQuery)) {
            handleBulkLookUp();
        }
    };

    const onKeyPress = button => {
        if (button === '{shift}' || button === '{lock}') handleShift();
        if (button === '{enter}') handleEnter();
    };

    return (
        <Dialog open={open} data-cy="bulk-search-dialog">
            <DialogTitlePrimary>Enter label/bagtag</DialogTitlePrimary>
            <DialogContent
                style={{
                    paddingTop: theme.spacing.unit,
                    paddingBottom: 0,
                    height: '100%',
                    overflow: 'hidden',
                    minWidth: mobileMode ? undefined : 500
                }}
            >
                <Grid container spacing={theme.spacing.unit}>
                    <Grid item sm={8} xs={8}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                data-cy="bulk-counter-bagtag-search"
                                id="bulk-label"
                                label="Bagtag/Label"
                                value={searchQuery}
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                helperText={!_.isNil(error) ? error : null}
                                error={!_.isNil(error)}
                                autoFocus
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MDIcon path={mdiTag} size={1} color={colors.blue[500]} />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={e => {
                                    onQueryChange(e);
                                    if (showKeyboard && !_.isNil(keyboard.current)) {
                                        keyboard.current.setInput(e.target.value);
                                    }
                                }}
                                onKeyPress={handleKeyPress}
                            />
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        sm={4}
                        xs={4}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {showKeyboard && (
                            <FormControl fullWidth>
                                <Button
                                    data-cy="bulk-counter-count-bulk"
                                    color="primary"
                                    variant="contained"
                                    disabled={_.isEmpty(searchQuery)}
                                    style={{
                                        marginTop: theme.spacing.unit,
                                        marginRight: theme.spacing.unit,
                                        height: 56
                                    }}
                                    onClick={() => {
                                        onQueryChange({ target: { value: '' } });
                                        if (showKeyboard) {
                                            keyboard.current.setInput('');
                                        }
                                    }}
                                >
                                    clear
                                </Button>
                            </FormControl>
                        )}
                        <FormControl fullWidth>
                            <Button
                                data-cy="bulk-counter-count-bulk"
                                color="primary"
                                variant="contained"
                                disabled={_.isEmpty(searchQuery) || bulkLookUpLoading || printedLabelSearchLoading}
                                style={{ marginTop: theme.spacing.unit, height: 56 }}
                                onClick={() => {
                                    handleBulkLookUp();
                                    resetTimeoutCount();
                                }}
                            >
                                find
                            </Button>
                        </FormControl>
                    </Grid>
                    {children}
                    {showKeyboard && (
                        <Grid item sm={12} xs={12}>
                            <Keyboard
                                keyboardRef={r => (keyboard.current = r)}
                                layoutName={layout}
                                onChange={input => {
                                    let e = { target: { value: input } };
                                    onQueryChange(e);
                                }}
                                onKeyPress={onKeyPress}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    data-cy="close"
                    color="primary"
                    style={{ margin: theme.spacing.unit }}
                    onClick={() => {
                        onClose();
                    }}
                >
                    close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTheme()(BulkSearchDialog);
