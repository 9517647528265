import _ from 'lodash';
import { deviceHelper, getDeviceInformation } from '../utils/misc.js';
import { AVAILABLE_LANGS } from '../constants.js';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { _time } from 'std';

import { logError, logWarning } from '../utils/reporter';
import { cfaSignIn, cfaSignOut } from 'capacitor-firebase-auth';
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';

const fcm = FCM;

const Analytics = require('utils/analytics.js');

// Documentation for these methods is found here
// https://firebase.google.com/docs/auth/web/cordova

/**
 * Initializes firebase for use
 */
export async function init() {
    try {
        const config = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: '',
            messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID
        };
        firebase.initializeApp(config);
        console.info('%cFirebase initialized', 'color: green; font-weight: bold;');
    } catch (err) {
        console.error(err);
        logError(err, 'firebaseAdmin - init');
    }
}

/**
 * Checks if there is an active session of a user logged in
 * @return {boolean} True if there is a current session, false otherwise
 */
export async function grabActiveUser() {
    let user = await firebase.auth().currentUser;
    let currentUser;
    if (!_.isNil(currentUser)) {
        currentUser = getInfo(user);
    }
    return currentUser;
}

/**
 * Logs in or registers the user to our server with our OAuth methods
 * @param {object} oAuthUser The user to log in
 * @param {object} token The ID token for identifying the user
 * @return {object} The object if successful, otherwise null if not
 */
export async function loginWithOAuthUser(oAuthUser, token) {
    try {
        let response = await fetch(process.env.REACT_APP_API_URL + '/oauth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                newUser: oAuthUser,
                token: token,
                deviceInfo: await getDeviceInformation(),
                timezone: _time.getTimezone()
            })
        });

        // check response
        if (response.status === 200) {
            let data = await response.json();
            console.log('login response: ', data);
            if (data.isNotRegistered) {
                return { isNotRegistered: true };
            } else if (data.twoFactorAuthenticationCodeRequired) {
                return data;
            } else {
                let user = data.user;

                let homeURL;
                switch (user.accountType) {
                    case 'Customer':
                        homeURL = '/customers/' + user._id;
                        if (_.isNil(user.name)) {
                            homeURL += '/profile';
                        } else if (!_.isNil(user.lastPath)) {
                            homeURL += user.lastPath;
                        } else if (!_.isNil(user.charities) && !_.isEmpty(user.charities)) {
                            homeURL += '/' + _.first(user.charities) + '/charity';
                        }
                        break;
                    default:
                        throw new Error('unexpectedAccountType');
                }

                return {
                    type: 'SET_AUTH_TRUE',
                    accountType: user.accountType,
                    multipleAccountAccessList: user.multipleAccountAccessList,
                    name: !_.isNil(user.name) ? user.name.first + ' ' + user.name.last : undefined,
                    _id: user._id,
                    collector_id: _.get(user, 'collector._id', undefined),
                    home: homeURL,
                    isNew: data.isNew
                };
            }
        } else if (response.status === 429) {
            return response.json();
        } else if (response.status === 400) {
            let message;
            try {
                message = (await response.json()).message; // error message supplied by the server
            } catch (err) {
                message = 'badRequest'; // error message to be generated locally
            }
            throw new Error(message);
        } else {
            throw new Error('serverError');
        }
    } catch (err) {
        //logError(err, 'firebaseAdmin - loginOAuth');
        return err;
    }
}

export async function registerWithOAuthUser(oAuthUser, token, charity = undefined) {
    if (!_.isNil(charity)) {
        oAuthUser.charityPreferred = charity._id;
    }
    oAuthUser.deviceInfo = await getDeviceInformation();
    try {
        if (_.isNil(oAuthUser.email)) {
            let email = oAuthUser.altEmail;
            _.set(oAuthUser, 'email', email);
        }

        if(_.isEmpty(oAuthUser.phone)) {
            delete oAuthUser.phone
        }

        let response = await fetch(process.env.REACT_APP_API_URL + '/oauth/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ newUser: oAuthUser, token: token })
        });

        return await handleRegistrationResponse(response, 'Customer', 'Registration Customer');
        /*// check response
        if (response.status === 200) {
            let data = await response.json();
            let user = data.user;
            console.log('oAuth response', user);
            let homeURL;
            switch (user.accountType) {
                case 'Customer':
                    homeURL = '/customers/' + user._id;
                    if (_.isNil(user.name)) {
                        homeURL += '/profile';
                    } else if (!_.isNil(user.charity)) {
                        homeURL += '/charity';
                    }
                    break;
                default:
                    throw new Error('Unexpected account type.');
            }
            Analytics.logEvent('Registration', 'Customer');
            Analytics.logFacebookEvent('Registration Customer');

            return {
                type: 'SET_AUTH_TRUE',
                accountType: user.accountType,
                name: !_.isNil(user.name) ? user.name.first + ' ' + user.name.last : undefined,
                _id: user._id,
                collector_id: _.get(user, 'collector._id', undefined),
                home: homeURL,
                isNew: data.isNew
            };
        } else if (response.status === 400) {
            let message;
            try {
                message = (await response.json()).message; // error message supplied by the server
            } catch (err) {
                message = 'Bad request.'; // error message to be generated locally
            }
            throw new Error(message);
        } else {
            throw new Error('Server error occurred. Please try again later');
        }*/
    } catch (err) {
        return err;
    }
}

export async function registerOAuthOrganisation(formAdjusted, file, oAuthUser, idToken) {
    oAuthUser.deviceInfo = await getDeviceInformation();
    try {
        if (_.isNil(oAuthUser.email)) {
            let email = oAuthUser.altEmail;
            _.set(oAuthUser, 'email', email);
        }

        const formData = new FormData(); // NB: FormData objects cannot be displayed in console.log
        if (!_.isNil(file)) {
            formData.append('logo', file);
        }
        formAdjusted.oAuthUser = oAuthUser;
        formAdjusted.idToken = idToken;

        formData.append('form', JSON.stringify(formAdjusted)); // will show up in req.body

        let response = await fetch(process.env.REACT_APP_API_URL + '/oauth/registerOrganisation', {
            method: 'POST',
            credentials: 'include',
            body: formData
        });

        return await handleRegistrationResponse(response, 'Organization', 'Registration Organization');
    } catch (err) {
        return err;
    }
}

export async function registerOAuthBottleDrive(formAdjusted, oAuthUser, token) {
    oAuthUser.deviceInfo = await getDeviceInformation();
    try {
        if (_.isNil(oAuthUser.email)) {
            let email = oAuthUser.altEmail;
            _.set(oAuthUser, 'email', email);
        }

        //const formData = new FormData(); // NB: FormData objects cannot be displayed in console.log
        /*formAdjusted.oAuthUser = oAuthUser;
        formAdjusted.idToken = idToken;*/

        //formData.append('form', JSON.stringify(formAdjusted)); // will show up in req.body
        let response = await fetch(process.env.REACT_APP_API_URL + '/oauth/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ newUser: oAuthUser, token: token, form: formAdjusted })
        });

        return await handleRegistrationResponse(response, 'Customer', 'Registration Customer');
    } catch (err) {
        return err;
    }
}

async function handleRegistrationResponse(response, analyticsRgistrationType, facebookEventType) {
    // check response
    if (response.status === 200) {
        let data = await response.json();
        if (data.twoFactorAuthenticationCodeRequired) {
            return data;
        }
        let user = data.user;
        console.log('oAuth response', user);
        let homeURL;
        switch (user.accountType) {
            case 'Customer':
                homeURL = '/customers/' + user._id;
                if (_.isNil(user.name)) {
                    homeURL += '/profile';
                } else if (!_.isNil(user.charities) && !_.isEmpty(user.charities)) {
                    homeURL += '/' + _.first(user.charities) + '/charity';
                }
                break;
            default:
                throw new Error('unexpectedAccountType');
        }
        Analytics.logEvent('Registration', analyticsRgistrationType);
        Analytics.logFacebookEvent(facebookEventType);

        return {
            type: 'SET_AUTH_TRUE',
            accountType: user.accountType,
            multipleAccountAccessList: user.multipleAccountAccessList,
            name: !_.isNil(user.name) ? user.name.first + ' ' + user.name.last : undefined,
            _id: user._id,
            collector_id: _.get(user, 'collector._id', undefined),
            home: homeURL,
            isNew: data.isNew
        };
    } else if (response.status === 400) {
        let message;
        try {
            let data = await response.json();
            message = data.message; // error message supplied by the server
        } catch (err) {
            message = 'serverError'; //Bad request.'; // error message to be generated locally
        }
        throw new Error(message);
    } else {
        throw new Error('serverError');
    }
}

/**
 * Logs out the user of a firebase session
 * @return {object} True if successful, otherwise false if not
 */
export async function logOutOAuth() {
    try {
        if (deviceHelper.isNativeApp()) {
            await cfaSignOut().subscribe();
        } else {
            await firebase.auth().signOut();
        }
        await firebase.auth().signOut();
        return true;
    } catch (err) {
        logError(err, 'firebaseAdmin - logOutOAuth');
        return false;
    }
}

/**
 * After an authentication with a redirect, this is needed to verify if it was successful or not
 * @return {object} The object if successful, otherwise null if not
 */
export async function checkAuth() {
    try {
        let result = await firebase.auth().getRedirectResult();
        if (result.credential) {
            return getInfo(result);
        } else {
            return null;
        }
    } catch (err) {
        logError(err, 'firebaseAdmin - checkAuth');
        return err;
    }
}

// export async function authenticateWithCustomOAuthToken(token) {
//     firebase
//         .auth()
//         .signInWithCustomToken(token)
//         .then(userCredential => {
//             const user = userCredential.user;
//         })
//         .catch(error => {
//             const errorCode = error.code;
//             const errorMessage = error.message;
//         });
// }

/**
 * Authenticate user with a redirect to Facebook
 * @return {object} The object if successful, otherwise throws
 */
export async function authenticateWithoAuth(providerType) {
    let provider, nativeProvider;
    switch (providerType) {
        case 'google':
            provider = new firebase.auth.GoogleAuthProvider();
            nativeProvider = 'google.com';
            break;
        case 'fb':
            provider = new firebase.auth.FacebookAuthProvider();
            nativeProvider = 'facebook.com';
            break;
        case 'apple':
            provider = new firebase.auth.OAuthProvider('apple.com');
            provider.addScope('email');
            provider.addScope('name');
            nativeProvider = 'apple.com';
            break;
        default:
            throw new Error('invalidOAuthProvider');
    }

    try {
        let result, user;

        if (deviceHelper.isNativeApp()) {
            // cancelling this native dialog throws an error
            user = await loginNativeOAuth(nativeProvider);
        } else {
            const device = await getDeviceInformation();
            if (!device.isInAppBrowser) {
                firebase.auth().languageCode = getLanguageForOAuth() || 'en';
                result = await firebase.auth().signInWithPopup(provider);
            } else {
                return new Error('OAuthOtherApp');
            }
            if (_.isNil(result) || _.isNil(result.credential)) {
                return new Error('errorOAuthVia');
            }
            user = getInfo(result);
        }
        return user;
    } catch (err) {
        // error for already having account with different provider
        if (err.code === 'auth/account-exists-with-different-credential') {
            let email = err.email;
            let methods = await firebase.auth().fetchSignInMethodsForEmail(email);
            if (methods[0] === 'google.com') {
                return new Error('googleAlreadyLinked');
            } else if (methods[0] === 'facebook.com') {
                return new Error('facebookAlreadyLinked');
            } else {
                return new Error('errorAuthViaOAuth');
            }
        } else if (err.code === 'auth/cancelled-popup-request' || err.code === 'auth/popup-closed-by-user') {
            return new Error('authProcessInterrupted');
        } else if (err.code === 'auth/popup-blocked') {
            logWarning(err);
            return new Error('authPopupBlocked');
        } else {
            // silence the useless errors from going to slack
            if (
                err.message !== 'Google Sign In failure.' &&
                err.message !== 'Facebook Sign In cancel.' &&
                err.message !== 'Google Sign In cancel.'
            ) {
                logError(err);
            }

            return new Error('oAuthGenericError'); //err.message);
        }
    }
}

/**
 * Grabs the users ID token to extra authentication
 * @return {string} The token if successful
 */
export async function getCurrentUsersIDToken() {
    return 'dummy-token';
}

function loginNativeOAuth(provider) {
    return new Promise(function(resolve, reject) {
        cfaSignIn(provider).subscribe(
            result => {
                let user = {
                    oAuth: {
                        provider,
                        uid: result.uid
                    },
                    email: result.email,
                    emailVerified: _.get(result, 'emailverified', false)
                };

                try {
                    user.nameFirst = result.displayName
                        .split(' ')
                        .slice(0, -1)
                        .join(' ');
                    user.nameLast = result.displayName
                        .split(' ')
                        .slice(-1)
                        .join(' ');
                } catch (err) {
                    user.nameFirst = '';
                    user.nameLast = '';
                }

                resolve(user);
            },
            err => {
                reject(err);
            }
        );
    });
}

function loginInAppBrowserOAuth(provider) {
    return new Promise(function(resolve, reject) {
        firebase
            .auth()
            .signInWithRedirect(provider)
            .then(function() {
                return firebase.auth().getRedirectResult();
            })
            .then(function(result) {
                resolve(result);
            })
            .catch(function(error) {
                reject(error);
            });
    });
}

function getInfo(object) {
    let user = _.pick(object.user, ['email', 'phoneNumber', 'emailVerified']);
    user.oAuth = {
        provider: object.additionalUserInfo.providerId,
        uid: object.user.uid
    };

    if (_.isNil(user.oAuth.uid)) {
        throw new Error('Authentication process not completed');
    }

    if (_.isNil(user.phoneNumber)) {
        delete user.phoneNumber;
    } else {
        user.phone = user.phoneNumber;
    }

    user.nameFirst = _.get(user, 'additionalUserInfo.given_name', null);
    user.nameLast = _.get(user, 'additionalUserInfo.family_name', null);

    if (_.isNil(user.nameFirst) || _.isNil(user.nameLast)) {
        if (!_.isNil(object.user.displayName)) {
            user.nameFirst = object.user.displayName
                .split(' ')
                .slice(0, -1)
                .join(' ');
            user.nameLast = object.user.displayName
                .split(' ')
                .slice(-1)
                .join(' ');
        } else {
            user.nameFirst = '';
            user.nameLast = '';
        }
    }

    return user;
}

// export function getDb () {
//     return firebase.firestore();
// }

/************* PUSH NOTIFICATIONS ARE BELOW HERE *************/
/*************************************************************/

export async function enablePushNotifications(listenerFunc) {
    if (!deviceHelper.isNativeApp()) {
        return null;
    }

    if (deviceHelper.iOSCordova()) {
        fcm.getToken()
            .then(r => listenerFunc({ value: r.token }))
            .catch(err => console.log(err));
    } else if (deviceHelper.AndroidCordova()) {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive === 'granted') {
            await PushNotifications.register();
        }
        
        await PushNotifications.addListener('registration', listenerFunc);
    }
}

function getLanguageForOAuth() {
    const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];

    let lang = window.localStorage.getItem('lang');
    if (!lang || !availableLangs.includes(lang)) {
        lang = process.env.REACT_APP_REGION_LANGUAGE;
    }

    return lang;
}
