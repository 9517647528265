import React, { useEffect, useState, useReducer, useRef, useMemo, useCallback, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';

import { SplashScreen } from '@capacitor/splash-screen';

import _ from 'lodash';

import VERSION_FRONT from 'VERSION';

import Main from './Main/Main';
import Login from './Login/Login';
import DepotLogin from './Login/DepotLogin';
import Registration from './Registration/Registration';
import Terms from './Legal/Terms/Terms';
import Privacy from './Legal/Privacy/Privacy';
import Rates from './Legal/Rates/Rates';
import Verification from './Verification/Verification';
import ConfirmPickup from './ConfirmPickup/ConfirmPickup';
import PasswordReset from './PasswordReset/PasswordReset';

import ReferralRedirect from 'components/ReferralRedirect/ReferralRedirect';
import HttpErrorDialog from 'components/HttpErrorDialog/HttpErrorDialog';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar';
import CustomDebugDialog from 'components/CustomDebugDialog/CustomDebugDialog';
import ConfirmDialog from 'components/Dialogs/Confirm';
import UpdateRequiredDialog from 'components/BlockingDialogs/UpdateRequiredDialog';
import ServerMaintenanceDialog from 'components/BlockingDialogs/ServerMaintenanceDialog';

import DebugOverlay from 'components/Debug/DebugOverlay';

import { MuiThemeProvider } from '@material-ui/core/styles';

import { HttpProvider } from 'utils/contexts/HttpContext';
import { ConfirmDialogProvider } from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { DebugProvider } from 'components/CustomDebugDialog/DebugContext';
import { SnackbarProvider } from 'components/CustomSnackbar/SnackbarContext';
import { GoogleProvider } from 'utils/contexts/GoogleContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { LocalizationProvider } from 'utils/contexts/LocalizationContext';

import {
    lightTheme as defaultLightTheme,
    darkTheme as defaultDarkTheme,
    adminTheme as defaultAdminTheme,
    markerPinTheme
} from 'theme';

import {
    isProductionEnv,
    deviceHelper,
    changeRequiresRefresh,
    breakingChangeRequiresUpdate,
    breakingChangeAwaitingServer,
    patchCanUpdate,
    isEXPRegion
} from 'utils/misc';
// custom hooks
import useCustomDebugDialog from 'components/CustomDebugDialog/hooks/useCustomDebugDialog';
import useCustomSnackbar from 'components/CustomSnackbar/hooks/useCustomSnackbar';
import useConfirmDialog from 'components/Dialogs/Confirm/useConfirmDialog';
import useHttp from './useHttp';
import useFetchProbe from 'utils/hooks/useFetchProbe';

import { AVAILABLE_LANGS } from '../constants';
import GenericDialog from 'components/Dialogs/GenericDialog';

import { loc } from '../localizations/localizationHandler';
import Authorization from './Authorization/Authorization';
import ChangeEmail from './ChangeEmail/ChangeEmail';
import DownloadApp from './DownloadApp/DownloadApp';

const DEBUG = process.env.REACT_APP_DEBUG;
const DEFAULT_LANG = process.env.REACT_APP_REGION_LANGUAGE;

function dispatchAuthReducer(state, action) {
    switch (action.type) {
        case 'SET_AUTH_TRUE':
            state = _.omit(action, 'type');
            state.isAuthenticated = true;
            return state;
        case 'SET_AUTH_FALSE':
            return {
                isAuthenticated: false,
                accountType: undefined,
                name: undefined,
                _id: undefined,
                collector_id: undefined,
                home: undefined
            };
        default:
            return state;
    }
}

const App = props => {
    const { google, handleLang } = props;

    const [onLoginMessage, setOnLoginMessage] = useState(null);
    const [onLoginMessageVariant, setOnLoginMessageVariant] = useState('success');

    const [lightTheme, setLightTheme] = useState(defaultLightTheme);
    const [darkTheme, setDarkTheme] = useState(defaultDarkTheme);
    const [adminTheme, setAdminTheme] = useState(defaultAdminTheme);
    const [brandLogoOverride, setBrandLogoOverride] = useState({});
    const [theme, setTheme] = useState(lightTheme);
    const [placesService, setPlacesService] = useState(null);

    const [loadingServerInfo, setLoadingServerInfo] = useState(true);
    // const [serverVersion, setServerVersion] = useState(null);

    const [refreshRequired, setRefreshRequired] = useState(false);
    const [updateRequired, setUpdateRequired] = useState(false);
    const [serverMaintenance, setServerMaintenance] = useState(false);

    const [showRefreshDialog, setShowRefreshDialog] = useState(false);

    const [showThirdPartyLogins, setShowThirdPartyLogins] = useState(true);
    const [registrationConfig, setRegistrationConfig] = useState({});

    const [debugHistory, setDebugHistory] = useState([]);

    const [promosEnabled, setPromosEnabled] = useState(true);
    const [pickupsEnabled, setPickupsEnabled] = useState(true);
    const [charityEnabled, setCharityEnabled] = useState(true);
    const [scanQREnabled, setScanQREnabled] = useState(false);
    const [showQREnabled, setShowQREnabled] = useState(false);
    const [helpCenterFunctions, setHelpCenterFunctions] = useState({
        showHowItWorks: true,
        showFaq: true,
        showContactUs: true
    });
    const [showZohoHelpDesk, setShowZohoHelpDesk] = useState(false);

    const [idleLogoutTimeoutConfig, setIdleLogoutTimeoutConfig] = useState({});
    const [idleLogoutDialogOpen, setIdleLogoutDialogOpen] = useState(false);

    const { lang } = useContext(LocalizationContext);

    // auth dispatcher
    const [auth, dispatch] = useReducer(dispatchAuthReducer, {
        isAuthenticated: undefined, // never set this to false initially, will cause page refresh errors
        accountType: undefined,
        name: undefined,
        _id: undefined,
        collector_id: undefined,
        home: undefined,
        multipleAccountAccessList: undefined
    });

    // TODO turn snackbar into a context to prevent prop drilling
    const { snackbarOpen, snackbarVariant, snackbarMessage, snackbarDuration, handleSnackbar } = useCustomSnackbar();

    const {
        http,
        httpErrorDialogOpen,
        httpErrorType,
        handleCloseHttpErrorDialog,
        oversizedFileSize,
        bytesSent,
        bytesReceived
    } = useHttp({
        dispatch,
        DEBUG,
        debugHistory,
        setDebugHistory
    });

    const {
        open: debugDialogOpen,
        json: debugDialogJSON,
        handleCloseDebugDialog,
        handleDebugDialog
    } = useCustomDebugDialog();

    const {
        callbackFunc,
        message: confirmMessage,
        warning: confirmWarning,
        open: confirmDialogOpen,
        enableConfirm,
        confirmString,
        confirmStringComparisonValue,
        handleCloseConfirmDialog,
        handleConfirmAction,
        handleChangeString
    } = useConfirmDialog();

    const { fetchProbe } = useFetchProbe({ dispatch, handleSnackbar, handleLang });

    const clearHistory = () => {
        setDebugHistory([]);
    };

    const getEventSources = async () => {
        let res = await http.getJSON('/logs/eventSources');

        if (res.ok) {
            const eventSources = _.get(res, 'data.eventSources', []);

            const logEvent = {
                type: 'event-sources',
                size: 0,
                text: JSON.stringify(eventSources)
            };

            setDebugHistory(debugHistory => [...debugHistory, logEvent]);
        }
    };

    useEffect(() => {
        let themeType = localStorage.getItem('theme');
        if (themeType === 'dark') {
            document.body.style.backgroundColor = darkTheme.palette.background.default;
            setTheme(darkTheme);
        } else {
            document.body.style.backgroundColor = lightTheme.palette.background.default;
            setTheme(lightTheme);
        }
    }, [lightTheme, darkTheme, adminTheme]);

    useEffect(() => {
        fetchProbe();
        // Set theme from localStorage:

        (async function getThemeColors() {
            let res = await http.getJSON('/themeConfig');
            if (res.ok) {
                let lightThemeCopy = _.cloneDeep(lightTheme);
                let darkThemeCopy = _.cloneDeep(darkTheme);
                let adminThemeCopy = _.cloneDeep(adminTheme);
                const keys = [
                    '50',
                    '100',
                    '200',
                    '300',
                    '400',
                    '500',
                    '600',
                    '700',
                    '800',
                    '900',
                    'A100',
                    'A200',
                    'A400',
                    'A700',
                    'main',
                    'light',
                    'dark'
                ];
                let charityPinColor = _.get(res.data, 'theme.charityPin', markerPinTheme.charityPin);
                let pinLabelStyle = _.get(res.data, 'theme.pinLabelStyle', markerPinTheme.pinLabelStyle);
                for (let key of keys) {
                    // set light theme values
                    lightThemeCopy.palette.primary[key] = _.get(
                        res.data,
                        'theme.light.primary',
                        lightThemeCopy.palette.primary[key]
                    );
                    lightThemeCopy.palette.secondary[key] = _.get(
                        res.data,
                        'theme.light.secondary',
                        lightThemeCopy.palette.secondary[key]
                    );

                    // set dark theme values
                    darkThemeCopy.palette.primary[key] = _.get(
                        res.data,
                        'theme.dark.primary',
                        darkThemeCopy.palette.primary[key]
                    );
                    darkThemeCopy.palette.secondary[key] = _.get(
                        res.data,
                        'theme.dark.secondary',
                        darkThemeCopy.palette.secondary[key]
                    );

                    // set admin theme values
                    adminThemeCopy.palette.primary[key] = _.get(
                        res.data,
                        'theme.admin.primary',
                        adminThemeCopy.palette.primary[key]
                    );
                    adminThemeCopy.palette.secondary[key] = _.get(
                        res.data,
                        'theme.admin.secondary',
                        adminThemeCopy.palette.secondary[key]
                    );
                }

                lightThemeCopy.palette.linkColor = lightThemeCopy.palette.primary[500];
                darkThemeCopy.palette.linkColor = darkThemeCopy.palette.primary[300];
                adminThemeCopy.palette.linkColor = adminThemeCopy.palette.primary;

                lightThemeCopy.palette.charityPin = charityPinColor;
                darkThemeCopy.palette.charityPin = charityPinColor;
                adminThemeCopy.palette.charityPin = charityPinColor;

                lightThemeCopy.palette.pinLabelStyle = pinLabelStyle;
                darkThemeCopy.palette.pinLabelStyle = pinLabelStyle;
                adminThemeCopy.palette.pinLabelStyle = pinLabelStyle;

                setLightTheme(lightThemeCopy);
                setDarkTheme(darkThemeCopy);
                setAdminTheme(adminThemeCopy);
            }
            let themeType = localStorage.getItem('theme');
            if (themeType === 'dark') {
                document.body.style.backgroundColor = darkTheme.palette.background.default;
                setTheme(darkTheme);
            } else {
                document.body.style.backgroundColor = lightTheme.palette.background.default;
                setTheme(lightTheme);
            }
        })();
        if (isProductionEnv && !_.isNil(window.fbq)) {
            window.fbq('track', 'Lead');
        }
        (async function loadBrandLogoConfig() {
            let res = await http.getJSON('/getBrandConfig');
            if (res.ok) {
                setBrandLogoOverride(res.data.brandLogo);
            }
        })();
        (async function loadServerConfig() {
            setLoadingServerInfo(true);

            let res = await http.getJSON('/getServerConfig');
            if (res.ok) {
                const showThirdPartyLogins = _.get(res, 'data.showThirdPartyLogins', true);
                const resServerVersion = _.get(res, 'data.serverVersion', 'legacy');
                const forcePatchUpdate = _.get(res, 'data.forcePatchUpdate', false);
                const disableRefreshCheck = _.get(res, 'data.disableRefreshCheck', false);
                const disableUpdateCheck = _.get(res, 'data.disableUpdateCheck', false);
                const disableMaintenanceCheck = _.get(res, 'data.disableMaintenanceCheck', false);
                const timeoutConfig = _.get(res, 'data.idleLogoutTimeoutConfig', {});
                const registrationConfig = _.get(res, 'data.registrationConfig', {});

                // Refresh
                const patchRequiresRefresh = changeRequiresRefresh(resServerVersion, VERSION_FRONT);

                // Forced Update
                const minorRequiresUpdate = breakingChangeRequiresUpdate(resServerVersion, VERSION_FRONT);
                const patchRequiresUpdate = forcePatchUpdate && patchCanUpdate(resServerVersion, VERSION_FRONT);

                // Server maintenance
                const minorRequiresMaintenance = breakingChangeAwaitingServer(resServerVersion, VERSION_FRONT);

                const refreshReq = !disableRefreshCheck && patchRequiresRefresh;
                const updateReq = !disableUpdateCheck && (patchRequiresUpdate || minorRequiresUpdate);
                const serverMaintenanceReq = !disableMaintenanceCheck && minorRequiresMaintenance;

                // setServerVersion(resServerVersion);

                setIdleLogoutTimeoutConfig(timeoutConfig);
                setRegistrationConfig(registrationConfig);

                setShowThirdPartyLogins(showThirdPartyLogins);

                setRefreshRequired(refreshReq);
                setUpdateRequired(updateReq);
                setShowRefreshDialog(refreshReq || updateReq);
                setServerMaintenance(serverMaintenanceReq);
            } else {
                // setUpdateRequired(true);
                // setServerVersion('legacy');
            }

            setLoadingServerInfo(false);
        })();

        (async function checkEnabledFunctions() {
            let res = await http.getJSON('/getEnabledFunctions');
            if (res.ok) {
                setPickupsEnabled(_.get(res.data, 'pickupsEnabled', true));
                setPromosEnabled(_.get(res.data, 'promosEnabled', true));
                setCharityEnabled(_.get(res.data, 'charityEnabled', true));
                setScanQREnabled(_.get(res.data, 'scanQREnabled', false));
                setShowQREnabled(_.get(res.data, 'showQREnabled', false));
            }
        })();

        (async function getHelpCenterFunctions() {
            let res = await http.getJSON('/getHelpCenterFunctions');
            if (res.ok) {
                setHelpCenterFunctions(
                    _.get(res.data, 'helpCenterFunctions', {
                        showHowItWorks: true,
                        showFaq: true,
                        showContactUs: true
                    })
                );
                setShowZohoHelpDesk(_.get(res.data, 'showZohoHelpDesk', false));
            }
        })();

        //try to avoid calling new google.maps.Map() alot as it counts towards our quota for map loads
        setPlacesService(new google.maps.places.PlacesService(new google.maps.Map(document.createElement('div'))));
    }, []); // leave this array empty so it runs only when the component is mounted

    useEffect(() => {
        if (!DEBUG) return;
        console.log('%cTheme: ', 'color: purple; font-weight: bold;', theme);
    }, [theme]);

    useEffect(() => {
        if (!DEBUG) return;
        console.log('%cAuth: ', 'color: purple; font-weight: bold;', auth);
    }, [auth]);

    useEffect(() => {
        if (!DEBUG) return;
        console.log('%cWindow: ', 'color: orange; font-weight: bold;', window);
    }, [window]);

    const handleNightModeToggle = () => {
        if (theme === lightTheme) {
            document.body.style.backgroundColor = darkTheme.palette.background.default;
            localStorage.setItem('theme', 'dark');
            setTheme(darkTheme);
        } else {
            document.body.style.backgroundColor = lightTheme.palette.background.default;
            localStorage.setItem('theme', 'light');
            setTheme(lightTheme);
        }
    };

    const handleAdminTheme = state => {
        if (state) {
            document.body.style.backgroundColor = adminTheme.palette.background.default;
            setTheme(adminTheme);
        } else {
            let themeType = localStorage.getItem('theme'); //get previously used theme
            if (themeType === 'dark') {
                document.body.style.backgroundColor = darkTheme.palette.background.default;
                setTheme(darkTheme);
            } else {
                document.body.style.backgroundColor = lightTheme.palette.background.default;
                setTheme(lightTheme);
            }
        }
    };

    useEffect(() => {
        try {
            SplashScreen.hide();
        } catch (err) {}
    }, []);

    const googleContextValue = useMemo(() => ({ google, placesService }), [google, placesService]);

    if (_.isNil(auth.isAuthenticated) || loadingServerInfo) {
        // Users will get stuck here if no internet connection
        return <LoadingScreen color={theme.palette.primary.main} />;
    }

    if (auth.isAuthenticated && updateRequired) {
        return <UpdateRequiredDialog open={true} disabling={true} />;
    }

    if (auth.isAuthenticated && serverMaintenance) {
        return <ServerMaintenanceDialog open={true} />;
    }

    if (auth.isAuthenticated) {
        return (
            <MuiThemeProvider theme={theme}>
                <HttpProvider value={http}>
                    <DebugProvider value={handleDebugDialog}>
                        <ConfirmDialogProvider value={handleConfirmAction}>
                            <SnackbarProvider value={handleSnackbar}>
                                <GoogleProvider value={googleContextValue}>
                                    <Main
                                        onLoginMessage={onLoginMessage}
                                        onLoginMessageVariant={onLoginMessageVariant}
                                        google={google}
                                        theme={theme}
                                        http={http}
                                        auth={auth}
                                        dispatch={dispatch}
                                        toggleNightMode={handleNightModeToggle}
                                        onSnackbar={handleSnackbar}
                                        onAdminTheme={handleAdminTheme}
                                        refreshRequired={refreshRequired}
                                        showThirdPartyLogins={showThirdPartyLogins}
                                        brandLogoOverride={brandLogoOverride}
                                        promosEnabled={promosEnabled}
                                        pickupsEnabled={pickupsEnabled}
                                        charityEnabled={charityEnabled}
                                        idleLogoutTimeoutConfig={idleLogoutTimeoutConfig}
                                        onShowIdleLogoutDialog={() => setIdleLogoutDialogOpen(true)}
                                        scanQREnabled={scanQREnabled}
                                        showQREnabled={showQREnabled}
                                        registrationConfig={registrationConfig}
                                        helpCenterFunctions={helpCenterFunctions}
                                        darkTheme={darkTheme}
                                        lightTheme={lightTheme}
                                        setTheme={setTheme}
                                    />
                                </GoogleProvider>
                            </SnackbarProvider>
                        </ConfirmDialogProvider>
                    </DebugProvider>
                </HttpProvider>

                <ConfirmDialog
                    open={confirmDialogOpen}
                    message={confirmMessage}
                    warning={confirmWarning}
                    confirmString={confirmString}
                    confirmStringComparisonValue={confirmStringComparisonValue}
                    enableConfirm={enableConfirm}
                    callbackFunc={callbackFunc}
                    onCloseConfirmDialog={handleCloseConfirmDialog}
                    onChangeString={handleChangeString}
                />

                <HttpErrorDialog
                    open={httpErrorDialogOpen}
                    errorType={httpErrorType}
                    onClose={handleCloseHttpErrorDialog}
                    oversizedFileSize={oversizedFileSize}
                />

                <CustomSnackbar
                    open={snackbarOpen}
                    variant={snackbarVariant}
                    message={snackbarMessage}
                    duration={snackbarDuration}
                    onClose={() => handleSnackbar()}
                />
                <CustomDebugDialog open={debugDialogOpen} json={debugDialogJSON} onClose={handleCloseDebugDialog} />
                <HttpProvider value={http}>
                    <DebugOverlay
                        bytesReceived={bytesReceived}
                        bytesSent={bytesSent}
                        debugHistory={debugHistory}
                        getEventSources={getEventSources}
                        clearHistory={clearHistory}
                    />
                </HttpProvider>
                <ZohoWidget show={showZohoHelpDesk} auth={auth} />
            </MuiThemeProvider>
        );
    }

    if (!auth.isAuthenticated && serverMaintenance) {
        // Do nothing for now
    }

    return (
        <MuiThemeProvider theme={theme}>
            <HttpProvider value={http}>
                <DebugProvider value={handleDebugDialog}>
                    <ConfirmDialogProvider value={handleConfirmAction}>
                        <SnackbarProvider value={handleSnackbar}>
                            <GoogleProvider value={googleContextValue}>
                                <Switch>
                                    <Route
                                        exact
                                        path="/login"
                                        render={props => (
                                            <Login
                                                {...props}
                                                http={http}
                                                theme={theme}
                                                auth={auth}
                                                dispatch={dispatch}
                                                setLang={handleLang}
                                                onSnackbar={handleSnackbar}
                                                setOnLoginMessage={setOnLoginMessage}
                                                setOnLoginMessageVariant={setOnLoginMessageVariant}
                                                useFallbackData={updateRequired}
                                                showThirdPartyLogins={showThirdPartyLogins}
                                                brandLogoOverride={brandLogoOverride}
                                                helpCenterFunctions={helpCenterFunctions}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/:collectorID/login"
                                        render={props => (
                                            <DepotLogin {...props} theme={theme} dispatch={dispatch} auth={auth} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/register"
                                        render={props => (
                                            <Registration
                                                {...props}
                                                http={http}
                                                google={google}
                                                theme={theme}
                                                dispatch={dispatch}
                                                onSnackbar={handleSnackbar}
                                                useFallbackData={updateRequired}
                                                showThirdPartyLogins={showThirdPartyLogins}
                                                brandLogoOverride={brandLogoOverride}
                                                promosEnabled={promosEnabled}
                                                charityEnabled={charityEnabled}
                                                registrationConfig={registrationConfig}
                                                helpCenterFunctions={helpCenterFunctions}
                                                auth={auth}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/terms"
                                        render={props => (
                                            <Terms
                                                {...props}
                                                theme={theme}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                useFallbackData={updateRequired}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/privacy"
                                        render={props => (
                                            <Privacy
                                                {...props}
                                                theme={theme}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                useFallbackData={updateRequired}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/rates"
                                        render={props => (
                                            <Rates
                                                {...props}
                                                theme={theme}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                useFallbackData={updateRequired}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/verify/:email/:token"
                                        render={props => (
                                            <Verification
                                                {...props}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/changeEmail/:_id/:email/:token"
                                        render={props => (
                                            <ChangeEmail
                                                {...props}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/authorize/:_id/:email/:token"
                                        render={props => (
                                            <Authorization
                                                {...props}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/confirm/:pickupID"
                                        render={props => (
                                            <ConfirmPickup
                                                {...props}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/reset/:id/:token"
                                        render={props => (
                                            <PasswordReset
                                                {...props}
                                                http={http}
                                                onSnackbar={handleSnackbar}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    {scanQREnabled && (
                                        <Route
                                            exact
                                            path="/qs/:token"
                                            render={props => (
                                                <DownloadApp
                                                    {...props}
                                                    brandLogoOverride={brandLogoOverride}
                                                    auth={auth}
                                                />
                                            )}
                                        />
                                    )}
                                    <Route
                                        exact
                                        path="/:url"
                                        render={props => (
                                            <ReferralRedirect
                                                {...props}
                                                http={http}
                                                lang={lang}
                                                theme={theme}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/:url/:subdivision"
                                        render={props => (
                                            <ReferralRedirect
                                                {...props}
                                                http={http}
                                                lang={lang}
                                                theme={theme}
                                                brandLogoOverride={brandLogoOverride}
                                            />
                                        )}
                                    />
                                    <Redirect to={isEXPRegion() ? '/login' : '/register'} />
                                </Switch>
                            </GoogleProvider>
                        </SnackbarProvider>
                    </ConfirmDialogProvider>
                </DebugProvider>
            </HttpProvider>

            <HttpErrorDialog
                open={httpErrorDialogOpen}
                errorType={httpErrorType}
                onClose={handleCloseHttpErrorDialog}
                oversizedFileSize={oversizedFileSize}
            />

            <GenericDialog
                open={idleLogoutDialogOpen}
                onClose={() => setIdleLogoutDialogOpen(false)}
                title={loc('loggedOut', lang)}
                message={loc('inactiveLogOut', lang)}
            />

            <CustomSnackbar
                open={snackbarOpen}
                variant={snackbarVariant}
                message={snackbarMessage}
                duration={snackbarDuration}
                onClose={() => handleSnackbar()}
            />

            <ConfirmDialog
                open={confirmDialogOpen}
                message={confirmMessage}
                warning={confirmWarning}
                confirmString={confirmString}
                confirmStringComparisonValue={confirmStringComparisonValue}
                enableConfirm={enableConfirm}
                callbackFunc={callbackFunc}
                onCloseConfirmDialog={handleCloseConfirmDialog}
                onChangeString={handleChangeString}
            />
            <CustomDebugDialog open={debugDialogOpen} json={debugDialogJSON} onClose={handleCloseDebugDialog} />
            <UpdateRequiredDialog
                open={showRefreshDialog}
                disabling={false}
                onClose={() => setShowRefreshDialog(false)}
            />
            <ZohoWidget show={showZohoHelpDesk} auth={auth} />
        </MuiThemeProvider>
    );
};

const LocalizedApp = () => {
    let localStorageLang = window.localStorage.getItem('lang');
    if (
        _.isNil(localStorageLang) ||
        _.isEmpty(localStorageLang) ||
        !AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].includes(localStorageLang)
    ) {
        localStorageLang = DEFAULT_LANG;
    }
    const [lang, setLang] = useState(localStorageLang);
    const handleLang = lang => {
        if (!AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT].includes(lang)) {
            return;
        }

        window.localStorage.setItem('lang', lang);
        setLang(lang);
    };
    const AppWithGoogleApi = useCallback(
        GoogleApiWrapper({
            apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
            libraries: ['places'],
            language: lang
        })(App),
        [lang]
    );

    return (
        <LocalizationProvider value={{ lang, setLang: handleLang }}>
            <AppWithGoogleApi handleLang={handleLang} />
        </LocalizationProvider>
    );
};

export default LocalizedApp;

const ZohoWidget = ({ show, auth }) => {
    // This is pretty hacky but options are very limited for handling this situation
    // Most reliable alternative is to move zoho widget wherever it's actually used (which is everywhere but operator site) but its very messy
    // This is also not future proof as the script may load a component with a different id in the future...

    const renderWidget = () => {
        const doc = document.getElementById('zohohc-asap-web-helper-main');
        if (doc) {
            doc.style.display = '';
            return;
        }

        const script = document.createElement('script');
        script.src = 'https://desk.zoho.com/portal/api/web/inapp/593559000008635023?orgId=746931224';
        script.async = true;

        document.head.appendChild(script);
    };

    const removeWidget = () => {
        const doc = document.getElementById('zohohc-asap-web-helper-main');
        if (!doc) {
            return;
        }

        doc.style.display = 'none';
    };

    useEffect(() => {
        if (!show || _.isNil(auth.isAuthenticated)) {
            removeWidget();
            return;
        }
        if (auth.isAuthenticated && auth.accountType != 'Customer') {
            removeWidget();
            return;
        }

        renderWidget();

        return () => {
            removeWidget();
        };
    }, [show, auth]);

    return <></>;
};
