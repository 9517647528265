import React, { useContext } from 'react';
import _ from 'lodash';

import { colors, Typography, withTheme } from '@material-ui/core';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';
import { _commodity } from 'std';

import { getPlural } from '../../utils/misc';

function DetailedPayloadIndicators({
    pickups,
    commodities,
    indicatorSize = 1,
    showUnits = false,
    style,
    labelStyle,
    theme,
    lang = 'en'
}) {
    const payloadItems = _commodity.getCombinedPayloadItemsForPickups(pickups, commodities, lang);

    return (
        <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', flexWrap: 'wrap', ...style }}>
            {payloadItems.map(payloadItem => {
                const skuType = payloadItem.skuType;
                const count = payloadItem.amount;
                const unit = payloadItem.unit;

                if (!skuType || !count || !unit) return <></>;

                let commodityIcon = _.get(payloadItem, 'iconName', '');
                let isMDIcon = commodityIcon.startsWith('mdi');
                if (!isMDIcon) {
                    commodityIcon = _.camelCase(`mdi-${commodityIcon}`);
                }

                return (
                    <span
                        key={skuType}
                        style={{
                            display: 'flex',
                            borderRadius: 4,
                            borderColor: colors.blue[300],
                            alignItems: 'center',
                            marginRight: theme.spacing.unit / 2
                        }}
                    >
                        <MDIcon
                            path={_.get(allIcons, commodityIcon)}
                            size={indicatorSize}
                            color={_.get(payloadItem, 'color')}
                            style={{ marginRight: theme.spacing.unit / 2}}
                        />{' '}
                        <Typography data-cy={`pickup-info-payload-${payloadItem.skuType}`} style={{ ...labelStyle }}>
                            {count} {showUnits && <>{count > 1 ? getPlural(unit) : unit}</>}
                        </Typography>
                    </span>
                );
            })}
        </div>
    );
}

export default withTheme()(DetailedPayloadIndicators);
