import React, { useState, useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';

import { MenuItem, TextField, colors, withMobileDialog, withTheme } from '@material-ui/core';

import { _bulk, _commodity, _user } from 'std';

import NumberOfBagsInput from 'components/InputComponents/NumberOfBagsInput';

import * as allIcons from '@mdi/js';

import { MAX_NUM_OF_RECYCLING_BAGS, MIN_NUM_OF_RECYCLING_BAGS } from 'constants.js';

import OperatorContext from 'utils/contexts/OperatorContext';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Icon,
    Typography,
    FormControl
} from '@material-ui/core';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import ImageViewer from 'components/ImageUploads/ImageViewer';
import { Icon as MDIcon } from '@mdi/react';
import useWindowSize from 'utils/hooks/useWindowSize';

function NumberOfBagsDialog({
    theme,
    fullScreen,
    open,
    bulk,
    commodities,
    collector,
    setImages,
    onClose,
    onBack,
    onUpdateCommodityProcessed,
    onUpdateCommodityAmount,
    onImageDialog,
    bagsToProcess,
    setBagsToProcess,
    disclaimer,
    adjustmentConfig,
    handleUpdateCommoditesProccessedBreakdown,
    handleUpdateAdjustmentReason,
    addEmptySession,
    http
}) {
    const [width, height] = useWindowSize();
    let mobileMode = width <= 620;
    const operator = useContext(OperatorContext);
    const PORDefaultSort = _.get(operator, 'collector.PORDefaultSort', 'Nothing');
    const { lang } = useContext(LocalizationContext);

    const comodityTotalAmount = _.get(bulk, 'commodityAmount', 0);
    const commodityAmountRemaining = comodityTotalAmount - _.get(bulk, 'commoditiesProcessed', 0);
    const bulkHasNoBags = comodityTotalAmount === 0;

    const [allRenderList, setAllRenderList] = useState([]);
    const [remainingRenderList, setRemainingRenderList] = useState([]);
    const [sortingCommodityList, setSortingCommodityList] = useState([]);
    const [updatedCommoditiesProcessedBreakdown, setUpdatedCommoditiesProcessedBreakdown] = useState([]);
    const [showAllSubCom, setShowAllSubCom] = useState(false);
    const [adjustmentReason, setAdjustmentReason] = useState(_.get(bulk, 'adjustmentReason', ''));
    const [editHistory, setEditHistory] = useState([]);

    const adjustmentReasons = _.get(adjustmentConfig, 'reasons', []);

    useEffect(() => {
        if (bulk === undefined || commodities === undefined || commodities.length < 1) return;
        initialFunctions();
    }, [commodities, bulk]);

    async function initialFunctions() {
        let numOfSubCom = getComEquivalent(bulk, commodities, true);
        let numOfBags = _.get(bulk, 'commodityAmount', 0) - numOfSubCom;
        setAllRenderList(getAllRenderList(numOfBags));
        setRemainingRenderList(getRemainingRenderList(numOfBags));
        let newSortingRenderList = await getSortingRenderList(numOfBags);
        setSortingCommodityList(newSortingRenderList);
        initCommodityProccessedBreakdown(newSortingRenderList);
        setShowAllSubCom(true);
        addAllSubCom(newSortingRenderList);
    }

    const commodityForBulk = _.find(commodities, { skuType: _.get(bulk, 'skuType') });
    const bulkImages = _bulk.getPickupOrDropOffPhotoURL(bulk || {});
    const sortedBulkImages = _bulk.sortBulkImages(bulkImages, _.get(bulk, 'skuType'));

    let error = '';

    if (_.isNaN(bagsToProcess) || bagsToProcess < 0) {
        error = 'Invalid number of bags';
    }

    const handleBack = () => {
        onBack();
    };
    function getAllRenderList(numOfBags) {
        const currentSkuCom = _.find(commodities, {
            skuType: _.get(bulk, 'skuType', '')
        });
        let commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
        let sortedBags = _.get(_.find(commoditiesProcessedBreakdown, { isSubCommodity: false }), 'amount', 0);
        let processedSubComList = _.filter(commoditiesProcessedBreakdown, { isSubCommodity: true });
        let renderList = [];
        if (numOfBags > 0) {
            renderList.push({
                color: _.get(currentSkuCom, 'color', colors.blue[300]),
                icon: allIcons[_.get(currentSkuCom, 'iconName', '')],
                amount: Math.max(sortedBags, numOfBags),
                name: shortenSubComName(_.get(bulk, 'commodityUOM', 'bag'), numOfBags)
            });
        }

        let combinedProcessedSubComList = {};

        _.get(bulk, 'pickup.subPayloads', [])
            .filter(subPayload => subPayload.skuType === _.get(bulk, 'skuType', ''))
            .forEach(subCom => {
                let id = _.get(subCom, 'subCommodity', '');
                let amount = _.get(subCom, 'amount', 0);
                if (amount > 0) combinedProcessedSubComList[id] = subCom;
            });
        processedSubComList.forEach(subCom => {
            let id = _.get(subCom, 'subCommodity', '');
            let amount = _.get(subCom, 'amount', 0);
            if (amount > 0) {
                if (combinedProcessedSubComList[id] === undefined) {
                    combinedProcessedSubComList[id] = subCom;
                } else {
                    combinedProcessedSubComList[id].amount = Math.max(
                        combinedProcessedSubComList[id].amount,
                        _.get(subCom, 'amount', 0)
                    );
                }
            }
        });
        Object.values(combinedProcessedSubComList).forEach(subCom => {
            let sortedSubCom = _.get(
                _.find(processedSubComList, { subCommodity: _.get(subCom, 'subCommodity', '') }),
                'amount',
                0
            );
            let amount = _.get(subCom, 'amount', 0);
            let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
            let subCommodity = _.find(subCommodityLookup, { _id: _.get(subCom, 'subCommodity', '') });
            let name = _.get(subCommodity, `name.${lang}`, '');
            renderList.push({
                color: _.get(subCommodity, 'color', colors.blue[300]),
                icon: allIcons[convertToImportName(_.get(subCommodity, 'iconName', ''))],
                amount: Math.max(sortedSubCom, amount),
                name: shortenSubComName(name, amount)
            });
        });
        return renderList;
    }
    function getRemainingRenderList(numOfBags) {
        const currentSkuCom = _.find(commodities, {
            skuType: _.get(bulk, 'skuType', '')
        });
        let commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
        let sortedBags = _.get(_.find(commoditiesProcessedBreakdown, { isSubCommodity: false }), 'amount', 0);
        let processedSubComList = _.filter(commoditiesProcessedBreakdown, { isSubCommodity: true });
        let renderList = [];
        if (numOfBags - sortedBags > 0) {
            renderList.push({
                color: _.get(currentSkuCom, 'color', colors.blue[300]),
                icon: allIcons[_.get(currentSkuCom, 'iconName', '')],
                amount: numOfBags - sortedBags,
                name: shortenSubComName(_.get(bulk, 'commodityUOM', 'bag'), numOfBags - sortedBags)
            });
        }
        _.get(bulk, 'pickup.subPayloads', [])
            .filter(subPayload => subPayload.skuType === _.get(bulk, 'skuType', ''))
            .forEach(subCom => {
                let subComId = _.get(subCom, 'subCommodity', '');
                let amount = _.get(subCom, 'amount', '');
                let remainingSubCom =
                    amount - _.get(_.find(processedSubComList, { subCommodity: subComId }), 'amount', '');
                if (remainingSubCom < 1) return;
                let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
                let subCommodity = _.find(subCommodityLookup, subComLookupItem => {
                    return subComLookupItem._id === subComId;
                });
                let name = _.get(subCommodity, `name.${lang}`, '');
                renderList.push({
                    color: _.get(subCommodity, 'color', colors.blue[300]),
                    icon: allIcons[convertToImportName(_.get(subCommodity, 'iconName', ''))],
                    amount: remainingSubCom,
                    name: shortenSubComName(name, remainingSubCom)
                });
            });
        return renderList;
    }

    async function getSortingRenderList(totalNumOfBags) {
        const originalCommoditiesProcessedBreakdown = await getCommoditiesProcessedBreakdown();
        const currentSkuCom = _.find(commodities, {
            skuType: _.get(bulk, 'skuType', '')
        });
        let sortedBags = _.get(_.find(originalCommoditiesProcessedBreakdown, { isSubCommodity: false }), 'amount', 0);
        let remainingBags = totalNumOfBags - sortedBags;
        let processedSubComList = _.filter(originalCommoditiesProcessedBreakdown, { isSubCommodity: true });
        let renderList = [];
        if (totalNumOfBags > sortedBags) {
            renderList.push({
                color: _.get(currentSkuCom, 'color', colors.blue[300]),
                icon: allIcons[_.get(currentSkuCom, 'iconName', '')],
                amount: 0,
                name: _.get(bulk, 'commodityUOM', 'bag'),
                totalAmount: remainingBags,
                remainingAmount: Math.max(remainingBags, 0)
            });
        }
        _.get(bulk, 'subPayloads', [])
            .filter(subPayload => subPayload.skuType === _.get(bulk, 'skuType', ''))
            .forEach(subCom => {
                let subComId = _.get(subCom, 'subCommodity', '');
                let totalAmount = _.get(subCom, 'amount', '');
                let remainingAmount =
                    totalAmount - _.get(_.find(processedSubComList, { subCommodity: subComId }), 'amount', 0);
                if (remainingAmount < 1) return;
                let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
                let subCommodity = _.find(subCommodityLookup, { _id: subComId });
                let name = _.get(subCommodity, `name.${lang}`, '');
                renderList.push({
                    color: _.get(subCommodity, 'color', colors.blue[300]),
                    icon: allIcons[convertToImportName(_.get(subCommodity, 'iconName', ''))],
                    amount: 0,
                    name: shortenSubComName(name, remainingAmount),
                    subCommodity: subComId,
                    totalAmount: remainingAmount,
                    remainingAmount: Math.max(remainingAmount, 0)
                });
            });
        return renderList;
    }

    function updateCommodityProccessedBreakdown(subCommodity, amountDelta) {
        let newCommoditiesProcessedBreakdown = _.cloneDeep(updatedCommoditiesProcessedBreakdown);
        let findQuery = subCommodity === null ? { isSubCommodity: false } : { subCommodity: subCommodity };
        let index = _.findIndex(newCommoditiesProcessedBreakdown, findQuery);
        if (index === -1) {
            newCommoditiesProcessedBreakdown.push({
                isSubCommodity: !(subCommodity === null),
                skuType: _.get(bulk, 'skuType'),
                subCommodity: subCommodity,
                amount: Math.max(amountDelta, 0)
            });
        } else {
            if (newCommoditiesProcessedBreakdown[index].amount + amountDelta < 1) {
                newCommoditiesProcessedBreakdown.splice(index, 1);
            } else {
                newCommoditiesProcessedBreakdown[index].amount =
                    newCommoditiesProcessedBreakdown[index].amount + amountDelta;
            }
        }
        setUpdatedCommoditiesProcessedBreakdown(newCommoditiesProcessedBreakdown);
    }

    function maxAllCommodity() {
        let newCommoditiesProcessedBreakdown = _.cloneDeep(updatedCommoditiesProcessedBreakdown);
        let newEditHistory = _.cloneDeep(editHistory);
        let newEditHistoryactions = [];
        sortingCommodityList.forEach(renderItem => {
            let subCommodityId = _.get(renderItem, 'subCommodity', null);
            let totalAmount = _.get(renderItem, 'totalAmount', 0);
            let findQuery = subCommodityId === null ? { isSubCommodity: false } : { subCommodity: subCommodityId };
            let index = _.findIndex(newCommoditiesProcessedBreakdown, findQuery);

            if (index === -1 && totalAmount > 0) {
                newCommoditiesProcessedBreakdown.push({
                    isSubCommodity: !(subCommodityId === null),
                    skuType: _.get(bulk, 'skuType'),
                    subCommodity: subCommodityId,
                    amount: totalAmount
                });

                let newEditHistoryItem = { amount: totalAmount };
                if (!_.isNil(subCommodityId)) newEditHistoryItem.subCommodity = subCommodityId;
                newEditHistoryactions.push(newEditHistoryItem);
            } else if (totalAmount > 0 && newCommoditiesProcessedBreakdown[index].amount < totalAmount) {
                let newEditHistoryItem = { amount: totalAmount - newCommoditiesProcessedBreakdown[index].amount };
                newCommoditiesProcessedBreakdown[index].amount = totalAmount;
                if (!_.isNil(subCommodityId)) newEditHistoryItem.subCommodity = subCommodityId;
                newEditHistoryactions.push(newEditHistoryItem);
            }
        });
        setUpdatedCommoditiesProcessedBreakdown(newCommoditiesProcessedBreakdown);

        if (newEditHistoryactions.length > 0) newEditHistory.push(newEditHistoryactions);
        setEditHistory(newEditHistory);
    }

    function clearAllCommodity() {
        setUpdatedCommoditiesProcessedBreakdown([]);
        let newEditHistory = _.cloneDeep(editHistory);
        let newEditHistoryactions = [];
        updatedCommoditiesProcessedBreakdown.forEach(commodity => {
            let subComId = _.get(commodity, 'subCommodity', null);
            let commodityAmount = _.get(commodity, 'amount', null);
            let newEditHistoryItem = { amount: commodityAmount * -1 };
            if (!_.isNil(subComId)) newEditHistoryItem.subCommodity = subComId;
            if (commodityAmount > 0) newEditHistoryactions.push(newEditHistoryItem);
        });
        if (newEditHistoryactions.length > 0) newEditHistory.push(newEditHistoryactions);
        setEditHistory(newEditHistory);
    }

    function undoCommodityEdit() {
        let newCommoditiesProcessedBreakdown = _.cloneDeep(updatedCommoditiesProcessedBreakdown);
        let newEditHistory = _.cloneDeep(editHistory);
        let lastActions = newEditHistory.pop();
        setEditHistory(newEditHistory);
        lastActions.forEach(action => {
            let subCommodity = _.get(action, 'subCommodity', null);
            let amountDelta = _.get(action, 'amount', 0) * -1;
            let findQuery = subCommodity === null ? { isSubCommodity: false } : { subCommodity: subCommodity };
            let index = _.findIndex(newCommoditiesProcessedBreakdown, findQuery);
            if (index === -1) {
                newCommoditiesProcessedBreakdown.push({
                    isSubCommodity: !(subCommodity === null),
                    skuType: _.get(bulk, 'skuType'),
                    subCommodity: subCommodity,
                    amount: Math.max(amountDelta, 0)
                });
            } else {
                if (newCommoditiesProcessedBreakdown[index].amount + amountDelta < 1) {
                    newCommoditiesProcessedBreakdown.splice(index, 1);
                } else {
                    newCommoditiesProcessedBreakdown[index].amount =
                        newCommoditiesProcessedBreakdown[index].amount + amountDelta;
                }
            }
        });
        setUpdatedCommoditiesProcessedBreakdown(newCommoditiesProcessedBreakdown);
    }

    function initCommodityProccessedBreakdown(renderList) {
        let newCommoditiesProcessedBreakdown = [];
        let sortedFirst = false;
        renderList.forEach(item => {
            let subCommodity = _.get(item, 'subCommodity', null);
            let amount = _.get(item, 'amount', 0);
            let totalAmount = _.get(item, 'totalAmount', 0);
            if (PORDefaultSort === 'First Items' && !sortedFirst && totalAmount > 0) {
                sortedFirst = true;
                amount = 1;
            } else if (PORDefaultSort === 'All Items') {
                amount = totalAmount;
            }
            if (amount === 0) return;
            if (subCommodity === null) {
                newCommoditiesProcessedBreakdown.push({
                    isSubCommodity: !(subCommodity === null),
                    skuType: _.get(bulk, 'skuType'),
                    subCommodity: subCommodity,
                    amount: amount,
                    totalAmount: totalAmount
                });
            } else {
                newCommoditiesProcessedBreakdown.push({
                    isSubCommodity: !(subCommodity === null),
                    skuType: _.get(bulk, 'skuType'),
                    subCommodity: subCommodity,
                    amount: amount,
                    totalAmount: totalAmount
                });
            }
        });
        setUpdatedCommoditiesProcessedBreakdown(newCommoditiesProcessedBreakdown);
    }
    function addAllSubCom(sortingRenderList) {
        const currentSkuCom = _.find(commodities, {
            skuType: _.get(bulk, 'skuType', '')
        });
        let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
        let newSortingRenderList = _.cloneDeep(sortingRenderList);

        let bagIndex = _.findIndex(newSortingRenderList, item => _.get(item, 'subCommodity', null) === null);
        if (bagIndex === -1) {
            newSortingRenderList.push({
                color: _.get(currentSkuCom, 'color', colors.blue[300]),
                icon: allIcons[_.get(currentSkuCom, 'iconName', '')],
                amount: 0,
                name: _.get(bulk, 'commodityUOM', 'bag'),
                totalAmount: 0
            });
        }
        subCommodityLookup.forEach(subCom => {
            let subComId = _.get(subCom, '_id', null);
            if (subComId !== null) {
                let index = _.findIndex(newSortingRenderList, { subCommodity: subComId });
                if (index === -1) {
                    newSortingRenderList.push({
                        color: _.get(subCom, 'color', colors.blue[300]),
                        icon: allIcons[convertToImportName(_.get(subCom, 'iconName', ''))],
                        amount: 0,
                        name: shortenSubComName(_.get(subCom, `name.${lang}`, '')),
                        subCommodity: subComId,
                        totalAmount: 0
                    });
                }
            }
        });
        setSortingCommodityList(newSortingRenderList);
        return newSortingRenderList;
    }

    async function getCommoditiesProcessedBreakdown() {
        const res = await http.getJSON(`/bulks/${_.get(bulk, '_id', '')}/getCommoditiesProcessedBreakdown`);
        if (res.ok) {
            return _.get(res, 'data.commoditiesProcessedBreakdown', []);
        }
        return [];
    }

    const imageIcons = useMemo(() => {
        const icons = [];
        _.forEach(sortedBulkImages, imgURL => {
            for (let i = 0; i < commodities.length; i++) {
                const skuType = _.get(commodities[i], `skuType`, null);
                if (skuType !== null) {
                    const lastSlashIndex = imgURL.lastIndexOf('/');
                    const fileName = imgURL.substring(lastSlashIndex, imgURL.length);
                    if (fileName.includes(skuType)) {
                        icons.push({
                            name: _.get(commodities[i], 'iconName', ''),
                            color: _.get(commodities[i], 'color')
                        });
                    }
                }
            }
        });

        return icons;
    }, [sortedBulkImages, commodities]);

    let commodityButtons = sortingCommodityList.map((renderItem, index) => {
        let subComId = _.get(renderItem, 'subCommodity', null);
        let commodityAmount =
            subComId === null
                ? _.get(_.find(updatedCommoditiesProcessedBreakdown, { isSubCommodity: false }), 'amount', 0)
                : _.get(_.find(updatedCommoditiesProcessedBreakdown, { subCommodity: subComId }), 'amount', 0);
        let totalAmount = _.get(renderItem, 'totalAmount', 0);
        if (_.isNaN(commodityAmount) || commodityAmount < 0) {
            commodityAmount = 0;
        }
        let dataName = _.get(renderItem, 'name', '');
        if (dataName.endsWith('s')) dataName = dataName.slice(0, -1);
        return (
            <Button
                style={{
                    backgroundColor:
                        commodityAmount > totalAmount
                            ? colors.red[600]
                            : totalAmount === 0 || commodityAmount === totalAmount
                            ? 'lightgrey'
                            : colors.blue[600],
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingBottom: 2,
                    width: 67
                }}
                variant="contained"
                onClick={() => {
                    updateCommodityProccessedBreakdown(subComId, 1);
                    let newEditHistory = _.cloneDeep(editHistory);
                    let newEditHistoryItem = { amount: 1 };
                    if (!_.isNil(subComId)) newEditHistoryItem.subCommodity = subComId;
                    newEditHistory.push([newEditHistoryItem]);
                    setEditHistory(newEditHistory);
                }}
                data-cy={`bulk-counter-commodity-${dataName}`}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            alignContent: 'center',
                            maxHeight: 20
                        }}
                    >
                        <MDIcon path={_.get(renderItem, 'icon', '')} size={0.8} color={'white'} />
                    </div>
                    <Typography style={{ color: 'white', fontSize: 11 }}>{_.get(renderItem, 'name', '')}</Typography>
                    {(totalAmount > 0 || commodityAmount > 0) && (
                        <Typography style={{ color: 'black', fontSize: 10, fontWeight: 'bold' }}>
                            {commodityAmount > totalAmount
                                ? `${commodityAmount - totalAmount} Extra`
                                : `${totalAmount - commodityAmount} Left`}
                        </Typography>
                    )}
                </div>
            </Button>
        );
    });
    let utilButtons = [
        <Button
            style={{
                backgroundColor: 'black',
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 2,
                width: 67
            }}
            variant="contained"
            onClick={() => {
                maxAllCommodity();
            }}
            data-cy={`bulk-counter-commodity-all`}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        alignContent: 'center',
                        maxHeight: 20
                    }}
                >
                    <Icon style={{ color: 'white' }}>fullscreen</Icon>
                </div>
                <Typography style={{ color: 'white', fontSize: 11 }}>ALL</Typography>
            </div>
        </Button>,
        <Button
            style={{
                backgroundColor: editHistory.length < 1 ? 'lightgrey' : 'black',
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 2,
                width: 67
            }}
            variant="contained"
            disabled={editHistory.length < 1}
            onClick={() => {
                undoCommodityEdit();
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        alignContent: 'center',
                        maxHeight: 20
                    }}
                >
                    <Icon style={{ color: 'white' }}>undo</Icon>
                </div>
                <Typography style={{ color: 'white', fontSize: 11 }}>UNDO</Typography>
            </div>
        </Button>,
        <Button
            style={{
                backgroundColor: 'black',
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 2,
                width: 67
            }}
            variant="contained"
            onClick={() => {
                clearAllCommodity();
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        alignContent: 'center',
                        maxHeight: 20
                    }}
                >
                    <Icon style={{ color: 'white' }}>close</Icon>
                </div>
                <Typography style={{ color: 'white', fontSize: 11 }}>CLEAR</Typography>
            </div>
        </Button>
    ];

    const buttonPlaceHolder = (
        <div
            style={{
                width: 67
            }}
        />
    );

    let buttonList = [...commodityButtons];
    let buttonsPerRow = mobileMode ? 4 : 7;
    let buttonRowCounter = 0;
    let buttonRenderList = [];
    let buttonRow = [];
    for (let i = 0; i < buttonList.length; i++) {
        if (buttonRowCounter < buttonsPerRow) {
            buttonRow.push(buttonList[i]);
            buttonRowCounter++;
        }
        if (buttonRowCounter === buttonsPerRow) {
            buttonRenderList.push(buttonRow);
            buttonRowCounter = 0;
            buttonRow = [];
        }
        if (i === buttonList.length - 1) {
            const remainingSpaces = buttonsPerRow - buttonRowCounter;
            // enough room to fit 3 util buttons, fill current row with placeholders until 3 spaces left and put util buttons at the end
            if (remainingSpaces > 2) {
                for (let j = 0; j < remainingSpaces - 3; j++) {
                    buttonRow.push(buttonPlaceHolder);
                }
                buttonRow = [...buttonRow, ...utilButtons];
                buttonRenderList.push(buttonRow);
                buttonRowCounter = 0;
                buttonRow = [];
            }
            // not enough room to fit 3 util buttons, fill current row with placeholders and put util buttons on next row
            else {
                for (let j = 0; j < remainingSpaces; j++) {
                    buttonRow.push(buttonPlaceHolder);
                }
                buttonRenderList.push(buttonRow);
                buttonRowCounter = 0;
                buttonRow = [];
                if (mobileMode) {
                    buttonRenderList.push([buttonPlaceHolder, ...utilButtons]);
                } else {
                    buttonRenderList.push([
                        buttonPlaceHolder,
                        buttonPlaceHolder,
                        buttonPlaceHolder,
                        buttonPlaceHolder,
                        ...utilButtons
                    ]);
                }
            }
        }
    }

    return (
        <Dialog open={open} maxWidth="sm" fullScreen={fullScreen} fullWidth>
            <DialogTitle style={{ paddingBottom: 8 }}>
                {_bulk.isAdjustment(bulk) ? (
                    <TextField
                        fullWidth
                        select
                        variant="outlined"
                        value={adjustmentReason}
                        onChange={e => setAdjustmentReason(e.target.value)}
                        label="Adjustment Reason"
                        style={{ marginBottom: theme.spacing.unit * 2 }}
                        data-cy="bulk-counter-adjustment-reason-select"
                    >
                        {adjustmentReasons.map((reason, idx) => (
                            <MenuItem data-cy={`bulk-counter-adjustment-reason-select-${idx}`} value={reason}>
                                {reason}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : (
                    <div>
                        BAGTAG: <strong>{_bulk.getTruncatedLabel(bulk)}</strong>
                    </div>
                )}
                {adjustmentReason !== 'Payment Processed' && (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <span style={{ marginRight: 5 }}>What are you sorting?</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {updatedCommoditiesProcessedBreakdown.map((commodity, index) => {
                                let subComId = _.get(commodity, 'subCommodity', null);
                                let commodityAmount = _.get(commodity, 'amount', null);
                                let matchingRenderItem = _.isNil(subComId)
                                    ? sortingCommodityList.find(item => item.subCommodity === undefined)
                                    : sortingCommodityList.find(item => item.subCommodity === subComId);
                                let name = _.get(matchingRenderItem, 'name', '');
                                if (name === 'bag') {
                                    if (commodityAmount > 1) name = 'Bags';
                                    else name = 'Bag';
                                }
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography
                                            style={{
                                                whiteSpace: 'nowrap',
                                                alignSelf: 'center',
                                                justifyContent: 'center',
                                                color: colors.blue[600],
                                                fontSize: 16
                                            }}
                                        >
                                            {index > 0 && ', '}
                                            {commodityAmount}
                                            &nbsp;
                                            {name}
                                        </Typography>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </DialogTitle>

            <DialogContent style={{ paddingBottom: 0 }}>
                {adjustmentReason !== 'Payment Processed' &&
                    buttonRenderList.map(buttonRow => {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginBottom: 10
                                }}
                            >
                                {buttonRow.map(button => button)}
                            </div>
                        );
                    })}

                {!bulkHasNoBags && (
                    <ImageViewer
                        displayImages={sortedBulkImages}
                        disableDelete={true}
                        innerStyles={{ overflow: 'hidden', maxHeight: 200, width: 'auto', cursor: 'pointer' }}
                        scrollerStyles={{ maxWidth: 420, overflowX: 'auto' }}
                        placeholderText={
                            <Typography
                                style={{
                                    color: colors.grey[400]
                                }}
                                variant="subtitle2"
                            >
                                <span>No photo provided</span>
                            </Typography>
                        }
                        onImageClick={() => {
                            if (!_.isEmpty(sortedBulkImages)) {
                                setImages(sortedBulkImages);
                                onImageDialog(true);
                            }
                        }}
                        imageIcons={imageIcons}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={handleBack}>
                    Back
                </Button>
                {(_user.isSystemAdmin(operator) ||
                    _user.isInternalRole(operator) ||
                    _user.isCollectorAdmin(operator) ||
                    _.get(operator, 'adminView', false)) && (
                    <Button color="default" onClick={onClose}>
                        {loc('close', lang)}
                    </Button>
                )}
                <Button
                    data-cy="bulk-counter-bags-submit"
                    color="primary"
                    onClick={async () => {
                        onClose();
                        if (_bulk.isAdjustment(bulk)) {
                            handleUpdateAdjustmentReason(adjustmentReason);
                        }
                        if (adjustmentReason === 'Payment Processed') {
                            if (_.get(bulk, 'countingSessions', []).length === 0) await addEmptySession();
                        } else {
                            await handleUpdateCommoditesProccessedBreakdown(updatedCommoditiesProcessedBreakdown);
                        }
                    }}
                    disabled={!_.isEmpty(error) || (_bulk.isAdjustment(bulk) && _.isEmpty(adjustmentReason))}
                >
                    {loc('continue', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(NumberOfBagsDialog));

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}

// use useSubPayloadsOnBulk = false if you are not having issue with subPayloads mutating between backend and frontend
export const getComEquivalent = (bulk, commodities, useSubPayloadsOnBulk = false) => {
    const subPayloads = useSubPayloadsOnBulk
        ? _.get(bulk, 'subPayloads', []).filter(subPayload => subPayload.skuType === _.get(bulk, 'skuType', ''))
        : _.get(bulk, 'pickup.subPayloads', []).filter(subPayload => subPayload.skuType === _.get(bulk, 'skuType', ''));
    if (_.isEmpty(subPayloads)) return 0;

    const tmpSubPayloads = _.cloneDeep(subPayloads);
    _commodity.populateSubPayloadsSubCommodities(tmpSubPayloads, commodities);

    let commodityUnitEquivalent = 0;
    for (let subPayload of tmpSubPayloads) {
        const amount = subPayload.amount;
        const multiplier = _.get(subPayload, 'subCommodity.multiplier', 0);

        commodityUnitEquivalent += Math.ceil(amount * multiplier);
    }
    return commodityUnitEquivalent;
};

export function shortenSubComName(name, amount) {
    let nameMap = {
        bag: 'Bag',
        'Box/Case (6)': 'Box (6)',
        'Box/Case (12)': 'Box (12)',
        'Box/Case (15)': 'Box (15)',
        'Box/Case (24)': 'Box (24)',
        'Box/Case (30)': 'Box (30)',
        'Large Bin': 'Bin'
    };
    if (nameMap[name] === undefined) return name;
    if (amount > 1) {
        let newName = nameMap[name];
        newName = newName.replace('Bag', 'Bags');
        newName = newName.replace('Box', 'Boxes');
        newName = newName.replace('Bin', 'Bins');
        newName = newName.replace('Item', 'Items');
        return newName;
    }
    return nameMap[name];
}
