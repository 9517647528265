import React, { useState, useRef, useEffect, useReducer, useContext } from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import _ from 'lodash';
import moment from 'moment-timezone';
import queryString from 'query-string';

import {
    profileNotComplete,
    emailIsVerified,
    getQuickDropName,
    uppercaseFirstLetter,
    isEXPRegion,
    groupQuickDropBulks
} from 'utils/misc';

import * as terms from 'localizations/terms';

import SchedulePickupSubwidget from '../Subwidgets/SchedulePickupSubwidget';
import EditPickupSubwidget from '../Subwidgets/EditPickupSubwidget';
import UnservicedPickupSubwidget from '../Subwidgets/UnservicedPickupSubwidget';
import AbortedPickupSubwidget from '../Subwidgets/AbortedPickupSubwidget';
import LastCompletedSubwidget from '../Subwidgets/LastCompletedSubwidget';
import UncountedPickupSubwidget from '../Subwidgets/UncountedPickupSubwidget';
import ReportedPickupSubwidget from '../Subwidgets/ReportedPickupSubwidget';
import SummaryPickupSubdiget from '../Subwidgets/SummaryPickupSubwidget';
import UncountedQuickdropSubwidget from '../Subwidgets/UncountedQuickdropSubwidget';

import UncountedDialog from './Dialogs/UncountedDialog';
import UncountedQuickdropDialog from './Dialogs/UncountedQuickdropDialog';

import CustomerReportDialog from 'components/Dialogs/Customer/CustomerReportDialog';

import TabWrapper from './TabWrapper';

import * as colors from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import { withTheme } from '@material-ui/core/styles';

import pickupHelper from 'helpers/pickupHelper';
import bulkHelper from 'helpers/bulkHelper';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from '../../../localizations/localizationHandler';
import { _bulk, _pickup } from 'std';
import CharitySelectDialog from 'components/CharitySelect/CharitySelectDialog';
import CharityInfoDialog from 'components/CharityInfoDialog/CharityInfoDialog';
import { getClosestRegion } from 'utils/latlngFunctions';

const SLIDE_WIDTH = 280;
const SWIPEABLE_TABS_WIDTH = 400;

// moment.tz.setDefault(process.env.REACT_APP_REGION_TIMEZONE);
// NOTE: dont touch this unless you know what youre doing

function CombinedPickupsWidget(props) {
    const {
        customer,
        history,
        theme,
        elevated,
        pickups,
        charities,
        bulks,
        onPickupDialog,
        assignRef,
        onReloadCustomer,
        onPickupConfirmationDialog,
        charityPreferred,
        http,
        charityEnabled,
        onSnackbar,
        regions
    } = props;

    const { lang } = useContext(LocalizationContext);

    const [uncountedDialogOpen, setUncountedDialogOpen] = useState(false);
    const [customerReportDialogOpen, setCustomerReportDialogOpen] = useState(false);
    const [uncountedQuickdropDialogOpen, setUncountedQuickdropDialogOpen] = useState(false);
    const [daysToCount, setDaysToCount] = useState(0);
    const [charitySelectDialogOpen, setCharitySelectDialogOpen] = useState(false);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [charityInfo, setCharityInfo] = useState('');
    const [region, setRegion] = useState(null);

    const [reportOptions, setReportOptions] = useState([]);
    const loadConfig = async () => {
        try {
            const res = await http.getJSON(`/system/configuration/daysToCount`);
            if (res.ok) {
                setDaysToCount(res.data.daysToCount);
            } else {
                setDaysToCount(2);
            }
        } catch (err) {
            setDaysToCount(2);
        }
    };
    const loadOptions = async () => {
        const res = await http.getJSON(`/system/configuration/clerkComplaintOptions/${lang}`);
        if (res.ok) {
            setReportOptions(res.data.options);
        }
    };
    useEffect(() => {
        loadConfig();
        loadOptions();
        setRegion(getClosestRegion(customer.location, regions));
    }, []);

    const handleCharitySelected = (charity_id, subdivision) => async e => {
        const res = await http.post(`/users/${customer._id}/setCharitySelected`, { charity_id, subdivision });
        if (res.ok) {
            onReloadCustomer();
            setCharitySelectDialogOpen(false);
            onSnackbar(
                loc('customerSnackBar3', lang, {
                    charity: _.get(_.find(charities, c => c._id === charity_id), 'name', 'N/A')
                })
            );
        } else {
            onSnackbar(loc('customerSnackBar4', lang), 'error');
        }
    };

    //used to force component to update similar to this.forceUpdate in react class components
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const handleInfoDialog = (state, _id) => () => {
        setInfoDialogOpen(state);
        setCharityInfo(_.find(charities, charity => charity._id === _id));
    };

    const firstRender = useRef(true);
    const combinedPickupsWidget = useRef();
    const isEXP = process.env.REACT_APP_REGION_EXT === 'EXP';

    const handleResize = e => {
        forceUpdate();
    };

    const handleGoToHistory = () => {
        history.push(`/customers/${customer._id}/history?${queryString.stringify({ back: true })}`);
    };

    const getVisibleSlides = tabsLength => {
        if (_.isNil(combinedPickupsWidget.current)) {
            return 1;
        }

        let width = combinedPickupsWidget.current.getBoundingClientRect().width;
        /*if (tabsLength === 2 && width < 280 * 2) {
            return 1.2;
        } else if (tabsLength === 2 && width >= 280 * 2) {
            return 2;
        } else if (tabsLength === 3 && width < 280 * 3) {
            return 1.2;
        } else if (tabsLength === 3 && width >= 280 * 3) {
            return 3;
        } else {
            return 1;
        }*/
        if (width < SLIDE_WIDTH * tabsLength) {
            const numWholeTabs = (width - 0.2 * SLIDE_WIDTH) / SLIDE_WIDTH;
            return Math.floor(numWholeTabs) + 0.2;
        } else if (width >= SLIDE_WIDTH * tabsLength) {
            return tabsLength;
        } else {
            return 1.2;
        }
    };

    const getTouchEnabled = tabsLength => {
        if (_.isNil(combinedPickupsWidget.current)) {
            return true;
        }

        if (getVisibleSlides(tabsLength) % 1 === 0) {
            return false;
        } else {
            return true;
        }
    };

    const getDragEnabled = tabsLength => {
        if (_.isNil(combinedPickupsWidget.current)) {
            return true;
        }

        if (getVisibleSlides(tabsLength) % 1 === 0) {
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const clicksAllowed = !profileNotComplete(customer) && emailIsVerified(customer);

    const swipeableTabs = [];

    /// const bulksWithPickups = _.filter(bulks, bulk => !_.isNil(bulk.pickup));

    // Pending pickups
    const pickupsPending = _.filter(pickups, p => !p.complete && !p.aborted);
    const lastPickupPending = _.last(pickupsPending); // TODO: this has to be reworked to display multi-pickups

    // Completed and aborted pickups
    const pickupsCompleted = _.filter(pickups, p => p.complete || p.aborted);
    const lastCompletedPickup = _.last(pickupsCompleted);

    const lastBulk = _.last(bulks);
    let lastBulkReported = false;
    lastBulkReported =
        lastBulk &&
        lastBulk.payloadIssues &&
        !_.isEmpty(
            lastBulk.payloadIssues.issues
                .map(issue => reportOptions.find(option => option.code === issue)) // change it to check code only next release
                .filter(issue => !_.isNil(issue) && issue.emailToCustomer === true)
        );

    const pickupForLastBulk = _.get(lastBulk, 'pickup');
    const pickupForLastBulkReported =
        pickupForLastBulk && pickupForLastBulk.driverIssues && !_.isEmpty(pickupForLastBulk.driverIssues);

    const pickupsCompletedAndUncounted = _.filter(
        pickupsCompleted,
        p =>
            p.complete &&
            pickupHelper.numberOfCountedBulksOnPickup(p) < p.bulks.length &&
            pickupHelper.numberOfCountedBulksOnPickup(p) !== -1 // -1 indicates there are no bulks on the pickup (legacy data)
    );
    const lastCompletedAndUncountedPickup = _.last(pickupsCompletedAndUncounted);

    const pickupsCompletedAndCounted = _.filter(
        pickupsCompleted,
        p => p.complete && pickupHelper.numberOfCountedBulksOnPickup(p) === p.bulks.length
    );
    const lastCompletedAndCountedPickup = _.last(pickupsCompletedAndCounted);

    const quickdropBulks = _.filter(bulks, bulk => _bulk.isQuickDrop(bulk));
    let lastQuickdropBulk = _.last(quickdropBulks);

    // Express App does not guarantee the last entry in the collection is the most recently completed. At least for the migrated data.
    // Get most recently completed
    if (isEXPRegion()) {
        const groupedQuickdropBulks = groupQuickDropBulks(quickdropBulks);

        const QuickdropByCompletion = _.last(_.sortBy(groupedQuickdropBulks.map(x => _.first(x)), 'dateCompleted'));

        const lastQuickDropBulkCreatedDate = moment(_.get(lastQuickdropBulk, 'createdAt')).tz(
            process.env.REACT_APP_REGION_TIMEZONE
        );

        if (lastQuickDropBulkCreatedDate.isBefore(QuickdropByCompletion)) {
            lastQuickdropBulk = QuickdropByCompletion;
        }
    }

    const showUncountedWidget =
        !_.isNil(lastCompletedAndUncountedPickup) && _.isNil(lastCompletedAndUncountedPickup.charity)
            ? pickupHelper.hasUncompletedBeverageBulks(lastCompletedAndUncountedPickup)
            : !_.isNil(lastCompletedAndUncountedPickup);

    let commodityUOM = 'bag';
    if (showUncountedWidget) {
        let bulkForCommodityUOM = _.find(
            _.get(lastCompletedAndUncountedPickup, 'bulks', []),
            b => b.skuType === 'beverage' || b.skuType === 'collectionbins'
        );
        commodityUOM = _.get(bulkForCommodityUOM, 'commodityUOM', 'bag');
    }
    // TEMPORARY INFO (UNCOUNTED PICKUP):
    if (showUncountedWidget) {
        swipeableTabs.push({
            widget: (
                <UncountedPickupSubwidget
                    clicksAllowed={clicksAllowed}
                    pickup={_.last(pickupsCompletedAndUncounted).pickup}
                    onUncountedDialog={state => () => setUncountedDialogOpen(state)}
                    commodityUOM={uppercaseFirstLetter(commodityUOM)}
                    daysToCount={daysToCount}
                />
            ),
            title: loc('pickupWidgets3', lang, { commodityUOM }),
            color: colors.green[500],
            onClick: () => setUncountedDialogOpen(true)
        });
    }

    //UNCOUNTED QUICKDROP
    if (!_.isNil(lastQuickdropBulk) && !bulkHelper.isCompleted(lastQuickdropBulk) && !isEXP) {
        swipeableTabs.push({
            widget: (
                <UncountedQuickdropSubwidget
                    clicksAllowed={clicksAllowed}
                    quickdropBulk={lastQuickdropBulk}
                    onUncountedQuickdropDialog={() => setUncountedQuickdropDialogOpen(true)}
                    daysToCount={daysToCount}
                />
            ),
            title: loc('pickupWidgets4', lang, { type: getQuickDropName(lang) }),
            color: colors.green[500],
            onClick: () => setUncountedQuickdropDialogOpen(true)
        });
    }

    // LAST BULK WAS REPORTED
    if (lastBulkReported || pickupForLastBulkReported) {
        swipeableTabs.push({
            widget: (
                <ReportedPickupSubwidget
                    clicksAllowed={clicksAllowed}
                    onReportedDialog={state => () => setCustomerReportDialogOpen(state)}
                />
            ),
            title: isEXP ? loc('pickupWidgets5', lang) : loc('pickupWidgets6', lang),
            color: colors.red[500],
            onClick: () => setCustomerReportDialogOpen(true)
        });
    }

    // CURRENT PENDING PICKUP:
    if (_.isEmpty(pickupsPending)) {
        swipeableTabs.push({
            widget: (
                <SchedulePickupSubwidget
                    onReloadCustomer={onReloadCustomer}
                    clicksAllowed={clicksAllowed}
                    onPickupDialog={onPickupDialog}
                    customer={customer}
                    charities={charities}
                    charityPreferred={charityPreferred}
                    http={http}
                    setCharitySelectDialogOpen={setCharitySelectDialogOpen}
                    charityEnabled={charityEnabled}
                />
            ),
            title: loc('getStartedHere', lang),
            color: theme.palette.primary.main,
            onClick: onPickupDialog(true)
        });
    } else if (lastPickupPending.unserviced) {
        swipeableTabs.push({
            widget: (
                <UnservicedPickupSubwidget
                    clicksAllowed={clicksAllowed}
                    pickup={lastPickupPending}
                    onPickupDialog={onPickupDialog}
                />
            ),
            title: loc('pickupWidgets7', lang),
            color: theme.palette.secondary.main,
            onClick: onPickupDialog(true)
        });
    } else if (pickupsPending.length === 1) {
        swipeableTabs.push({
            widget: (
                <EditPickupSubwidget
                    clicksAllowed={clicksAllowed}
                    pickup={lastPickupPending}
                    charities={charities}
                    onPickupDialog={onPickupDialog(true)}
                    customerId={customer.uniqueID}
                    onPickupConfirmationDialog={onPickupConfirmationDialog}
                    charityEnabled={charityEnabled}
                />
            ),
            title: loc('pickupWidgets10', lang),
            color: theme.palette.primary.main,
            onClick: onPickupDialog(true)
        });
    } else {
        swipeableTabs.push({
            widget: (
                <SummaryPickupSubdiget
                    clicksAllowed={clicksAllowed}
                    pickups={pickupsPending}
                    charities={charities}
                    onPickupDialog={onPickupDialog(true)}
                    customerId={customer.uniqueID}
                    setCharitySelectDialogOpen={setCharitySelectDialogOpen}
                />
            ),
            title: loc('pickupWidgets23', lang),
            color: theme.palette.primary.main,
            onClick: onPickupDialog(true)
        });
    }

    const lastQuickdropBulkDate = !_.isNil(_.get(lastQuickdropBulk, 'dateCompleted'))
        ? moment(_.get(lastQuickdropBulk, 'dateCompleted')).tz(process.env.REACT_APP_REGION_TIMEZONE)
        : null;

    // LAST PICKUP:
    if (!_.isNil(lastCompletedPickup)) {
        const lastCompletePickupDate = moment(_.get(lastCompletedPickup, 'completionDate')).tz(
            _.get(lastCompletedPickup, 'zone.timezone', process.env.REACT_APP_REGION_TIMEZONE)
        );

        if (!_.isNil(lastQuickdropBulkDate) && lastCompletePickupDate.isBefore(lastQuickdropBulkDate)) {
            swipeableTabs.push({
                widget: (
                    <LastCompletedSubwidget
                        clicksAllowed={clicksAllowed}
                        lastCompletedItem={{
                            type: getQuickDropName(lang),
                            date: lastQuickdropBulkDate
                        }}
                        onGoToHistory={handleGoToHistory}
                    />
                ),
                title: isEXP
                    ? loc('pickupWidgets32b', lang)
                    : loc('pickupWidgets32', lang, { type: getQuickDropName(lang) }),
                color: colors.grey[700],
                onClick: handleGoToHistory
            });
        } else if (lastCompletedPickup.aborted) {
            swipeableTabs.push({
                widget: (
                    <AbortedPickupSubwidget
                        clicksAllowed={clicksAllowed}
                        pickup={lastCompletedPickup}
                        onGoToHistory={handleGoToHistory}
                    />
                ),
                title: loc('pickupWidgets9', lang),
                color: colors.grey[700],
                onClick: handleGoToHistory
            });
        } else if (!_.isNil(lastCompletedAndCountedPickup)) {
            swipeableTabs.push({
                widget: (
                    <LastCompletedSubwidget
                        clicksAllowed={clicksAllowed}
                        lastCompletedItem={{
                            type: lang === 'fr' ? 'ramassage' : 'pickup',
                            date: lastCompletePickupDate
                        }}
                        pickup={lastCompletedAndCountedPickup}
                        onGoToHistory={handleGoToHistory}
                    />
                ),
                title: loc('pickupWidgets9', lang),
                color: colors.grey[700],
                onClick: handleGoToHistory
            });
        }
    } else if (!_.isNil(lastQuickdropBulk) && bulkHelper.isCompleted(lastQuickdropBulk)) {
        swipeableTabs.push({
            widget: (
                <LastCompletedSubwidget
                    clicksAllowed={clicksAllowed}
                    lastCompletedItem={{
                        type: getQuickDropName(lang),
                        date: lastQuickdropBulkDate
                    }}
                    onGoToHistory={handleGoToHistory}
                />
            ),
            title: loc('pickupWidgets32', lang, { type: getQuickDropName(lang) }),
            color: colors.grey[700],
            onClick: handleGoToHistory
        });
    }

    /*let carouselMaxWidth;
        if (swipeableTabs.length === 1) {
            carouselMaxWidth = 400;
        } else if (swipeableTabs.length === 2) {
            carouselMaxWidth = 800;
        } else if (swipeableTabs.length === 3) {
            carouselMaxWidth = 1200;
        }*/
    let carouselMaxWidth = SWIPEABLE_TABS_WIDTH * swipeableTabs.length;
    /*useEffect(() => {
        setTimeout(() => forceUpdate(), 0);
    }, [carouselMaxWidth]);*/

    if (firstRender.current) {
        firstRender.current = false;
        setTimeout(() => forceUpdate(), 0);
    }

    return (
        <React.Fragment>
            <div
                id="combined-pickups-widget"
                data-cy="combined-pickups-widget"
                ref={elem => {
                    combinedPickupsWidget.current = elem;
                    assignRef(elem);
                }}
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: `100%`,
                    marginTop: 'env(safe-area-inset-top)'
                }}
            >
                <div style={{ margin: '0 auto', maxWidth: carouselMaxWidth }}>
                    <CarouselProvider
                        naturalSlideWidth={100}
                        naturalSlideHeight={100}
                        totalSlides={swipeableTabs.length}
                        visibleSlides={getVisibleSlides(swipeableTabs.length)}
                        currentSlide={0}
                        touchEnabled={getTouchEnabled(swipeableTabs.length)}
                        dragEnabled={getDragEnabled(swipeableTabs.length)}
                    >
                        <Slider
                            trayTag="div"
                            className={'slider-custom-slider-inner'}
                            classNameAnimation="slider-custom-animation"
                        >
                            {swipeableTabs.map((tab, index) => {
                                let paperMargin;
                                if (index === 0) {
                                    paperMargin = `${theme.spacing.unit * 2}px ${theme.spacing.unit}px ${theme.spacing
                                        .unit * 2}px ${theme.spacing.unit * 2}px`;
                                } else if (index === swipeableTabs.length - 1) {
                                    paperMargin = `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme
                                        .spacing.unit * 2}px ${theme.spacing.unit}px`;
                                } else {
                                    paperMargin = `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`;
                                }

                                // NOTE: remove flex from slider-custom-slider-inner class to prevent same height on slides

                                return (
                                    <Slide
                                        key={tab.widget.type.displayName}
                                        tag="div"
                                        index={index}
                                        className="slider-slide-root"
                                        innerClassName="slider-slide-inner"
                                        style={{
                                            userSelect: 'none',
                                            paddingBottom: 0,
                                            //padding: 'initial',
                                            height: `calc(100% - ${theme.spacing.unit * 2}px)`
                                        }}
                                    >
                                        <Paper
                                            elevation={elevated ? 4 : 2}
                                            style={{
                                                margin: paperMargin,
                                                height: `calc(100% - ${theme.spacing.unit * 1}px - ${
                                                    elevated ? 4 : 2
                                                }px)`
                                            }}
                                        >
                                            <TabWrapper theme={theme} title={tab.title} color={tab.color}>
                                                {tab.widget}
                                            </TabWrapper>
                                        </Paper>
                                    </Slide>
                                );
                            })}
                        </Slider>
                    </CarouselProvider>
                </div>
            </div>

            {!_.isNil(pickupsCompletedAndUncounted) && (
                <UncountedDialog
                    open={uncountedDialogOpen}
                    onClose={() => setUncountedDialogOpen(false)}
                    pickupsCompletedAndUncounted={pickupsCompletedAndUncounted}
                />
            )}

            {(lastBulkReported || pickupForLastBulkReported) && (
                <CustomerReportDialog
                    open={customerReportDialogOpen}
                    onClose={() => setCustomerReportDialogOpen(false)}
                    bulk={lastBulk}
                    pickup={pickupForLastBulk}
                    http={http}
                />
            )}

            {!_.isNil(lastQuickdropBulk) && !bulkHelper.isCompleted(lastQuickdropBulk) && (
                <UncountedQuickdropDialog
                    open={uncountedQuickdropDialogOpen}
                    onClose={() => setUncountedQuickdropDialogOpen(false)}
                    customer={customer}
                    onReloadCustomer={onReloadCustomer}
                    quickdropBulk={lastQuickdropBulk}
                    http={http}
                    charityEnabled={charityEnabled}
                    daysToCount={daysToCount}
                />
            )}
            <CharitySelectDialog
                open={charitySelectDialogOpen}
                charities={charities}
                onChange={handleCharitySelected}
                onInfoDialog={handleInfoDialog}
                onClose={() => {
                    setCharitySelectDialogOpen(false);
                }}
                hideSubdivisions={false}
                regionToFilterFeatured={region}
            />
            <CharityInfoDialog open={infoDialogOpen} charity={charityInfo} onClose={handleInfoDialog(false, null)} />
        </React.Fragment>
    );
}

export default withTheme()(CombinedPickupsWidget);
