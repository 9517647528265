import React, { useState, useEffect, useMemo, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Paper,
    Avatar,
    Button,
    Typography,
    TextField,
    Grid,
    FormControl,
    FormControlLabel,
    InputLabel,
    OutlinedInput,
    Select,
    Switch,
    MenuItem,
    InputAdornment,
    Chip,
    Icon
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import DateListInput from 'components/InputComponents/DateListInput';
import GMapsAutocomplete from 'components/GMapsAutocomplete';
import CustomWrapper from 'containers/Operators/BulkCounter/CustomWrapper';

import DriverOptions from './DriverOptions';
import AddDriverDialog from './AddDriverDialog';
import EditDriverDialog from './EditDriverDialog';

import { CLUSTERING_ALGORITHMS, DESTINATION_ROUTING_OPTIONS } from 'constants.js';
import { formatAsCurrency } from 'utils/misc';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';
import StringListInput from 'components/CustomInputs/StringListInput';
import { validate } from 'utils/validate';

const DEFAULT_FIXED_COST_OPTIONS = ['Actual Gas Cost', 'Actual Payroll'];

const CollectorConfig = ({
    theme,
    operator,
    payloadOptions,
    handleChange,
    handleSave,
    saveStatus,
    notServicedDates,
    historyForm,
    handleAddFixedCost,
    handleDeleteFixedCost,
    handleDeleteRecurringFixedCost,
    fixedCosts,
    recurringFixedCosts,
    fixedCostForm,
    collectorName,
    destinationRouting,
    emails,
    phones,
    location,
    pickupAggregation,
    aggregationPreferences,
    avoidTolls,
    http,
    google,
    activeChanges,
    timezone = process.env.REACT_APP_REGION_TIMEZONE,
    collector,
    processors
}) => {
    const { lang } = useContext(LocalizationContext);
    const errorName = _.isNil(collectorName) || collectorName === '';
    const errorEmail = (emails || []).some(email => _.isNil(email) || !isValidEmail(email));
    const errorPhone = (phones || []).some(phone => _.isNil(phone) || validate(['phone'], phone, lang).fail);
    const errorAddress = _.isNil(location) || location.description === '';
    const [addDriverDialog, setAddDriverDialog] = useState(false);
    const [editDriverDialog, setEditDriverDialog] = useState({ open: false, driver: {} });
    const [dayToEdit, setDayToEdit] = useState(
        moment()
            .tz(timezone)
            .day()
    );
    const [defaultFixedCostOptions, setDefaultFixedCostOptions] = useState(DEFAULT_FIXED_COST_OPTIONS);
    const [availableDrivers, setAvailableDrivers] = useState({});
    const [driversToAdd, setDriversToAdd] = useState([]);
    const presentNotServicedDates = notServicedDates.filter(date => moment(date, 'YYYY/MM/DD').isSameOrAfter(moment()));

    const getDrivers = async () => {
        const res = await http.getJSON(`/users/getAllDrivers/${collector._id}`);
        if (res.ok) {
            const drivers = {};
            res.data.drivers.forEach(driver => {
                drivers[driver._id] = driver;
            });

            setAvailableDrivers(drivers);
        }
    };

    const getFixedCostOptions = async () => {
        const res = await http.getJSON(`/system/configuration/defaultFixedCosts`);
        if (res.ok) {
            setDefaultFixedCostOptions(res.data.defaultFixedCosts);
        }
    };

    const handleAddDriverDialog = open => () => {
        setAddDriverDialog(open);
    };

    const handleEditDriverDialog = (driver = null) => () => {
        setEditDriverDialog({ open: Boolean(driver), driver: Boolean(driver) ? driver : editDriverDialog.driver });
    };

    const handleChangeDayToEdit = (e, day) => {
        if (!_.isNil(day)) {
            setDayToEdit(day);
        }
    };

    const handleDriversToAdd = _id => () => {
        if (driversToAdd.includes(_id)) {
            setDriversToAdd(_.without(driversToAdd, _id));
        } else {
            setDriversToAdd([...driversToAdd, _id]);
        }
    };

    const handleAddDriversToDay = () => {
        const dayObj = _.find(aggregationPreferences, ({ day }) => day === dayToEdit);
        dayObj.drivers = _.concat(dayObj.drivers, driversToAdd);
        handleChange({
            target: {
                name: 'aggregationPreferences',
                value: dayObj
            }
        });
        setDriversToAdd([]);
        setAddDriverDialog(false);
    };

    const handleAddDriversToAllDays = (driver = null) => {
        const newAggregationPreferences = _.cloneDeep(aggregationPreferences);
        const driversList = driver ? [driver] : driversToAdd;

        newAggregationPreferences.forEach(dayObj => {
            driversList.forEach(driver => {
                if (!dayObj.drivers.includes(driver)) {
                    dayObj.drivers.push(driver);
                }
            });
        });

        handleChange({
            target: {
                name: 'allAggregationPreferences',
                value: newAggregationPreferences
            }
        });

        setDriversToAdd([]);
        setAddDriverDialog(false);
    };

    const handleDragEnd = ({ draggableId, destination }) => {
        if (!destination) return;
        const dayObj = _.find(aggregationPreferences, ({ day }) => day === dayToEdit);
        _.pull(dayObj.drivers, draggableId);
        dayObj.drivers.splice(destination.index, 0, draggableId);

        handleChange({
            target: {
                name: 'aggregationPreferences',
                value: dayObj
            }
        });
    };

    const handleRemoveDriver = _id => () => {
        const dayObj = _.find(aggregationPreferences, ({ day }) => day === dayToEdit);
        _.pull(dayObj.drivers, _id);
        handleChange({
            target: {
                name: 'aggregationPreferences',
                value: dayObj
            }
        });
    };

    const handleFixedCostSelect = e => {
        const manualEvent = {
            target: {
                name: 'fixedCostName',
                value: e.target.value
            }
        };

        handleChange(manualEvent);
    };

    const dayDrivers = useMemo(() => {
        const dayPreferences = _.find(aggregationPreferences, obj => obj.day === dayToEdit);
        return dayPreferences.drivers;
    }, [availableDrivers, dayToEdit, aggregationPreferences]);

    const inactiveDrivers = useMemo(() => {
        return _.sortBy(Object.values(availableDrivers).filter(driver => !dayDrivers.includes(driver._id)), driver =>
            driver.name.first.toLowerCase()
        );
    }, [availableDrivers, dayToEdit, dayDrivers, aggregationPreferences]);

    const capacity = useMemo(() => {
        if (!_.isEmpty(availableDrivers)) {
            const dayPreferences = _.find(aggregationPreferences, obj => obj.day === dayToEdit);
            return dayPreferences.drivers.reduce((sum, _id) => {
                const driverBagCapacity =
                    _.get(availableDrivers[_id], 'driverBagCapacity', 0) *
                    _.get(availableDrivers[_id], 'driverLoadsPerDay', 1);

                return sum + driverBagCapacity;
            }, 0);
        }
    }, [availableDrivers, aggregationPreferences, dayToEdit]);

    useEffect(() => {
        getDrivers();
        getFixedCostOptions();
    }, []);

    const paperStyles = {
        margin: theme.spacing.unit,
        padding: theme.spacing.unit * 2
    };

    const headerStyles = {
        marginBottom: theme.spacing.unit * 2
    };

    const saveStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    };

    return (
        <>
            <Grid container spacing={theme.spacing.unit}>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <Paper style={paperStyles}>
                        <Typography variant="h6" style={headerStyles}>
                            Driver Options
                        </Typography>
                        <Grid container>
                            <Grid item xs={6} style={{ paddingRight: theme.spacing.unit }}>
                                <FormControl variant="outlined" style={headerStyles} fullWidth>
                                    <InputLabel>Pickup Clustering Algorithm</InputLabel>
                                    <Select
                                        value={pickupAggregation}
                                        onChange={handleChange}
                                        input={<OutlinedInput labelWidth={200} name="pickupAggregation" />}
                                    >
                                        {CLUSTERING_ALGORITHMS.map(algorithm => {
                                            return (
                                                <MenuItem value={algorithm} key={algorithm}>
                                                    {ALGORITHM_DESCRIPTION[algorithm]}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={6}>
                                <FormControl variant="outlined" style={headerStyles} fullWidth>
                                    <InputLabel>Route Destination By</InputLabel>
                                    <Select
                                        value={destinationRouting}
                                        onChange={handleChange}
                                        input={<OutlinedInput labelWidth={150} name="destinationRouting" />}
                                    >
                                        {DESTINATION_ROUTING_OPTIONS.map(option => {
                                            return (
                                                <MenuItem value={option} key={option}>
                                                    {option}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <DriverOptions
                            dayToEdit={dayToEdit}
                            capacity={capacity}
                            destinationRouting={destinationRouting}
                            handleChangeDayToEdit={handleChangeDayToEdit}
                            handleDragEnd={handleDragEnd}
                            aggregationPreferences={aggregationPreferences}
                            dayDrivers={dayDrivers}
                            availableDrivers={availableDrivers}
                            inactiveDrivers={inactiveDrivers}
                            collector={collector}
                            handleRemoveDriver={handleRemoveDriver}
                            handleAddDriverDialog={handleAddDriverDialog}
                            handleEditDriverDialog={handleEditDriverDialog}
                            handleAddToAllDays={handleAddDriversToAllDays}
                        />
                        <div style={saveStyles}>
                            <Typography
                                color={saveStatus === 'ok' ? 'primary' : 'error'}
                                style={{ marginRight: theme.spacing.unit * 2, textAlign: 'right' }}
                            >
                                {saveStatus === 'ok'
                                    ? loc('saved', lang)
                                    : !_.isEmpty(saveStatus)
                                    ? 'Error, please try again'
                                    : activeChanges
                                    ? 'You have unsaved changes'
                                    : null}
                            </Typography>
                            <Button
                                data-cy="redemptions-save-config-driver-options"
                                variant="contained"
                                color="secondary"
                                onClick={handleSave}
                                disabled={!activeChanges || errorName || errorAddress || errorEmail || errorPhone}
                            >
                                Save
                            </Button>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={4}>
                    <Paper style={paperStyles}>
                        <Typography variant="h6" style={headerStyles}>
                            Pickup Options
                        </Typography>

                        <div
                            style={{
                                margin: theme.spacing.unit,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Button
                                data-cy="collector-config-picture-enabled-switch"
                                onClick={() => handleChange({ target: { name: 'enabled' } })}
                                variant={payloadOptions.enabled ? 'contained' : 'outlined'}
                                color="primary"
                            >
                                Picture Confirmation {payloadOptions.enabled ? 'Enabled' : 'Disabled'}
                            </Button>

                            <TextField
                                data-cy="collector-config-picture-bag-minimum"
                                name="minimum"
                                id="payloadImageMinimum"
                                label="Minimum Bags Required"
                                margin="normal"
                                variant="outlined"
                                value={payloadOptions.minimum}
                                onChange={handleChange}
                                disabled={!payloadOptions.enabled}
                                style={{ margin: theme.spacing.unit }}
                            />
                        </div>

                        <TextField
                            type="number"
                            name="gasPrice"
                            id="gasPrice"
                            label="Default Gas Price"
                            margin="normal"
                            variant="outlined"
                            value={historyForm.gasPrice}
                            onChange={handleChange}
                            style={headerStyles}
                            fullWidth
                        />

                        <TextField
                            type="number"
                            name="employeeWage"
                            id="employeeWage"
                            label="Default Employee Wage ($/hr)"
                            margin="normal"
                            variant="outlined"
                            value={historyForm.employeeWage}
                            onChange={handleChange}
                            style={headerStyles}
                            fullWidth
                        />
                        <TextField
                            type="number"
                            name="fuelEfficiency"
                            id="fuelEfficiency"
                            label="Default Driver Fuel Efficiency (km/L)"
                            margin="normal"
                            variant="outlined"
                            value={historyForm.fuelEfficiency}
                            onChange={handleChange}
                            style={headerStyles}
                            fullWidth
                        />

                        <div style={saveStyles}>
                            <Typography
                                color={saveStatus === 'ok' ? 'primary' : 'error'}
                                style={{ marginRight: theme.spacing.unit * 2, textAlign: 'right' }}
                            >
                                {saveStatus === 'ok'
                                    ? loc('saved', lang)
                                    : !_.isEmpty(saveStatus)
                                    ? 'Error, please try again'
                                    : activeChanges
                                    ? 'You have unsaved changes'
                                    : null}
                            </Typography>
                            <Button
                                data-cy="redemptions-save-config-picture-confirmation"
                                variant="contained"
                                color="secondary"
                                onClick={handleSave}
                                disabled={!activeChanges || errorName || errorAddress || errorEmail || errorPhone}
                            >
                                Save
                            </Button>
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={12} lg={12} xl={4}>
                    <Paper id="collector-options" data-cy="collector-options" style={paperStyles}>
                        <Typography variant="h6" style={headerStyles}>
                            Collector Options
                        </Typography>

                        <TextField
                            name="collectorName"
                            id="collectorName"
                            label="Collector Name"
                            margin="normal"
                            variant="outlined"
                            value={collectorName}
                            onChange={handleChange}
                            style={headerStyles}
                            error={errorName}
                            helperText={errorName && 'You must enter a name'}
                            fullWidth
                        />

                        <StringListInput
                            data-cy="collector-emails"
                            InputProps={{
                                variant: 'outlined'
                            }}
                            label="Emails"
                            values={emails}
                            onChange={values =>
                                handleChange({
                                    target: {
                                        name: 'emails',
                                        value: values
                                    }
                                })
                            }
                            error={email => {
                                return !(email === '') && !isValidEmail(email);
                            }}
                            errorMessage="Please enter a valid email address."
                        />
                        <StringListInput
                            InputProps={{
                                variant: 'outlined'
                            }}
                            label="Phones"
                            values={phones}
                            onChange={values =>
                                handleChange({
                                    target: {
                                        name: 'phones',
                                        value: values
                                    }
                                })
                            }
                            error={phone => {
                                let errorsObj = validate(['phone'], phone, lang);
                                return errorsObj.fail && !(phone === '');
                            }}
                            errorMessage="Please enter a valid phone."
                            type="phone"
                        />
                        {/* <TextField
                            name="customerSupportEmail"
                            id="customerSupportEmail"
                            data-cy="collector-contact-email"
                            label="Contact Email (For notifications, customer information requests and CSR communication)"
                            margin="normal"
                            variant="outlined"
                            value={customerSupportEmail}
                            onChange={handleChange}
                            style={headerStyles}
                            error={errorEmail}
                            helperText={
                                errorEmail &&
                                'An email is required for notifications, customer information requests and for CSR communication'
                            }
                            fullWidth
                        /> */}

                        <div style={{ ...headerStyles, marginTop: theme.spacing.unit * 2 }}>
                            <GMapsAutocomplete
                                margin="normal"
                                http={http}
                                google={google}
                                label="Address"
                                placeholder="Enter an address"
                                selectedValue={location.description}
                                types={['address']}
                                onSuggestionSelected={({ suggestion, place }) => {
                                    handleChange({
                                        target: {
                                            name: 'location',
                                            value: {
                                                description: suggestion.description,
                                                place_id: suggestion.place_id,
                                                lat: place.geometry.location.lat(),
                                                lng: place.geometry.location.lng(),
                                                city: _.get(
                                                    _.find(place.address_components, ac =>
                                                        ac.types.includes('locality')
                                                    ),
                                                    'long_name',
                                                    undefined
                                                ),
                                                province: _.get(
                                                    _.find(place.address_components, ac =>
                                                        ac.types.includes('administrative_area_level_1')
                                                    ),
                                                    'long_name',
                                                    undefined
                                                ),
                                                postalCode: _.get(
                                                    _.find(place.address_components, component =>
                                                        component.types.includes('postal_code')
                                                    ),
                                                    'short_name',
                                                    undefined
                                                )
                                            }
                                        }
                                    });
                                }}
                                error={errorAddress}
                                helperText={errorAddress && 'You must enter an address'}
                            />
                        </div>

                        <div style={headerStyles}>
                            <DateListInput
                                values={presentNotServicedDates}
                                onDatesChanged={dates =>
                                    handleChange({ target: { name: 'notServicedDates', value: dates } })
                                } //HACK not real event
                                dateFormat="YYYY-MM-DD"
                                label="Unserviced Dates"
                                disablePast={true}
                            />
                        </div>

                        <div style={headerStyles}>
                            <CustomWrapper title="Monthly Costs">
                                <Grid container spacing={8} style={{ marginTop: theme.spacing.unit }}>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }} />
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex' }}>
                                            <FormControl style={{ width: 150 }}>
                                                <InputLabel htmlFor="fixedCostMonth">Month</InputLabel>
                                                <Select
                                                    style={{ marginRight: theme.spacing.unit }}
                                                    value={fixedCostForm.date.month()}
                                                    onChange={handleChange}
                                                    inputProps={{
                                                        name: 'fixedCostMonth',
                                                        id: 'fixedCostMonth'
                                                    }}
                                                >
                                                    {MONTHS.map((month, i) => {
                                                        return (
                                                            <MenuItem value={i} key={i}>
                                                                {month}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <FormControl style={{ width: 150 }}>
                                                <InputLabel htmlFor="fixedCostYear">Year</InputLabel>
                                                <Select
                                                    value={fixedCostForm.date.year()}
                                                    onChange={handleChange}
                                                    inputProps={{
                                                        name: 'fixedCostYear',
                                                        id: 'fixedCostYear'
                                                    }}
                                                >
                                                    {Array.from(
                                                        { length: new Date().getFullYear() - 2017 },
                                                        (a, i) => 2018 + i
                                                    ).map((year, idx) => {
                                                        return (
                                                            <MenuItem value={year} key={idx}>
                                                                {year}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item xs={7} sm={7}>
                                        <TextField
                                            name="fixedCostName"
                                            id="fixedCostName"
                                            label="Name"
                                            value={fixedCostForm.name || ''}
                                            onChange={handleChange}
                                            style={headerStyles}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {!_.isEmpty(defaultFixedCostOptions) && (
                                                            <Select
                                                                renderValue={() => ''}
                                                                onChange={handleFixedCostSelect}
                                                                style={{
                                                                    width: '25px',
                                                                    overflow: 'hidden'
                                                                }}
                                                                value={fixedCostForm.name || ''}
                                                                disableUnderline
                                                            >
                                                                {defaultFixedCostOptions.map(option => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                        <Button
                                                            size="small"
                                                            style={{ whiteSpace: 'nowrap' }}
                                                            onClick={() =>
                                                                handleChange({
                                                                    target: {
                                                                        name: 'fixedCostRecurring',
                                                                        value: !fixedCostForm.recurring
                                                                    }
                                                                })
                                                            }
                                                        >
                                                            {fixedCostForm.recurring ? 'Recurring' : 'One Time'}
                                                        </Button>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={3}>
                                        <TextField
                                            name="fixedCostAmount"
                                            id="fixedCostAmount"
                                            label="Amount"
                                            type="number"
                                            value={fixedCostForm.amount || ''}
                                            onChange={handleChange}
                                            style={headerStyles}
                                            fullWidth
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '100%',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={handleAddFixedCost}
                                                disabled={!fixedCostForm.name || !fixedCostForm.amount}
                                                data-cy="fixed-cost-add-btn"
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography style={{ margin: theme.spacing.unit }}>
                                        Costs for {MONTHS[fixedCostForm.date.month()]} {fixedCostForm.date.year()}
                                    </Typography>
                                    {fixedCosts.map(fixedCost => {
                                        const { name, amount, date } = fixedCost;

                                        if (
                                            moment(date)
                                                .tz(collector.timezone)
                                                .format('YYYYMM') !== fixedCostForm.date.format('YYYYMM')
                                        )
                                            return null;
                                        return (
                                            <Chip
                                                key={name}
                                                label={`${name} - ${formatAsCurrency(amount)}`}
                                                style={{ margin: theme.spacing.unit / 2 }}
                                                onDelete={() => handleDeleteFixedCost(fixedCost)}
                                                deleteIcon={<Icon>cancel</Icon>}
                                            />
                                        );
                                    })}
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography
                                        style={{ margin: theme.spacing.unit, marginTop: theme.spacing.unit * 2 }}
                                    >
                                        Recurring Costs
                                    </Typography>
                                    {recurringFixedCosts.map(fixedCost => {
                                        const { name, amount } = fixedCost;
                                        return (
                                            <Chip
                                                key={name}
                                                label={`${name} - ${formatAsCurrency(amount)}`}
                                                style={{ margin: theme.spacing.unit / 2 }}
                                                onDelete={() => handleDeleteRecurringFixedCost(fixedCost)}
                                                deleteIcon={<Icon>cancel</Icon>}
                                            />
                                        );
                                    })}
                                </Grid>
                            </CustomWrapper>
                        </div>
                        {process.env.REACT_APP_REGION_EXT === 'AUS' && (
                            <div style={headerStyles}>
                                <FormControlLabel
                                    control={<Switch checked={avoidTolls} onChange={handleChange} name="avoidTolls" />}
                                    label="Avoid Tolls"
                                />
                            </div>
                        )}

                        <div style={saveStyles}>
                            <Typography
                                color={saveStatus === 'ok' ? 'primary' : 'error'}
                                style={{ marginRight: theme.spacing.unit * 2, textAlign: 'right' }}
                            >
                                {saveStatus === 'ok'
                                    ? loc('saved', lang)
                                    : !_.isEmpty(saveStatus)
                                    ? 'Error, please try again'
                                    : activeChanges
                                    ? 'You have unsaved changes'
                                    : null}
                            </Typography>
                            <Button
                                data-cy="save-config-collector-options"
                                variant="contained"
                                color="secondary"
                                onClick={handleSave}
                                disabled={!activeChanges || errorName || errorAddress || errorEmail || errorPhone}
                            >
                                Save
                            </Button>
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <AddDriverDialog
                open={addDriverDialog}
                handleClose={handleAddDriverDialog(false)}
                dayToEdit={dayToEdit}
                driversToAdd={driversToAdd}
                inactiveDrivers={inactiveDrivers}
                handleChange={handleDriversToAdd}
                handleAdd={handleAddDriversToDay}
                handleAddToAllDays={handleAddDriversToAllDays}
            />

            <EditDriverDialog
                open={editDriverDialog.open}
                handleClose={handleEditDriverDialog()}
                driver={editDriverDialog.driver}
                collector={collector}
                getDrivers={getDrivers}
                theme={theme}
                google={google}
                processors={processors}
            />
        </>
    );
};

export default CollectorConfig;

const ALGORITHM_DESCRIPTION = {
    CAPACITY_FIRST: 'Capacity First (Minimum number of drivers)',
    ROUTE_FIRST: 'Route First (Optimize driving distance)',
    EVEN_SPLIT: 'Even Split (Equal pickups per driver)',
    MIN_DRIVERS_EVEN_SPLIT: 'Even Split + Minimum Drivers'
};

function isValidEmail(value) {
    let regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEmail.test(value.toLowerCase());
}

const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
