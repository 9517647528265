import React, { useState, useContext, useEffect } from 'react';

import _ from 'lodash';

import { getCustomerFirstNameAndLastInitial } from 'utils/misc';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';
import OperatorContext from 'utils/contexts/OperatorContext';

import ReactJson from 'react-json-view';

import {
    colors,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    DialogContent,
    DialogActions,
    Dialog,
    Typography,
    withMobileDialog,
    CircularProgress,
    Divider
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';
import { Icon as MDIcon } from '@mdi/react';
import { mdiCheckboxMarkedCircleOutline, mdiCodeJson } from '@mdi/js';

import bulkHelper from 'helpers/bulkHelper';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { _bulk, _user } from 'std';

import CustomDebugDialog from 'components/CustomDebugDialog/CustomDebugDialog';

function BulkErrorDialog(props) {
    const { theme, http, bulk, open, operator, inProgress, refreshBulk, onClose } = props;

    const [history, setHistory] = useState({});
    const [showHistory, setShowHistory] = useState(false);

    const loadHistory = async id => {
        const res = await http.getJSON(`/integrationHistory/${id}/getData`);
        if (res.ok) {
            setHistory(res.data.integrationData);
            setShowHistory(true);
        }
    };

    const handleResolveErrors = async () => {
        const res = await http.getJSON(`/bulks/${bulk._id}/resolveErrors`);
        if (res.ok) {
            setHistory({});
            setShowHistory(false);
            onClose();

            refreshBulk();
        }
    };

    return (
        <>
            <Dialog data-cy="multi-step-dialog" fullWidth open={open}>
                <DialogTitlePrimary>Errors</DialogTitlePrimary>
                <DialogContent>
                    {_.get(bulk, 'bulkErrors', []).map((error, idx) => {
                        return (
                            <>
                                {idx != 0 && <Divider />}
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: theme.spacing.unit * 1.5
                                        }}
                                    >
                                        <MDIcon
                                            size={1}
                                            color={error.isResolved ? colors.green[500] : colors.grey[400]}
                                            path={mdiCheckboxMarkedCircleOutline}
                                        />
                                    </div>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginTop: theme.spacing.unit * 2,
                                            marginBottom: theme.spacing.unit * 2
                                        }}
                                    >
                                        {error.description}
                                    </Typography>
                                    {operator.accountType === 'System Administrator' && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginRight: theme.spacing.unit,
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => loadHistory(error.history)}
                                        >
                                            <MDIcon path={mdiCodeJson} size={1} color={theme.palette.text.secondary} />
                                        </div>
                                    )}
                                </div>
                            </>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button color="default" onClick={onClose}>
                        Cancel
                    </Button>
                    {operator.accountType === 'System Administrator' && _bulk.hasUnresolvedErrors(bulk) && (
                        <Button color="primary" onClick={() => handleResolveErrors()}>
                            Mark as resolved
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <CustomDebugDialog open={showHistory} json={history} onClose={() => setShowHistory(false)} />
        </>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(BulkErrorDialog));
