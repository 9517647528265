import React, { useContext, useState } from 'react';

import _ from 'lodash';
import * as Yup from 'yup';

import { _collector } from 'std';

import GMapsAutocomplete from 'components/GMapsAutocomplete';
import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import TextMaskPhoneNumber from 'components/TextMasks/TextMaskPhoneNumber';
import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import { Formik } from 'formik';
import { validate } from 'utils/validate';

import { DialogActions, FormControl, Icon, MenuItem, Typography } from '@material-ui/core';
import { FEE_APPLICATION_OPTIONS, INVOICE_FREQUENCY_OPTIONS } from '../../../constants';

import {
    Grid,
    Button,
    TextField,
    Dialog,
    DialogContent,
    withMobileDialog,
    Switch,
    FormControlLabel
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { useMemo } from 'react';
import { isWidthDown } from '@material-ui/core/withWidth';
import PromoCodesInput from 'components/InputComponents/PromoCodesInput';
import { getSelect, getSwitch, getTextInput } from 'components/CRUDTables/helperFunctions';
import { isAUSRegion } from 'utils/misc';

// TODO: eventually turn this into a multistep dialog since it will take several fields
function QuickRegisterForm({
    theme,
    google,
    open,
    fullScreen,
    collectorObj,
    onClose,
    onSubmitForm,
    defaultCoordinates,
    services,
    width,
    startingValues = {}
}) {
    const [enableMultipleBookings, setEnableMultipleBookings] = useState(false);
    const { lang } = useContext(LocalizationContext);
    const [pickupType, setPickupType] = useState('Residential');
    const [promos, setPromos] = useState([]);
    const mobilePickupSubServices = useMemo(
        () =>
            _.get(
                _.find(services, service => service.isMobilePickupService && !service.disabled),
                'subServices',
                []
            ).filter(service => !service.disabled),
        [services]
    );
    const isSmallScreen = isWidthDown('xs', width);
    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        let valuesCopy = _.cloneDeep(values);
        valuesCopy.pickupType = pickupType;
        valuesCopy.promos = promos;
        onSubmitForm(valuesCopy, { setSubmitting, resetForm });
    };

    return (
        <Formik
            initialValues={{
                phone: _.get(startingValues, 'phone', ''),
                name: _.get(startingValues, 'name', {
                    first: '',
                    last: ''
                }),
                email: _.get(startingValues, 'email', ''),
                location: _.get(startingValues, 'location', {
                    description: '',
                    place_id: undefined,
                    lat: defaultCoordinates.latitude,
                    lng: defaultCoordinates.longitude,
                    businessName: '',
                    unitNumber: '',
                    province: '',
                    postalCode: ''
                }),
                feeApplication: FEE_APPLICATION_OPTIONS[0],
                invoiceFrequency: '30th of Month',
                feeInvoicePaymentWindow: 30,
                charityPreferred: '',
                multiplePickups: {
                    enabled: enableMultipleBookings,
                    limit: 1
                },
                bypassPickupThreshold: false,
                unitNumber: '',
                businessName: '',
                registeredBusinessName: '',
                businessNumber: '',
                locationType: ''
                // fundraiserName: ''
            }}
            validationSchema={Yup.object({
                email: Yup.string()
                    .email()
                    .trim()
                    .required('You must enter an email'),
                phone: Yup.string().test('validPhoneNumber', 'Phone number invalid', value => {
                    if (!value) return false;
                    let errorsObj = validate(['phone'], value, lang);
                    return !errorsObj.fail;
                }),
                name: Yup.object({
                    first: Yup.string()
                        .trim()
                        .required('You must enter a first name'),
                    last: Yup.string()
                        .trim()
                        .required('You must enter a last name')
                }),
                feeInvoicePaymentWindow: Yup.number().min(1, 'Must be 1 or more'),
                unitNumber: Yup.string(),
                locationType: Yup.string(),
                businessName: Yup.string(),
                registeredBusinessName: Yup.string(),
                businessNumber: Yup.string()
                // fundraiserName: Yup.string()
            })}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {formik => {
                const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    setFieldValue,
                    getFieldProps
                } = formik;

                return (
                    <Dialog open={open} fullWidth maxWidth="sm" fullScreen={fullScreen} onClose={onClose}>
                        <DialogTitlePrimary closeButtonShown onClose={onClose}>
                            {collectorObj ? `Registering for ${_collector.getName(collectorObj)}` : 'Register Customer'}
                        </DialogTitlePrimary>
                        <DialogContent>
                            <Grid container spacing={theme.spacing.unit} style={{ marginTop: theme.spacing.unit }}>
                                <CustomFormTitle
                                    titleText="Customer Info"
                                    iconName="person"
                                    style={{ marginTop: theme.spacing.unit }}
                                />
                                <Grid item xs="12">
                                    {!_.isNil(mobilePickupSubServices) &&
                                        !_.isEmpty(mobilePickupSubServices) &&
                                        mobilePickupSubServices.length > 1 && (
                                            <TextField
                                                {...getFieldProps('locationType')}
                                                fullWidth
                                                select
                                                variant="outlined"
                                                data-cy="profile-location-type-select"
                                                style={{ backgroundColor: 'white' }}
                                                label="Account Type"
                                            >
                                                {_(mobilePickupSubServices)
                                                    .map((service, i) => {
                                                        const title = _.get(service, `text.${lang}.title`, '');
                                                        return (
                                                            <MenuItem
                                                                value={_.get(service, 'pickupType')}
                                                                key={_.get(service, 'pickupType')}
                                                            >
                                                                <Icon
                                                                    style={{
                                                                        color: 'inherit',
                                                                        marginRight: theme.spacing.unit
                                                                    }}
                                                                >
                                                                    {_.get(service, 'icon')}
                                                                </Icon>
                                                                {title}
                                                            </MenuItem>
                                                        );
                                                    })
                                                    .value()}
                                            </TextField>
                                        )}
                                </Grid>

                                <Grid item xs="6">
                                    {_.get(formik.values, 'locationType') === 'Commercial' && (
                                        <TextField
                                            {...getFieldProps('registeredBusinessName')}
                                            error={
                                                _.get(touched, 'registeredBusinessName', false) &&
                                                _.get(errors, 'registeredBusinessName', false)
                                                    ? true
                                                    : null
                                            }
                                            label="Registered Business Name"
                                            variant="outlined"
                                            helperText={
                                                _.get(touched, 'registeredBusinessName', false) &&
                                                _.get(errors, 'registeredBusinessName', false)
                                                    ? _.get(errors, 'registeredBusinessName', false)
                                                    : null
                                            }
                                            fullWidth
                                            style={{ marginBottom: theme.spacing.unit }}
                                        />
                                    )}
                                </Grid>

                                <Grid item xs="6">
                                    {_.get(formik.values, 'locationType') === 'Commercial' && (
                                        <TextField
                                            {...getFieldProps('businessName')}
                                            error={
                                                _.get(touched, 'businessName', false) &&
                                                _.get(errors, 'businessName', false)
                                                    ? true
                                                    : null
                                            }
                                            label={
                                                isAUSRegion()
                                                    ? 'Trading Name (if different)'
                                                    : 'Doing Business As (if different)'
                                            }
                                            variant="outlined"
                                            helperText={
                                                _.get(touched, 'businessName', false) &&
                                                _.get(errors, 'businessName', false)
                                                    ? _.get(errors, 'businessName', false)
                                                    : null
                                            }
                                            fullWidth
                                            style={{ marginBottom: theme.spacing.unit }}
                                        />
                                    )}
                                </Grid>

                                <Grid item xs="12">
                                    {_.get(formik.values, 'locationType') === 'Commercial' && (
                                        <TextField
                                            {...getFieldProps('businessNumber')}
                                            error={
                                                _.get(touched, 'businessNumber', false) &&
                                                _.get(errors, 'businessNumber', false)
                                                    ? true
                                                    : null
                                            }
                                            label={isAUSRegion() ? 'Australian Business Number' : 'Business Number'}
                                            helperText={
                                                _.get(touched, 'businessNumber', false) &&
                                                _.get(errors, 'businessNumber', false)
                                                    ? _.get(errors, 'businessNumber', false)
                                                    : null
                                            }
                                            variant="outlined"
                                            fullWidth
                                            style={{ marginBottom: theme.spacing.unit }}
                                        />
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    <GMapsAutocomplete
                                        onBlur={handleBlur}
                                        google={google}
                                        location={{
                                            lat: _.get(values, 'location.lat'),
                                            lng: _.get(values, 'location.lng')
                                        }}
                                        label={
                                            _.get(formik.values, 'locationType') === 'Commercial'
                                                ? 'Company Address'
                                                : 'Address'
                                        }
                                        placeholder="Enter an address"
                                        selectedValue={_.get(values, 'location.description', '')}
                                        types={['address']}
                                        error={{
                                            fail:
                                                _.get(errors, 'location.description', false) &&
                                                _.get(touched, 'location.description', false),
                                            reason: 'You must enter a location'
                                        }}
                                        onSuggestionSelected={({ suggestion, place }) => {
                                            setFieldValue('location', {
                                                description: suggestion.description,
                                                place_id: suggestion.place_id,
                                                lat: place.geometry.location.lat(),
                                                lng: place.geometry.location.lng(),
                                                province: _.get(
                                                    _.find(place.address_components, ac =>
                                                        ac.types.includes('administrative_area_level_1')
                                                    ),
                                                    'long_name',
                                                    undefined
                                                )
                                            });
                                        }}
                                        style={{ marginBottom: 0, paddingBottom: 0, marginTop: 0, paddingTop: 0 }}
                                    />
                                </Grid>

                                {(_.get(formik.values, 'locationType') === 'Condo' ||
                                    _.get(formik.values, 'locationType') === 'Commercial') && (
                                    <Grid item xs={12}>
                                        <TextField
                                            {...getFieldProps('unitNumber')}
                                            error={
                                                _.get(touched, 'unitNumber', false) &&
                                                _.get(errors, 'unitNumber', false)
                                                    ? true
                                                    : null
                                            }
                                            label="Unit Number"
                                            variant="outlined"
                                            helperText={
                                                _.get(touched, 'unitNumber', false) &&
                                                _.get(errors, 'unitNumber', false)
                                                    ? _.get(errors, 'unitNumber', false)
                                                    : null
                                            }
                                            fullWidth
                                            style={{ marginBottom: theme.spacing.unit }}
                                        />
                                    </Grid>
                                )}

                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        {...getFieldProps('email')}
                                        error={
                                            _.get(touched, 'email', false) && _.get(errors, 'email', false)
                                                ? true
                                                : null
                                        }
                                        label={
                                            _.get(formik.values, 'locationType') === 'Commercial'
                                                ? 'Contact Email'
                                                : 'Email'
                                        }
                                        variant="outlined"
                                        helperText={
                                            _.get(touched, 'email', false) && _.get(errors, 'email', false)
                                                ? _.get(formik.errors, 'email', false)
                                                : null
                                        }
                                        fullWidth
                                        autoFocus
                                        autoComplete="new-email"
                                    />
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        {...getFieldProps('phone')}
                                        type="tel"
                                        variant="outlined"
                                        label="Phone number"
                                        fullWidth
                                        helperText={
                                            _.get(touched, 'phone', false) && _.get(errors, 'phone', false)
                                                ? _.get(errors, 'phone', false)
                                                : null
                                        }
                                        error={
                                            _.get(touched, 'phone', false) && _.get(errors, 'phone', false)
                                                ? true
                                                : null
                                        }
                                        InputProps={{
                                            inputComponent: TextMaskPhoneNumber
                                        }}
                                        autoComplete="new-phone"
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        {...getFieldProps('name.first')}
                                        error={
                                            _.get(touched, 'name.first', false) && _.get(errors, 'name.first', false)
                                                ? true
                                                : null
                                        }
                                        label={
                                            _.get(formik.values, 'locationType') === 'Commercial'
                                                ? 'Contact First Name'
                                                : 'First Name'
                                        }
                                        variant="outlined"
                                        helperText={
                                            _.get(touched, 'name.first', false) && _.get(errors, 'name.first', false)
                                                ? _.get(errors, 'name.first', false)
                                                : null
                                        }
                                        fullWidth
                                        autoComplete="new-first"
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        {...getFieldProps('name.last')}
                                        error={
                                            _.get(touched, 'name.last', false) && _.get(errors, 'name.last', false)
                                                ? true
                                                : null
                                        }
                                        label={
                                            _.get(formik.values, 'locationType') === 'Commercial'
                                                ? 'Contact Last Name'
                                                : 'Last Name'
                                        }
                                        variant="outlined"
                                        helperText={
                                            _.get(touched, 'name.last', false) && _.get(errors, 'name.last', false)
                                                ? _.get(errors, 'name.last', false)
                                                : null
                                        }
                                        fullWidth
                                        autoComplete="new-last"
                                    />
                                </Grid>

                                <CustomFormTitle
                                    titleText="Payments"
                                    iconName="paid"
                                    style={{ marginTop: theme.spacing.unit }}
                                />
                                {isAUSRegion() && (
                                    <Grid item xs="12">
                                        {getSelect(
                                            theme,
                                            'feeApplication',
                                            'Fee Application',
                                            FEE_APPLICATION_OPTIONS,
                                            formik
                                        )}
                                    </Grid>
                                )}
                                {isAUSRegion() &&
                                    _.get(formik, 'values.feeApplication') === FEE_APPLICATION_OPTIONS[1] && (
                                        <Grid item xs="6">
                                            {getSelect(
                                                theme,
                                                'invoiceFrequency',
                                                'Invoice Frequency',
                                                INVOICE_FREQUENCY_OPTIONS,
                                                formik
                                            )}
                                        </Grid>
                                    )}
                                {isAUSRegion() &&
                                    _.get(formik, 'values.feeApplication') === FEE_APPLICATION_OPTIONS[1] && (
                                        <Grid item xs="6">
                                            {getTextInput(
                                                theme,
                                                'feeInvoicePaymentWindow',
                                                'Payment Window',
                                                formik,
                                                'number'
                                            )}
                                        </Grid>
                                    )}
                                <Grid item xs="12">
                                    <FormControl fullWidth data-cy="multistep-dialog-promo-codes-input">
                                        <PromoCodesInput
                                            promos={promos}
                                            onApplyPromo={e => setPromos([e])}
                                            onRemovePromo={e => setPromos([])}
                                            isLastPickupStep={true}
                                            onCharityPreferred={charity =>
                                                _.set(formik, 'values.charityPreferred', charity)
                                            }
                                            donationDialogTitle="Set Donation Preference"
                                            donationDialogText="This promo code is locked to a specific charity. Do you want to set this user's donation preference?"
                                        />
                                    </FormControl>
                                    {_.get(promos, '[0].recurring', false) && (
                                        <Typography variant="caption" style={{ marginTop: theme.spacing.unit }}>
                                            This promo is recurring
                                        </Typography>
                                    )}
                                </Grid>
                                <CustomFormTitle
                                    titleText="Admin Overrides"
                                    iconName="manage_account"
                                    style={{ marginTop: theme.spacing.unit }}
                                />
                                <Grid item xs="12" sm="6">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={_.get(formik, 'values.multiplePickups.enabled')}
                                                onChange={e => {
                                                    _.set(formik, 'values.multiplePickups.enabled', e.target.checked);
                                                    setEnableMultipleBookings(e.target.checked);
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="Enable Multiple Bookings"
                                    />
                                </Grid>
                                {enableMultipleBookings && (
                                    <Grid item xs="12" sm="6">
                                        <TextField
                                            {...getFieldProps('multiplePickups.limit')}
                                            error={
                                                _.get(touched, 'multiplePickups.limit', false) &&
                                                _.get(errors, 'multiplePickups.limit', false)
                                                    ? true
                                                    : null
                                            }
                                            type="number"
                                            label="Pickup Limit"
                                            variant="outlined"
                                            helperText={
                                                _.get(touched, 'multiplePickups.limit', false) &&
                                                _.get(errors, 'name.last', false)
                                                    ? _.get(errors, 'multiplePickups.limit', false)
                                                    : null
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                )}
                                <Grid item xs="12">
                                    {getSwitch(
                                        theme,
                                        'bypassPickupThreshold',
                                        'Bypass Abort & Postpone Threshold',
                                        formik
                                    )}
                                </Grid>
                                {/* <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isFundraiser}
                                            onChange={() => {
                                                setFieldValue('fundraiserName', '');
                                                setIsFundraiser(!isFundraiser);
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Fundraiser"
                                />
                                {isFundraiser && (
                                    <>
                                        <CustomFormTitle
                                            titleText="Fundraiser Info"
                                            iconName="person"
                                            style={{ marginTop: theme.spacing.unit }}
                                        />
                                        <Grid item xs={12}>
                                            <TextField
                                                {...getFieldProps('fundraiserName')}
                                                error={
                                                    _.get(touched, 'fundraiserName', false) &&
                                                    _.get(errors, 'fundraiserName', false)
                                                        ? true
                                                        : null
                                                }
                                                label="Fundraiser Name"
                                                variant="outlined"
                                                helperText={
                                                    _.get(touched, 'fundraiserName', false) &&
                                                    _.get(errors, 'fundraiserName', false)
                                                        ? _.get(errors, 'fundraiserName', false)
                                                        : null
                                                }
                                                fullWidth
                                                autoComplete="new-fundraiserName"
                                            />
                                        </Grid>
                                    </>
                                )} */}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="secondary">
                                close
                            </Button>
                            <Button
                                disabled={isSubmitting || !_.isEmpty(errors) || !dirty}
                                onClick={handleSubmit}
                                color="primary"
                            >
                                register
                            </Button>
                        </DialogActions>
                    </Dialog>
                );
            }}
        </Formik>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(QuickRegisterForm));
