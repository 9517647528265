import React, { useMemo, useState, useContext, useRef } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';

import { formatAsCurrency, mapCodeToName, isLocalEnv } from 'utils/misc';

// custom components
import PromoCodesInput from 'components/InputComponents/PromoCodesInput';
import CountsScreen from 'components/BulkComponents/CountsScreen';
import CountsScreenV2 from 'components/BulkComponents/CountsScreenV2';
// npm components
import * as colors from '@material-ui/core/colors';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import { Badge, DialogActions, DialogContent, Divider, Tooltip } from '@material-ui/core';
import { Icon as MDIcon } from '@mdi/react';
import { mdiExclamationThick, mdiCheckBold, mdiTagArrowDown } from '@mdi/js';

import { _bulk, _rates, _user } from 'std';
import { _charity } from 'std';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import ReactPlayer from 'react-player';

import { getComEquivalent, shortenSubComName } from 'containers/Operators/PORScreen/NumberOfBagsDialog';
import * as allIcons from '@mdi/js';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { useEffect } from 'react';
// const localPlaceholderVideoURL = 'https://youtu.be/qgMfcq47h3M';
// const localPlaceholderVideoURL = 'https://www.w3schools.com/html/movie.mp4';
const localPlaceholderVideoURL =
    'https://local-std-pickup-photos.s3.amazonaws.com/clerk_videos/CV_5e744e80-8a9a-11ec-81fe-c9850d7b1e23.webm';

function CountsPanel({
    theme,
    history,
    inProgress,
    bulk,
    skus,
    grandTotal,
    totalCountFormAmount,
    promoCodes,
    disableEditing,
    customFeesForm,
    countTotal,
    onApplyPromo,
    onRemoveCustomFee,
    onRemovePromo,
    onRemoveCounts,
    onOpenReportDialog,
    onOpenComplaintDialog,
    onClearCountsAndFees,
    onDonation,
    onUpdateCommodityAmount,
    setIdleCountdown,
    cameraIsOn,
    children,
    http,
    operator,
    bagtag,
    recordFinished,
    customerUID,
    videoFilename,
    isEmployee,
    haveVideoPermission,
    resetTimeoutCount,
    // countsScreenToPrintRef,
    printingReceipt,
    setEditNumberOfBagsDialogOpen,
    hideCustomerIdentification,
    commodities,
    commodityColors,
    collector,
    setCurrentCountingSessionId,
    currentCountingSessionId,
    setCreateEditNumberOfCommoditiesDialogOpen,
    handleRemoveCountingSession,
    finishRecording,
    restartRecording,
    handleRemoveSessionlessCounts,
    handleRemoveCustomFeeFromSessionAndBulk,
    onImageDialog,
    setImages,
    setCreatEditNumberOfCommoditiesMode,
    handleShowErrorDialog
}) {
    const headerFooterColor = null;
    const padding = 2;
    const totalsFontSize = null;
    const isAdminOrCSR = _user.isSystemAdmin(operator) || _user.isInternalRole(operator);

    const { lang } = useContext(LocalizationContext);

    const warnAction = useContext(ConfirmDialogContext);
    const [displayTab, setDisplayTab] = useState('ledger');
    const heightOffset = theme.spacing.unit;

    const disabledBagCountEditing =
        operator.accountType === 'System Administrator'
            ? _bulk.isRedeemed(bulk) || _bulk.isInvoiced(bulk)
            : disableEditing;

    const [remainingRenderList, setRemainingRenderList] = useState([]);
    useEffect(() => {
        getRemainingRenderList();
    }, [commodities, bulk]);

    async function getRemainingRenderList() {
        const originalCommoditiesProcessedBreakdown = await getCommoditiesProcessedBreakdown();
        const currentSkuCom = _.find(commodities, {
            skuType: _.get(bulk, 'skuType', '')
        });
        let numOfSubCom = getComEquivalent(bulk, commodities);
        let numOfBags = _.get(bulk, 'commodityAmount', 0) - numOfSubCom;
        let sortedBags = _.get(_.find(originalCommoditiesProcessedBreakdown, { isSubCommodity: false }), 'amount', 0);
        let processedSubComList = _.filter(originalCommoditiesProcessedBreakdown, { isSubCommodity: true });
        let renderList = [];
        if (numOfBags - sortedBags > 0) {
            renderList.push({
                color: _.get(currentSkuCom, 'color', colors.blue[300]),
                icon: allIcons[_.get(currentSkuCom, 'iconName', '')],
                amount: numOfBags - sortedBags,
                name: shortenSubComName(_.get(bulk, 'commodityUOM', 'bag'), numOfBags - sortedBags)
            });
        }
        _.get(bulk, 'pickup.subPayloads', [])
            .filter(subPayload => subPayload.skuType === _.get(bulk, 'skuType', ''))
            .forEach(subCom => {
                let subComId = _.get(subCom, 'subCommodity', '');
                let amount = _.get(subCom, 'amount', '');
                let remainingSubCom =
                    amount - _.get(_.find(processedSubComList, { subCommodity: subComId }), 'amount', '');
                if (remainingSubCom < 1) return;
                let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
                let subCommodity = _.find(subCommodityLookup, subComLookupItem => {
                    return subComLookupItem._id === subComId;
                });
                let name = _.get(subCommodity, `name.${lang}`, '');
                renderList.push({
                    color: _.get(subCommodity, 'color', colors.blue[300]),
                    icon: allIcons[convertToImportName(_.get(subCommodity, 'iconName', ''))],
                    amount: remainingSubCom,
                    name: shortenSubComName(name, remainingSubCom)
                });
            });
        setRemainingRenderList(renderList);
    }
    async function getCommoditiesProcessedBreakdown() {
        const res = await http.getJSON(`/bulks/${_.get(bulk, '_id', '')}/getCommoditiesProcessedBreakdown`);
        if (res.ok) {
            return _.get(res, 'data.commoditiesProcessedBreakdown', []);
        }
        return [];
    }

    const [videoDetails, setVideoDetails] = React.useState([]);
    const [showVideoDialog, setShowVideoDialog] = React.useState(false);
    const [currentVideoDetail, setCurrentVideoDetail] = React.useState('');
    React.useEffect(() => {
        if (_.isNil(bulk)) return;
        if (recordFinished) {
            setTimeout(() => {
                getVideoDetails(operator._id, _bulk.getTruncatedLabel(bulk), _user.getUniqueID(bulk.owner));
            }, 1000);
        } else {
            getVideoDetails(operator._id, _bulk.getTruncatedLabel(bulk), _user.getUniqueID(bulk.owner));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operator, cameraIsOn, recordFinished, bulk]);

    async function getVideoDetails(clerkID, bagtag, customerUID) {
        const newVideoDetails = await http.getJSON(
            `/clerkVideo/getvideoDetails?clerkID=${clerkID}&bagtag=${bagtag}&customerUID=${customerUID}`,
            false,
            true
        );
        if (newVideoDetails.ok) {
            setVideoDetails(newVideoDetails.data || []);
        }
    }

    const [scrollBarWidth, setScrollBarWidth] = useState(0);
    const handleScrollBarWidth = width => {
        setScrollBarWidth(width);
    };

    const [collapseOutstandingBagtags, setCollapseOutstandingBagtags] = useState(false);
    const BagtagBadge = withStyles(theme => ({
        badge: {
            right: 4,
            top: 8,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px'
        }
    }))(Badge);

    const serviceFeesForSkuType = useMemo(() => {
        const collectorServiceFees = _.get(collector, 'configuration.serviceFees', []);
        return _.find(collectorServiceFees, { skuType: _.get(bulk, 'skuType') });
    }, [collector, bulk]);
    const breakdowns = useMemo(() => getBreakdowns(bulk, skus[bulk.skuType] || [], grandTotal + totalCountFormAmount), [
        bulk,
        grandTotal,
        skus
    ]);

    const headerFooterStyle = {
        fontSize: totalsFontSize,
        color: theme.palette.text.primary
    };

    const totalsTableCellStyle = {
        fontSize: '13px',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        lineHeight: 1
        // textAlign: 'center'
    };

    return (
        <Grid item md={4} xs={12} style={{ height: '100%', paddingBottom: 'env(safe-area-inset-bottom)' }}>
            <Paper
                elevation={1}
                style={{
                    padding: theme.spacing.unit,
                    marginBottom: theme.spacing.unit,
                    display: 'flex',
                    flexDirection: 'column',
                    height: `calc(100% - ${heightOffset}px)`
                }}
            >
                {displayTab === 'ledger' && (
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: theme.spacing.unit / 2
                            }}
                        >
                            <div
                                style={{
                                    flexWrap: 'wrap',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Typography
                                    style={{
                                        marginRight: 5,
                                        whiteSpace: 'nowrap',
                                        alignSelf: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    Remaining:
                                </Typography>
                                {remainingRenderList.length === 0 && (
                                    <Typography
                                        style={{
                                            whiteSpace: 'nowrap',
                                            alignSelf: 'center',
                                            justifyContent: 'center'
                                            // fontSize: 11
                                        }}
                                    >
                                        Nothing
                                    </Typography>
                                )}
                                {(remainingRenderList || []).map(renderItem => {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: 'max-content',
                                                flexDirection: 'row',
                                                marginRight: 5
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: 'center',
                                                    justifyContent: 'center',
                                                    height: 16
                                                }}
                                            >
                                                <MDIcon
                                                    path={_.get(renderItem, 'icon', '')}
                                                    size={0.7}
                                                    color={_.get(renderItem, 'color', '')}
                                                />
                                            </div>
                                            <Typography
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    alignSelf: 'center',
                                                    justifyContent: 'center'
                                                    // fontSize: 11
                                                }}
                                            >
                                                {_.get(renderItem, 'amount', '')} {_.get(renderItem, 'name', '')}
                                            </Typography>
                                        </div>
                                    );
                                })}
                            </div>
                            {_.get(bulk, 'adjustmentReason', '') !== 'Payment Processed' && (
                                <Button
                                    variant="contained"
                                    style={{
                                        fontSize: 12,
                                        minWidth: '126px',
                                        maxWidth: '126px',
                                        minHeight: '26px',
                                        maxHeight: '26px',
                                        padding: theme.spacing.unit,
                                        marginRight: scrollBarWidth + theme.spacing.unit * 2
                                    }}
                                    color="primary"
                                    onClick={() => {
                                        setCreatEditNumberOfCommoditiesMode('create');
                                        setCreateEditNumberOfCommoditiesDialogOpen(true);
                                    }}
                                    data-cy="newSession"
                                    disabled={_bulk.isCompleted(bulk)}
                                >
                                    Sort More Items
                                </Button>
                            )}
                        </div>
                        <Divider style={{ marginTop: theme.spacing.unit / 2 }} />
                        {bulk && !_.isEmpty(bulk.outstandingBagtags) && (
                            <Card
                                elevation={0}
                                style={{
                                    marginTop: theme.spacing.unit,
                                    border: '1px solid',
                                    borderColor: theme.palette.grey[300],
                                    maxHeight: '100%',
                                    position: 'relative',
                                    padding: '8px'
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <BagtagBadge badgeContent={bulk.outstandingBagtags.length} color="primary">
                                            <MDIcon
                                                path={mdiTagArrowDown}
                                                size={0.9}
                                                color={colors.grey[500]}
                                                style={{ margin: theme.spacing.unit / 2 }}
                                            />
                                        </BagtagBadge>

                                        <Typography style={{ marginLeft: theme.spacing.unit * 1.5 }}>
                                            Unprocessed Printed Bagtags
                                        </Typography>
                                    </div>
                                    <IconButton
                                        style={{ padding: theme.spacing.unit / 2 }}
                                        onClick={() => setCollapseOutstandingBagtags(!collapseOutstandingBagtags)}
                                    >
                                        <Icon>
                                            {collapseOutstandingBagtags ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                        </Icon>
                                    </IconButton>
                                </div>
                                <Collapse in={collapseOutstandingBagtags}>
                                    <Divider style={{ margin: theme.spacing.unit, marginLeft: 0, marginBottom: 0 }} />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            flexDirection: 'row',
                                            marginTop: theme.spacing.unit * 1.5
                                        }}
                                    >
                                        {bulk.outstandingBagtags.map(label => {
                                            return (
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div
                                                        style={{
                                                            height: 'auto',
                                                            width: 'auto',
                                                            marginTop: theme.spacing.unit / 4,
                                                            marginLeft: theme.spacing.unit / 2,
                                                            marginRight: theme.spacing.unit / 2
                                                        }}
                                                    >
                                                        <MDIcon
                                                            path={mdiTagArrowDown}
                                                            size={0.6}
                                                            color={colors.grey[500]}
                                                        />
                                                    </div>
                                                    <Tooltip
                                                        title={`Printed: ${moment(label.datePrinted).format(
                                                            'MMM Do h:mm a'
                                                        )} (${label.collectorCode || 'Unknown Collector'})`}
                                                    >
                                                        <Typography
                                                            style={{
                                                                fontSize: '12px',
                                                                color: label.bulkId
                                                                    ? theme.palette.linkColor
                                                                    : 'default',
                                                                cursor: label.bulkId ? 'pointer' : 'default'
                                                            }}
                                                            onClick={() => {
                                                                if (_.isEmpty(label.bulkId)) return;

                                                                history.push(
                                                                    `/operators/${operator._id}/bulks/${label.bulkId}`
                                                                );
                                                            }}
                                                        >
                                                            {label.bagtag}
                                                        </Typography>
                                                    </Tooltip>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Collapse>
                            </Card>
                        )}
                        <div style={{ flexGrow: 1, height: 0, overflow: 'auto' }}>
                            <CountsScreenV2
                                bulk={bulk}
                                disableEditing={disableEditing}
                                inProgress={inProgress}
                                customFeesForm={customFeesForm}
                                skus={skus}
                                // countTotal={countTotal}
                                // grandTotal={grandTotal}
                                // totalCountFormAmount={totalCountFormAmount}
                                onRemoveCounts={onRemoveCounts}
                                onRemoveCustomFee={onRemoveCustomFee}
                                onClearCountsAndFees={onClearCountsAndFees}
                                setIdleCountdown={setIdleCountdown}
                                cameraIsOn={cameraIsOn}
                                resetTimeoutCount={resetTimeoutCount}
                                printingReceipt={printingReceipt}
                                commodityColors={commodityColors}
                                setCurrentCountingSessionId={setCurrentCountingSessionId}
                                commodities={commodities}
                                lang={lang}
                                currentCountingSessionId={currentCountingSessionId}
                                operator={operator}
                                collector={collector}
                                promoCodes={promoCodes}
                                setCreateEditNumberOfCommoditiesDialogOpen={setCreateEditNumberOfCommoditiesDialogOpen}
                                handleRemoveCountingSession={handleRemoveCountingSession}
                                handleRemoveSessionlessCounts={handleRemoveSessionlessCounts}
                                handleRemoveCustomFeeFromSessionAndBulk={handleRemoveCustomFeeFromSessionAndBulk}
                                onImageDialog={onImageDialog}
                                setImages={setImages}
                                handleScrollBarWidth={handleScrollBarWidth}
                                setCreatEditNumberOfCommoditiesMode={setCreatEditNumberOfCommoditiesMode}
                                // ref={countsScreenToPrintRef}
                            />
                        </div>
                        <Divider style={{ marginTop: theme.spacing.unit }} />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: theme.spacing.unit,
                                paddingTop: 0,
                                paddingBottom: theme.spacing.unit / 2,
                                marginRight: scrollBarWidth
                            }}
                        >
                            <div
                                style={{ display: 'flex', flexDirection: 'column', width: '50%', textAlign: 'center' }}
                            >
                                <Typography style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                    {_.get(bulk, 'rates.name', '')}
                                </Typography>
                                {!printingReceipt && (
                                    <Typography
                                        style={{ ...headerFooterStyle, fontWeight: 'bold', fontSize: '12px' }}
                                        noWrap
                                    >
                                        {getValuePerBag(countTotal, bulk, commodities)} /{' '}
                                        {_bulk.getBulkUnitOfMeasurement(bulk)}
                                    </Typography>
                                )}
                            </div>
                            <table
                                style={{
                                    // tableLayout: 'fixed',
                                    width: '100%',
                                    // padding: '2px',
                                    // paddingRight: 0,
                                    paddingTop: '8px',
                                    paddingRight: '8px'
                                }}
                            >
                                <tbody>
                                    {breakdowns.map((breakdown, index) => {
                                        return (
                                            <tr key={breakdown.title}>
                                                <td style={{ width: '50%' }}>
                                                    <Typography style={{ ...totalsTableCellStyle, textAlign: 'right' }}>
                                                        {breakdown.title}:
                                                    </Typography>
                                                </td>
                                                <td style={{ width: '25%' }}>
                                                    <Typography style={{ ...totalsTableCellStyle, textAlign: 'right' }}>
                                                        {Math.floor(breakdown.quantity)}
                                                    </Typography>
                                                </td>
                                                <td style={{ width: '25%' }}>
                                                    <Typography
                                                        style={{
                                                            ...totalsTableCellStyle,
                                                            textAlign: 'right',
                                                            color:
                                                                breakdown.value < 0
                                                                    ? theme.palette.error.main
                                                                    : theme.palette.text.primary
                                                        }}
                                                        data-cy={`counts-panel-breakdown-value-${breakdown.title}`}
                                                    >
                                                        {formatAsCurrency(breakdown.value)}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {isAdminOrCSR && (
                            <AdminBreakdownSection
                                bulk={bulk}
                                skus={skus}
                                promoCodes={promoCodes}
                                serviceFeesForSkuType={serviceFeesForSkuType}
                                headerFooterStyle={headerFooterStyle}
                                totalsTableCellStyle={totalsTableCellStyle}
                                padding={padding}
                                scrollBarWidth={scrollBarWidth}
                            />
                        )}
                    </div>
                )}
                {displayTab !== 'ledger' && (
                    <List
                        style={{
                            marginTop: -10,
                            marginBottom: -12,
                            flex: `1 1 auto`,
                            overflowY: 'scroll',
                            overflowX: 'hidden'
                        }}
                    >
                        <VideoFilenameDisplay
                            cameraIsOn={cameraIsOn}
                            recordFinished={recordFinished}
                            bulk={bulk}
                            videoFilename={videoFilename}
                        />
                        {videoDetails.map(videoDetail => {
                            let videoURL = videoDetail.videoURL;
                            let SplitVideoURL = videoURL.split(/[\\/]/);
                            let videoName = SplitVideoURL[SplitVideoURL.length - 1];
                            return (
                                <ListItem style={{ height: '20px', marginTop: -3 }}>
                                    <ListItemAvatar>
                                        <IconButton
                                            style={{ height: '3px' }}
                                            onClick={() => {
                                                setCurrentVideoDetail(videoDetail);
                                                setShowVideoDialog(true);
                                                resetTimeoutCount();
                                            }}
                                        >
                                            <Icon style={{ fontSize: '18px', color: 'grey' }}>visibility</Icon>
                                        </IconButton>
                                    </ListItemAvatar>
                                    <ListItemText
                                        style={{ fontSize: 11 }}
                                        primary={videoName}
                                        primaryTypographyProps={{
                                            variant: 'subtitle2',
                                            style: {
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }
                                        }}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                )}
                {_bulk.hasErrors(bulk) && (
                    <List>
                        <ListItem
                            style={{
                                backgroundColor: _bulk.hasUnresolvedErrors(bulk) ? colors.red[100] : colors.green[100],
                                borderRadius: 2
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                                    <MDIcon
                                        path={_bulk.hasUnresolvedErrors(bulk) ? mdiExclamationThick : mdiCheckBold}
                                        size={1}
                                        color={_bulk.hasUnresolvedErrors(bulk) ? colors.red[300] : colors.green[300]}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                secondaryTypographyProps={{
                                    noWrap: true,
                                    style: { marginRight: theme.spacing.unit * 11 }
                                }}
                                primary={_bulk.hasUnresolvedErrors(bulk) ? 'Unresolved Errors' : 'Resolved Errors'}
                                // secondary={
                                //     _bulk.hasUnresolvedErrors(bulk)
                                //         ? 'Press resolve to view errors'
                                //         : 'Press edit to view errors'
                                // }
                            />
                            <ListItemSecondaryAction>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    disabled={inProgress}
                                    size="small"
                                    onClick={() => {
                                        handleShowErrorDialog(true);
                                        resetTimeoutCount();
                                    }}
                                >
                                    {_bulk.hasUnresolvedErrors(bulk) ? 'resolve' : 'view'}
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                )}
                {_bulk.hasCustomerIssue(bulk) && _bulk.isRedeemed(bulk) && (
                    <List>
                        <ListItem
                            style={{
                                backgroundColor: _bulk.hasUnresolvedCustomerIssue(bulk)
                                    ? colors.red[100]
                                    : colors.green[100],
                                borderRadius: 2
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                                    <MDIcon
                                        path={
                                            _bulk.hasUnresolvedCustomerIssue(bulk) ? mdiExclamationThick : mdiCheckBold
                                        }
                                        size={1}
                                        color={
                                            _bulk.hasUnresolvedCustomerIssue(bulk) ? colors.red[300] : colors.green[300]
                                        }
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                secondaryTypographyProps={{
                                    noWrap: true,
                                    style: { marginRight: theme.spacing.unit * 11 }
                                }}
                                primary={
                                    _bulk.hasUnresolvedCustomerIssue(bulk)
                                        ? 'Unresolved Complaint'
                                        : 'Resolved Complaint'
                                }
                                secondary={_bulk.getCustomerComplaint(bulk)}
                            />
                            <ListItemSecondaryAction>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    disabled={inProgress}
                                    size="small"
                                    onClick={() => {
                                        onOpenComplaintDialog();
                                        resetTimeoutCount();
                                    }}
                                >
                                    {_bulk.hasUnresolvedCustomerIssue(bulk) ? 'resolve' : 'edit'}
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                )}

                {!hideCustomerIdentification && (
                    <PromoCodesInput
                        customer={bulk.owner}
                        bypassNewCustomersCheck={true}
                        allowSystemPromos={true} // flag that allows more promo codes
                        promos={promoCodes}
                        disabled={inProgress || disableEditing}
                        onApplyPromo={promo => {
                            onApplyPromo(promo);
                            resetTimeoutCount();
                        }}
                        onRemovePromo={promo => {
                            warnAction(() => {
                                resetTimeoutCount();
                                return onRemovePromo(promo, bulk.isLostAndFound);
                            }, 'Are you sure you want to remove this promo code?');
                            resetTimeoutCount();
                        }}
                        charityPreferred={_bulk.getCharityId(bulk)}
                        donate={_bulk.isDonating(bulk)}
                        onCharityPreferred={onDonation}
                    />
                )}
            </Paper>
            {_user.isSystemAdmin(operator) && (
                <VideoDialog
                    open={showVideoDialog}
                    onClose={() => {
                        setShowVideoDialog(false);
                        resetTimeoutCount();
                    }}
                    currentVideoDetail={currentVideoDetail}
                    commodities={commodities}
                    bulk={bulk}
                    lang={lang}
                    theme={theme}
                />
            )}
        </Grid>
    );
}
export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(CountsPanel));

function VideoDialog(props) {
    const { open, onClose, currentVideoDetail, commodities, bulk, lang, theme } = props;

    const [countsArray, setCountsArray] = React.useState([]);
    const [videoDuration, setVideoDuration] = React.useState(0);
    const playerRef = React.useRef(null);
    if ((videoDuration === 0 || videoDuration === Infinity) && playerRef.current !== null) {
        playerRef.current.seekTo(1e101, 'seconds');
        setTimeout(() => {
            playerRef.current.seekTo(0, 'seconds');
            setVideoDuration(playerRef.current.getDuration());
        }, 100);
    }
    React.useEffect(() => {
        try {
            setVideoDuration(0);
            if (!_.isNil(currentVideoDetail.countDifference)) {
                let parsedCountDifference = JSON.parse(currentVideoDetail.countDifference);
                let newCountsArray = [];
                for (const sku in parsedCountDifference) {
                    newCountsArray.push(parsedCountDifference[sku]);
                }
                setCountsArray(newCountsArray);
            }
        } catch (error) {
            console.log('VideoDialog error: ', error);
        }
    }, [currentVideoDetail]);

    const countFontSize = null;
    const padding = 0;
    const headerFooterColor = null;
    const headerFooterTextColor = theme.palette.text.primary;
    const totalsFontSize = null;
    const countsStyle = { fontSize: countFontSize, paddingLeft: padding };
    const headerFooterStyle = {
        fontSize: totalsFontSize,
        color: headerFooterTextColor
    };
    const commoditiesProcessedBreakdown = _.get(currentVideoDetail, 'commoditiesProcessedBreakdown', []);
    return (
        <Dialog open={open} onClose={onClose} disableBackdropClick maxWidth={'md'}>
            <DialogContent>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    {!_.isNil(currentVideoDetail.videoURL) && currentVideoDetail.videoURL !== '' ? (
                        <ReactPlayer
                            url={isLocalEnv ? localPlaceholderVideoURL : currentVideoDetail.videoURL}
                            onDuration={duration => {
                                setVideoDuration(duration);
                            }}
                            controls={true}
                            ref={playerRef}
                        />
                    ) : null}{' '}
                    <Grid item md={4} xs={12} style={{ height: '100%', marginLeft: 20 }}>
                        <div style={{ overflowY: 'auto', flex: `1 1 auto` }} id="custom-scroller">
                            <Typography
                                style={{
                                    fontSize: 16,
                                    textAlign: 'center'
                                }}
                            >
                                Details
                            </Typography>
                            <Typography style={{}}>
                                Duration: {videoDuration !== Infinity ? videoDuration + ' s' : 'calculating...'}
                            </Typography>
                            {!_.isNil(currentVideoDetail.fileSize) && (
                                <Typography style={{}}>
                                    File size: {(currentVideoDetail.fileSize / 1048576).toFixed(2) + 'MB'}
                                </Typography>
                            )}
                            {!_.isNil(currentVideoDetail.bags) && !_.isEmpty(currentVideoDetail.bags) && (
                                <Typography style={{}}>Bags: {currentVideoDetail.bags}</Typography>
                            )}
                            <Typography
                                style={{
                                    marginBottom: 10
                                }}
                            >
                                Date recorded: {moment(currentVideoDetail.date).format('ddd MMM D YYYY')}
                            </Typography>
                            {commoditiesProcessedBreakdown.length > 0 && (
                                <>
                                    <Typography
                                        style={{
                                            fontSize: 16,
                                            textAlign: 'center',
                                            marginBottom: 10
                                        }}
                                    >
                                        Commodities
                                    </Typography>
                                    <table
                                        style={{
                                            width: '100%',
                                            fontSize: countFontSize,
                                            border: 'none',
                                            borderSpacing: padding,
                                            borderCollapse: 'collapse'
                                        }}
                                    >
                                        <thead style={{ backgroundColor: headerFooterColor }}>
                                            <tr
                                                style={{
                                                    textDecoration: 'underline',
                                                    fontSize: countFontSize,
                                                    color: headerFooterTextColor
                                                }}
                                            >
                                                <td style={{ padding }}>
                                                    <Typography style={headerFooterStyle}>Item</Typography>
                                                </td>
                                                <td style={{ padding }}>
                                                    <Typography style={headerFooterStyle}>Qty</Typography>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody style={{ backgroundColor: 'white' }}>
                                            {commoditiesProcessedBreakdown.map((item, j) => {
                                                const epicStyle = {
                                                    // borderBottom: isLastItemInCount ? `1px solid ${colors.grey[300]}` : null
                                                };

                                                const currentSkuCom = _.find(commodities, {
                                                    skuType: _.get(bulk, 'skuType', '')
                                                });
                                                let amount = _.get(item, 'amount', 0);
                                                let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
                                                let subCommodity = _.find(subCommodityLookup, {
                                                    _id: _.get(item, 'subCommodity', '')
                                                });
                                                let name = _.get(subCommodity, `name.${lang}`, '');
                                                let shortenName = item.isSubCommodity
                                                    ? shortenSubComName(name, amount)
                                                    : shortenSubComName(_.get(bulk, 'commodityUOM', 'Bag'), amount);

                                                return (
                                                    <tr
                                                        key={`${item.shortenName}-${j}`}
                                                        style={{
                                                            marginBottom: 5,
                                                            paddingBottom: 5
                                                        }}
                                                    >
                                                        <td style={epicStyle}>
                                                            <Typography style={countsStyle}>{shortenName}</Typography>
                                                        </td>
                                                        <td style={epicStyle}>
                                                            <Typography style={countsStyle}>{amount}</Typography>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </>
                            )}
                            <Typography
                                style={{
                                    fontSize: 16,
                                    textAlign: 'center',
                                    marginBottom: 10
                                }}
                            >
                                Counts
                            </Typography>
                            <table
                                style={{
                                    width: '100%',
                                    fontSize: countFontSize,
                                    border: 'none',
                                    borderSpacing: padding,
                                    borderCollapse: 'collapse'
                                }}
                            >
                                <thead style={{ backgroundColor: headerFooterColor }}>
                                    <tr
                                        style={{
                                            textDecoration: 'underline',
                                            fontSize: countFontSize,
                                            color: headerFooterTextColor
                                        }}
                                    >
                                        <td style={{ padding }}>
                                            <Typography style={headerFooterStyle}>Item</Typography>
                                        </td>
                                        <td style={{ padding }}>
                                            <Typography style={headerFooterStyle}>Qty</Typography>
                                        </td>
                                        <td style={{ padding }}>
                                            <Typography style={headerFooterStyle}>Amt</Typography>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody style={{ backgroundColor: 'white' }}>
                                    {countsArray.map((item, j) => {
                                        const epicStyle = {
                                            // borderBottom: isLastItemInCount ? `1px solid ${colors.grey[300]}` : null
                                        };

                                        return (
                                            <tr
                                                key={`${item.description}-${j}`}
                                                style={{
                                                    marginBottom: 5,
                                                    paddingBottom: 5
                                                }}
                                            >
                                                <td style={epicStyle}>
                                                    <Typography style={countsStyle}>
                                                        {item.description} {item.size}
                                                    </Typography>
                                                </td>
                                                <td style={epicStyle}>
                                                    <Typography style={countsStyle}>{item.quantity}</Typography>
                                                </td>
                                                <td style={epicStyle}>
                                                    <Typography style={countsStyle}>
                                                        {formatAsCurrency(item.value || 0)}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div id="custom-anchor" />
                        </div>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" size="small" onClick={onClose}>
                    close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function VideoFilenameDisplay(props) {
    const { cameraIsOn, recordFinished, bulk, videoFilename } = props;
    const countIsFinished = !_.isNil(bulk) && !_.isNil(bulk.dateCompleted);
    if (countIsFinished) {
        return null;
    }
    if (recordFinished) {
        return null;
    }
    if (cameraIsOn) {
        return (
            <ListItem style={{ height: '20px', marginTop: -3 }}>
                <ListItemAvatar>
                    <IconButton disabled style={{ height: '3px' }}>
                        <Icon style={{ fontSize: '18px', color: colors.green[700] }}>videocam</Icon>
                    </IconButton>
                </ListItemAvatar>
                <ListItemText
                    style={{ fontSize: 11 }}
                    primary={videoFilename}
                    primaryTypographyProps={{
                        variant: 'subtitle2',
                        style: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }
                    }}
                />
            </ListItem>
        );
    }
    return null;
}

function getBulkReportedIssues(bulk, options) {
    const issues = _.get(bulk, 'payloadIssues.issues', []);
    if (_.isEmpty(issues)) {
        return ['Press here to report the customer for garbage, ineligible containers, etc'];
    } else {
        const isLegacyIssueFormat = issues.some(issue => issue.length > 4);
        return isLegacyIssueFormat ? issues : mapCodeToName(options, issues);
    }
}

function convertToImportName(iconName) {
    return _.camelCase(`mdi-${iconName}`);
}

const AdminBreakdownSection = function({
    bulk,
    skus,
    promoCodes,
    serviceFeesForSkuType,
    headerFooterStyle,
    totalsTableCellStyle,
    scrollBarWidth
}) {
    const generatedFees = _.get(bulk, 'customFees', []).filter(fee => {
        return _.get(fee, 'generatedOnBulkCompletion', false);
    });

    const rates = _.get(bulk, 'rates.rates', []);
    const depositTotal = useMemo(() => {
        let depositTotal = 0;
        _.get(bulk, 'counts', []).forEach(count => {
            _.get(count, 'items', []).forEach(item => {
                const rate = _.find(rates, { sku: _.get(item, 'sku') });
                if (rate) {
                    depositTotal += _.get(rate, 'value') * -1 * _.get(item, 'quantity', 0);
                }
            });
        });
        return depositTotal;
    }, [bulk, rates]);

    const promo = _.first(promoCodes);
    const serviceFeeAmount = calculateServiceFeeAmount(
        serviceFeesForSkuType,
        depositTotal,
        _.get(bulk, 'counts', []),
        rates,
        !_.isNil(bulk.charity),
        promo
    );

    let discount = 0;
    if (!_.isNil(promo)) {
        const discountRatio = 1.0 - _.get(promo, 'variables.customerSplit', 0);
        discount = Math.round(discountRatio * 100);
    }

    return (
        <>
            <Divider />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '4px 8px',
                    marginTop: '4px',
                    marginRight: scrollBarWidth + '8px'
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', width: '50%', textAlign: 'center' }}>
                    <Typography style={{ ...totalsTableCellStyle }}>Admin Info</Typography>
                </div>
                <table
                    style={{
                        // tableLayout: 'fixed',
                        width: '100%'
                        // padding: '2px',
                        // paddingRight: 0,
                        // paddingTop: '8px',
                        // paddingRight: '8px'
                    }}
                >
                    <tbody>
                        {generatedFees.map((fee, i) => (
                            <tr key={i}>
                                <td style={{ width: '50%' }}>
                                    <Typography style={{ ...totalsTableCellStyle, textAlign: 'right' }}>
                                        {_.get(fee, 'description', '')}:
                                    </Typography>
                                </td>
                                <td style={{ width: '25%' }}>
                                    <Typography style={{ ...totalsTableCellStyle, textAlign: 'right' }} />
                                </td>
                                <td style={{ width: '25%' }}>
                                    <Typography style={{ ...totalsTableCellStyle, textAlign: 'right' }}>
                                        {formatAsCurrency(-_.get(fee, 'amount'))}
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td style={{ width: '50%' }}>
                                <Typography style={{ ...totalsTableCellStyle, textAlign: 'right' }}>
                                    Service Fee
                                    {!_.isNil(promo) && <> (promocode {discount}%)</>}:
                                </Typography>
                            </td>
                            <td style={{ width: '25%' }}>
                                <Typography style={{ ...totalsTableCellStyle, textAlign: 'right' }} />
                            </td>
                            <td style={{ width: '25%' }}>
                                <Typography style={{ ...totalsTableCellStyle, textAlign: 'right' }}>
                                    {formatAsCurrency(-serviceFeeAmount)}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

function getBreakdowns(bulk, skus, grandTotal) {
    const nonGeneratedFees = _.get(bulk, 'customFees', []).filter(fee => {
        return !_.get(fee, 'generatedOnBulkCompletion', false);
    });

    const [feesAmount, paymentsAmount] = nonGeneratedFees.reduce(
        (arr, { amount }) => {
            if (amount > 0) {
                arr[0] = arr[0] + amount;
            } else if (amount < 0) {
                arr[1] = arr[1] + amount;
            }

            return arr;
        },

        [0, 0]
    );

    const feesBreakdown = {
        title: 'Fees',
        quantity: nonGeneratedFees.filter(({ amount }) => amount > 0).length,
        value: -feesAmount
    };

    const paymentsBreakdown = {
        title: 'Payments',
        quantity: nonGeneratedFees.filter(({ amount }) => amount < 0).length,
        value: -paymentsAmount
    };

    const rates = _.get(bulk, 'rates.rates', []);
    const skusToIgnoreForTotal = _rates.getIgnoredSkusForTotals(rates);
    const totalBreakdown = {
        title: 'Total',
        quantity: _bulk.getCountsTotalItemCount(bulk, skusToIgnoreForTotal),
        value: grandTotal
    };

    let breakdowns = [totalBreakdown];

    if (!_.isEmpty(skus)) {
        const allSkuLabels = _(skus)
            .groupBy('label')
            .keys()
            .value();

        // only show more lines if theres more than 1 label types
        if (allSkuLabels.length > 1) {
            const flattenedAndGroupedCountsObj = _.groupBy(
                _.get(bulk, 'counts', []).reduce((array, count) => (array = array.concat(count.items)), []),
                'size'
            );

            for (let label of allSkuLabels) {
                if (!_.isEmpty(label)) {
                    let quantity = 0;
                    let value = 0;

                    const flattenedAndGroupedCountsArr = flattenedAndGroupedCountsObj[label];
                    if (!_.isEmpty(flattenedAndGroupedCountsArr)) {
                        for (let flatCounts of flattenedAndGroupedCountsArr) {
                            quantity += flatCounts.quantity;

                            const sku = _(skus)
                                .filter(sku => sku.sku === flatCounts.sku)
                                .value();

                            value += flatCounts.quantity * _.first(sku).value * -1; // skus values are inverted
                        }
                    }

                    const newBreakdown = {
                        title: label,
                        quantity,
                        value
                    };

                    breakdowns.push(newBreakdown);
                }
            }
        }
    }

    if (!_.isEmpty(nonGeneratedFees)) {
        breakdowns.push(feesBreakdown);
        breakdowns.push(paymentsBreakdown);
    }

    return breakdowns;
}

function getValuePerBag(countTotal, bulk, commodities) {
    const currentSkuCom = _.find(commodities, {
        skuType: _.get(bulk, 'skuType', '')
    });
    let commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
    let sortedBags = _.get(_.find(commoditiesProcessedBreakdown, { isSubCommodity: false }), 'amount', 0);
    let processedSubComList = _.filter(commoditiesProcessedBreakdown, { isSubCommodity: true });
    let subComToBagEquiv = 0;
    processedSubComList.forEach(subCom => {
        let subComId = _.get(subCom, 'subCommodity', '');
        let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
        let subCommodity = _.find(subCommodityLookup, { _id: subComId });
        subComToBagEquiv += Math.ceil(_.get(subCom, 'amount', 0) * _.get(subCommodity, 'multiplier', 0));
    });
    if (sortedBags + subComToBagEquiv > 0) {
        return formatAsCurrency(countTotal / (sortedBags + subComToBagEquiv));
    } else {
        return '?';
    }
}

function calculateServiceFeeAmount(serviceFee, totalValue, counts, countRates, donated, promo) {
    if (_.get(serviceFee, 'type', '') === 'Count Split') {
        let totalApplicableQuantity = 0;
        counts.forEach(count => {
            _.get(count, 'items', []).forEach(item => {
                const rate = _.find(countRates, { sku: count.sku });
                if (!_.isNil(rate) && rate.skuType === serviceFee.skuType && count.sku === serviceFee.sku) {
                    totalApplicableQuantity += count.quantity;
                }
            });
        });
        let customerReturns;
        if (!_.isNil(promo)) {
            const rate = _.find(countRates, { sku: serviceFee.sku });
            const newSplit = Math.abs(rate.value) * _.get(promo, 'variables.customerSplit', 0);
            customerReturns = totalApplicableQuantity * newSplit;
        } else {
            customerReturns =
                totalApplicableQuantity *
                (donated ? _.get(serviceFee, 'split.charity', 0) : _.get(serviceFee, 'split.customer', 0));
        }
        return _.round(Math.abs(totalValue) - customerReturns);
    } else {
        if (!_.isNil(promo)) {
            return _.round((1.0 - _.get(promo, 'variables.customerSplit', 0)) * Math.abs(totalValue)) || 0;
        } else {
            return _.round(_.get(serviceFee, 'value', 0) * Math.abs(totalValue)) || 0;
        }
    }
}
