import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';

import {
    Avatar,
    Button,
    Collapse,
    colors,
    Icon,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Tooltip,
    Typography
} from '@material-ui/core';

import { mdiCheckboxMarkedCircleOutline, mdiCircleOffOutline, mdiSync } from '@mdi/js';
import MDIcon from '@mdi/react';
import * as allIcons from '@mdi/js';

import DropLocationForm from './Forms/DropLocationForm';

import CRUDTable from './CRUDTable';
import useCRUD from './hooks/useCRUD';

import { DAYS_OF_THE_WEEK, DROP_OFF_TYPES, maxFileSize } from 'constants.js';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { useContext } from 'react';

function DropLocationsTable(props) {
    const {
        http,
        google,
        operator,
        collectors,
        onSnackbar,
        theme,
        unmappedIntegrationData,
        reloadUnmappedData
    } = props;

    const warnAction = useContext(ConfirmDialogContext);

    const endpoints = {
        getEndPoint: '/dropLocations/allDropLocations', // url to get all documents
        getEditEndPoint: _id => `/dropLocations/${_id}/updateDropLocation`, // url to edit documents NOTE: this should be a function
        createEndPoint: `/dropLocations/createDropLocation` // url to add new documents
    };

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState(null);
    const [activeOrder, setActiveOrder] = useState('asc');

    const [commodities, setCommodities] = useState([]);

    const [importFile, setImportFile] = useState(undefined);
    const [importing, setImporting] = useState(false);
    const [importReports, setImportReports] = useState([]);
    const [expandedReports, setExpandedReports] = useState([]);

    const [syncErrorsFound, setSyncErrorsFound] = useState(false);

    const {
        data,
        otherStats,
        startDateFilterStats,
        endDateFilterStats,
        loading,
        setLoading,
        setStartDateFilterStats,
        setEndDateFilterStats,
        dateFilterStatsErrorMessage,
        defaults,
        handleCreate,
        handleReloadData
    } = useCRUD({
        endpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    const updatedData = data.map(item => {
        const collectorId = item.collector;
        const collectorObject = collectors.find(collector => collector._id === collectorId);
        return {
            ...item,
            collector: !_.isNil(collectorObject) ? collectorObject : collectorId
        };
    });

    const handleEdit = async (values, markerFile = undefined) => {
        setLoading(true);
        let formAdjusted = _.cloneDeep(values);

        const formData = new FormData(); // NB: FormData objects cannot be displayed in console.log
        if (!_.isNil(markerFile)) {
            formData.append('markerFile', markerFile); //will show up in req.files
        }

        formData.append('form', JSON.stringify(formAdjusted)); // will show up in req.body
        let resUpdate = await http.postJSON(`/dropLocations/${values._id}/updateDropLocation`, formData, true, true); //second true is necessary to parse the form
        if (resUpdate.ok) {
            await handleReloadData();
            setEditDialogOpen(false);
        } else {
            onSnackbar(resUpdate.errorMessage, 'error');
        }
        setLoading(false);
    };

    const onChangeEnabled = async dropLocation => {
        setLoading(true);
        dropLocation.enabled = !dropLocation.enabled;

        const formData = new FormData();
        formData.append('form', JSON.stringify(dropLocation));
        const res = await http.post(`/dropLocations/${dropLocation._id}/updateDropLocation`, formData, false, true);
        if (res.ok) {
            await handleReloadData();
            onSnackbar(`Technology ${dropLocation.enabled ? 'Enabled' : 'Disabled'}`);
        }

        setLoading(false);
    };

    const handleTechnologySync = async dropLocation => {
        setLoading(true);
        const res = await http.getJSON(`/dropLocations/${dropLocation._id}/sync`);
        if (res.ok) {
            await handleReloadData();
        } else {
            onSnackbar('Request failed', 'error');
        }
        setLoading(false);
    };

    const columns = [
        {
            key: 'collector',
            header: 'Collector',
            formatValue: dropLocation => {
                return dropLocation.name;
            }
        },
        {
            key: 'name',
            header: 'Name',
            formatValue: (name, collector) => {
                // if (_.get(collector, 'readOnly', false)) {
                if (false) {
                    return (
                        <>
                            {name} <span style={{ color: theme.palette.text.secondary }}>(read only)</span>
                        </>
                    );
                } else {
                    return name;
                }
            }
        },
        {
            key: 'payloadAccepted',
            header: 'Commodities',
            formatValue: (value, column) => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {isTransporterCollector(_.get(column, 'collector._id'), collectors) &&
                        payloadMismatch(
                            value,
                            _.get(_.find(collectors, { _id: _.get(column, 'collector') }), 'payloadAccepted')
                        ) && (
                            <Tooltip
                                title="Collector/Technology commodities not equal"
                                disableFocusListener
                                disableTouchListener
                            >
                                <MDIcon path={_.get(allIcons, 'mdiAlert')} size={1} color={colors.yellow[700]} />
                            </Tooltip>
                        )}
                    {Object.assign([], value)
                        .sort()
                        .map(commodityId => {
                            const commodity = _.find(commodities, { _id: commodityId });
                            const commodityIcon = _.get(commodity, 'iconName');
                            return (
                                <MDIcon
                                    path={_.get(allIcons, commodityIcon)}
                                    size={1}
                                    color={_.get(commodity, 'color')}
                                />
                            );
                        })}
                </span>
            )
        },
        {
            key: 'dropOffType',
            header: 'Drop Off Type',
            formatValue: (value, column) => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {!_.isNil(value) && (
                        <Tooltip title={value}>
                            <Icon style={{ color: theme.palette.primary.main }}>
                                {_.get(_.find(DROP_OFF_TYPES, t => t.value === value), 'icon', '')}
                            </Icon>
                        </Tooltip>
                    )}
                </span>
            )
        },
        {
            key: 'kioskKey',
            header: 'Kiosk Key',
            formatValue: (value, column) => {
                const dropOffType = _.get(column, 'dropOffType', '');
                if (!dropOffType.toLowerCase().includes('kiosk')) {
                    return 'N/A';
                }

                if (!value) {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon
                                style={{
                                    color: theme.palette.error.main,
                                    marginRight: theme.spacing.unit / 2,
                                    fontSize: '20px'
                                }}
                            >
                                warning_amber
                            </Icon>
                            <span>None</span>
                        </div>
                    );
                }

                return value;
            }
        },
        {
            key: 'availability',
            header: 'Available Days',
            formatValue: availability => {
                return displayDropLocationAvailableDays(availability, theme);
            }
        },
        { key: 'description', header: 'Description' },
        { key: 'location.description', header: 'Address' }
    ];

    const editForm = (
        <DropLocationForm
            http={http}
            defaults={defaults}
            dropLocation={selectedDocument}
            open={editDialogOpen}
            collectors={collectors}
            commodities={commodities}
            onClose={() => setEditDialogOpen(false)}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
            onSnackbar={onSnackbar}
            editing={!_.isNil(selectedDocument)}
        />
    );

    const renderExtraActions = document => (
        <>
            {document.enabled ? (
                <Tooltip title="Disable Technology" disableFocusListener disableTouchListener>
                    <IconButton
                        // disabled={_.get(document, 'readOnly', false)}
                        onClick={() => onChangeEnabled(document)}
                    >
                        <MDIcon
                            path={mdiCircleOffOutline}
                            size={1}
                            color={
                                // _.get(document, 'readOnly', false)
                                false ? 'rgba(0, 0, 0, 0.26)' : theme.palette.text.secondary
                            }
                        />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Enable Technology" disableFocusListener disableTouchListener>
                    <IconButton
                        // disabled={_.get(document, 'readOnly', false)}
                        onClick={() => onChangeEnabled(document)}
                    >
                        <MDIcon
                            path={mdiCheckboxMarkedCircleOutline}
                            size={1}
                            color={
                                // _.get(document, 'readOnly', false)
                                false ? 'rgba(0, 0, 0, 0.26)' : theme.palette.text.secondary
                            }
                        />
                    </IconButton>
                </Tooltip>
            )}
            {['Kiosk', 'Kiosk & Scanner'].includes(_.get(document, 'dropOffType')) &&
                _.get(document, 'integrations.kioskSyncFail') && (
                    <Tooltip title="Retry technology sync" disableFocusListener disableTouchListener>
                        <IconButton
                            //disabled={_.get(document, 'readOnly', false)}
                            onClick={() => handleTechnologySync(document)}
                        >
                            <MDIcon path={mdiSync} size={1} color="red" />
                        </IconButton>
                    </Tooltip>
                )}
        </>
    );

    useEffect(() => {
        async function getAllCommodities() {
            const res = await http.getJSON(`/commodities/getAllCommodities`);
            if (res.ok) {
                setCommodities(_.get(res, 'data.collectionData', []));
            }
        }
        getAllCommodities();
    }, []);

    const handleDrop = e => {
        const uploadFile = e.target.files[0];
        if (uploadFile.size <= maxFileSize) {
            setImportFile(e.target.files[0]);
        } else {
            onSnackbar('File exceeds max file size', 'error');
        }
    };

    const handleImport = async () => {
        setImporting(true);
        const formData = new FormData();
        formData.append('importFile', importFile);

        const res = await http.postJSON(`/dropLocations/importDropLocations`, formData, false, true);
        if (res.ok) {
            let importReportsCopy = _.cloneDeep(importReports);
            importReportsCopy.splice(0, 0, res.data);
            setImportReports(importReportsCopy);
            let expandedReportsCopy = _.cloneDeep(expandedReports);
            expandedReportsCopy.splice(0, 0, false);
            setExpandedReports(expandedReportsCopy);
            setImportFile(undefined);
            setImporting(false);
            handleReloadData();
            onSnackbar(`${res.data.numOfDropLocationsImported} technologies successfully imported`);
        } else {
            setImporting(false);
            onSnackbar('Error importing technologies', 'error');
        }
    };

    const handleExpandReport = (expand, index) => {
        let expandedReportsCopy = _.cloneDeep(expandedReports);
        expandedReportsCopy[index] = expand;
        setExpandedReports(expandedReportsCopy);
    };

    const headerContent = (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {_.isNil(importFile) && (
                <>
                    <Button
                        style={{ marginLeft: theme.spacing.unit, marginRight: theme.spacing.unit * 2 }}
                        variant="outlined"
                        disabled={importing || !_.isNil(importFile)}
                    >
                        <label htmlFor="importFileInput" style={{ padding: 0, cursor: 'pointer', display: 'block' }}>
                            Bulk Import Technology
                        </label>
                    </Button>

                    <input
                        data-cy="file-drop-input"
                        type="file"
                        accept={'.csv'}
                        id="importFileInput"
                        name="importFileInput"
                        onChange={handleDrop}
                        style={{ display: 'none' }}
                    />
                </>
            )}
            {!_.isEmpty(unmappedIntegrationData) && (
                <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                    <Icon style={{ color: colors.yellow[700] }}>warning</Icon>
                    <div>
                        {unmappedIntegrationData.map(data => {
                            return (
                                <>
                                    <Typography style={{ marginLeft: theme.spacing.unit, color: colors.grey[700] }}>
                                        {`Unmapped ${data.technology || data.type}`}
                                    </Typography>
                                    <Typography style={{ marginLeft: theme.spacing.unit, color: colors.grey[700] }}>
                                        {`(Code: ${data.collectorCode})`}
                                    </Typography>
                                </>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );

    useEffect(() => {
        if (!_.isNil(importFile)) {
            warnAction(() => {
                return handleImport();
            }, `Are you sure you want to import technologies from ${importFile.name}`);
            setImportFile(undefined);
            setImporting(false);
        }
    }, [importFile]);

    useEffect(() => {
        let syncErrorCount = 0;

        for (let technology of data) {
            if (!technology.enabled) {
                continue;
            }

            const kioskSyncFail = _.get(technology, 'integrations.kioskSyncFail');
            if (kioskSyncFail) {
                syncErrorCount++;
            }
        }

        setSyncErrorsFound(syncErrorCount);

        reloadUnmappedData();
    }, [data]);

    const footerContent = () => {
        if (!syncErrorsFound) {
            return <></>;
        }

        return (
            <div
                style={{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center',
                    marginLeft: theme.spacing.unit * 2,
                    marginRight: theme.spacing.unit * 2
                }}
            >
                <Icon style={{ color: colors.red[500] }}>warning</Icon>
                <Typography style={{ marginLeft: theme.spacing.unit, color: colors.red[300] }}>
                    {`${syncErrorsFound} technologies failed to sync. Please contact technical support.`}
                </Typography>
            </div>
        );
    };

    return (
        <>
            <CRUDTable
                headerContent={headerContent}
                operator={operator}
                columns={columns}
                data={updatedData}
                editForm={editForm}
                documentIsDisabled={dropLocation => !_.get(dropLocation, 'enabled', true)}
                // documentNotEditable={dropLocation => _.get(dropLocation, 'readOnly', false)}
                defaultRowsPerPage={5}
                renderExtraActions={renderExtraActions}
                startDateFilterStats={startDateFilterStats}
                setStartDateFilterStats={setStartDateFilterStats}
                endDateFilterStats={endDateFilterStats}
                setEndDateFilterStats={setEndDateFilterStats}
                dateFilterStatsErrorMessage={dateFilterStatsErrorMessage}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'Technology'}
                disabledHeaderText={'Disabled Technology'}
                loading={loading}
                hideDateFilters
                footerContent={footerContent()}
            />
            {importReports.map((report, index) => {
                return (
                    <Paper
                        style={{
                            margin: theme.spacing.unit * 2,
                            marginTop: 0,
                            padding: theme.spacing.unit * 2,
                            overflowX: 'scroll'
                        }}
                    >
                        <Typography variant="h6">
                            <IconButton onClick={() => handleExpandReport(!expandedReports[index], index)}>
                                <Icon>{expandedReports[index] ? 'expand_less' : 'expand_more'}</Icon>
                            </IconButton>
                            Imported {_.filter(report.dropLocations, p => _.isNil(p.errorMessage)).length}/
                            {report.dropLocations.length} technologies from{' '}
                            <span style={{ fontWeight: 'bold' }}>{report.fileName}</span>
                            {!_.isNil(_.find(report.dropLocations, p => !_.isNil(p.errorMessage))) && (
                                <Tooltip title={'Some imports were unsuccessful'}>
                                    <span style={{ margin: theme.spacing.unit / 2 }}>
                                        <Icon style={{ color: theme.palette.error.main }}>warning_amber</Icon>
                                    </span>
                                </Tooltip>
                            )}
                        </Typography>
                        <Collapse in={expandedReports[index]}>
                            {report.dropLocations.map(p => {
                                const hasError = !_.isNil(p.errorMessage);
                                return (
                                    <ListItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    style={{
                                                        backgroundColor: hasError
                                                            ? theme.palette.error.main
                                                            : theme.palette.primary.main
                                                    }}
                                                >
                                                    {hasError ? (
                                                        <MDIcon
                                                            path={allIcons.mdiAlertCircle}
                                                            size={1}
                                                            color={'white'}
                                                        />
                                                    ) : (
                                                        <MDIcon path={allIcons.mdiCheck} size={1} color={'white'} />
                                                    )}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    hasError ? (
                                                        <span style={{ color: theme.palette.error.main }}>
                                                            <span style={{ fontWeight: 'bold' }}>
                                                                Import Unsuccessful:{' '}
                                                            </span>
                                                            {p.errorMessage}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <span style={{ fontWeight: 'bold' }}>Name: </span>
                                                            {p.name} |{' '}
                                                            <span style={{ fontWeight: 'bold' }}>Collector: </span>
                                                            {p.collectorCode} |{' '}
                                                            <span style={{ fontWeight: 'bold' }}>Address: </span>
                                                            {_.get(p, 'location.description', p.address)}
                                                        </span>
                                                    )
                                                }
                                            />
                                        </div>
                                    </ListItem>
                                );
                            })}
                        </Collapse>
                    </Paper>
                );
            })}
        </>
    );
}

export default withTheme()(DropLocationsTable);

function isTransporterCollector(collectorId, collectors) {
    const collector = _.find(collectors, { _id: collectorId });
    if (!collector) return false;

    const pickupsEnabled = _.get(collector, 'configuration.enablePickups');

    return pickupsEnabled;
}

function payloadMismatch(dropLocationPayloads, collectorPayloads) {
    if (!collectorPayloads) return true;

    // Checks that the drop location payloads contain collector payloads, otherwise there is a mismatch
    for (let collectorPayload of collectorPayloads) {
        if (!dropLocationPayloads.includes(collectorPayload)) {
            return true;
        }
    }

    return false;
}

function displayDropLocationAvailableDays(availableDays, theme) {
    return (
        <div style={{ display: 'flex' }}>
            {DAYS_OF_THE_WEEK.map(dayOfWeek => {
                const availabilityForDay = _.find(
                    availableDays,
                    availableDay => availableDay.day.toLowerCase() === dayOfWeek
                );

                if (!availabilityForDay) return null;

                return (
                    <Tooltip title={availabilityForDay.hours}>
                        <div
                            style={{
                                backgroundColor: availabilityForDay.hours !== 'Closed' && colors.green[500],
                                marginRight: theme.spacing.unit,
                                color: availabilityForDay.hours !== 'Closed' ? 'white' : colors.grey[500],
                                padding: theme.spacing.unit * 0.5,
                                borderRadius: theme.shape.borderRadius,
                                borderWidth: 1,
                                borderStyle: 'solid'
                            }}
                        >
                            {dayOfWeek.slice(0, 2).toUpperCase()}
                        </div>
                    </Tooltip>
                );
            })}
        </div>
    );
}
